// 商品カテゴリ
import { Section } from '@/types/section'

export class Category {
  // 大カテゴリ・サブカテゴリ共通
  private readonly _id: number
  private readonly _layersPath: string
  private readonly _layers: string[]
  private readonly _hasDivideSetting?: boolean
  private readonly _isDivideSetting?: boolean
  private readonly _isFaq?: boolean
  private readonly _isSearchSections: Section[]
  private readonly _isQuestionSections: Section[]
  // 大カテゴリ
  private readonly _subcategoryId?: number
  private readonly _knowledgeCategoryIds?: number[]
  private readonly _subcategoryIsDivideSetting: boolean
  // サブカテゴリ
  private readonly _rootCategory?: Category

  private readonly MAX_END_CATEGORY_NAME_LENGTH = 20
  private readonly MAX_END_CATEGORY_LAYERS_PATH_LENGTH_ON_PC = 40

  constructor(
    id: number,
    layersPath: string,
    layers: string[],
    sort: number,
    subcategoryId?: number,
    knowledgeCategoryIds?: number[],
    hasSubcategory = false,
    category?: Category,
    hasDivideSetting?: boolean,
    isDivideSetting?: boolean,
    isFaq?: boolean,
    isSearchSections?: Section[],
    isQuestionSections?: Section[],
    subcategoryIsDivideSetting?: boolean
  ) {
    this._id = id
    this._layersPath = layersPath
    this._layers = layers
    this._sort = sort
    this._subcategoryId = subcategoryId
    this._knowledgeCategoryIds = knowledgeCategoryIds
    this._hasSubcategory = hasSubcategory
    this._rootCategory = category
    this._hasDivideSetting = hasDivideSetting
    this._isDivideSetting = isDivideSetting
    this._isFaq = isFaq
    this._isSearchSections = isSearchSections || []
    this._isQuestionSections = isQuestionSections || []
    this._subcategoryIsDivideSetting = subcategoryIsDivideSetting || false
  }

  private _sort: number

  get sort() {
    return this._sort
  }

  set sort(sort) {
    this._sort = sort
  }

  get layers() {
    return this._layers
  }

  private _isExpanded = false

  get isExpanded() {
    return this._isExpanded
  }

  set isExpanded(isExpanded: boolean) {
    this._isExpanded = isExpanded
  }

  private _isChecked = false

  get isChecked() {
    return this._isChecked
  }

  set isChecked(isChecked: boolean) {
    this._isChecked = isChecked
  }

  private _children: Category[] = []

  get children() {
    return this._children
      .filter((c) => {
        return c.layersDepth === this.layersDepth + 1
      })
      .map((c) => {
        c.children = this._children
        return c
      })
  }

  set children(children: Category[]) {
    this._children = children.filter((c) => {
      return (
        c.layersDepth >= this.layersDepth + 1 &&
        c._layers[this.layersDepth - 1] === this.name
      )
    })
  }

  private _hasSubcategory: boolean

  get hasSubcategory() {
    return this._hasSubcategory
  }

  set hasSubcategory(hasSubcategory: boolean) {
    this._hasSubcategory = hasSubcategory
  }

  get id() {
    return this._id
  }

  get isRootCategory() {
    return !!this._subcategoryId
  }

  /**
   * 大カテゴリ、サブカテゴリに関わらずサブカテゴリのIDを取得したい場合には
   * 明示的にsubcategoryIdから取得する
   */
  get subcategoryId(): number {
    return this.isRootCategory ? (this._subcategoryId as number) : this._id
  }

  get rootId() {
    return this.isRootCategory ? this._id : (this._rootCategory?.id as number)
  }

  get rootCategory(): Category {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.isRootCategory ? (this as any) : this._rootCategory
  }

  get knowledgeCategoryIds() {
    return this._knowledgeCategoryIds
  }

  get name() {
    return this._layers[this._layers.length - 1]
  }

  get parentName() {
    return this._layers[this._layers.length - 2]
  }

  get layersPath() {
    return this._layersPath
  }

  get layersDepth() {
    return this._layers.length
  }

  get hasChildren() {
    return this.isRootCategory
      ? this.hasSubcategory
      : (() => {
          const child = this._children.find((c) => {
            return c.layersDepth === this.layersDepth + 1
          })
          return !!child
        })()
  }

  get hasDivideSetting() {
    return this._hasDivideSetting
  }

  get isDivideSetting() {
    return this._isDivideSetting
  }

  get isFaq() {
    return this._isFaq
  }

  get isSearchSections() {
    return this._isSearchSections
  }

  get isQuestionSections() {
    return this._isQuestionSections
  }

  // SPキーワード検索でoptionsに表示されるカテゴリの末尾を20文字に切り詰める
  get endCategoryName() {
    if (this._layersPath.length <= this.MAX_END_CATEGORY_NAME_LENGTH)
      return this._layersPath

    return (
      '...' +
      this._layersPath.substring(
        this._layersPath.length - this.MAX_END_CATEGORY_NAME_LENGTH,
        this._layersPath.length
      )
    )
  }

  get subcategoryIsDivideSetting() {
    return this._subcategoryIsDivideSetting
  }

  // PCカテゴリーツリーで表示するカテゴリ名を取得する。SPなら末尾だけ表示
  public endCategoryLayersPath(isSP = false) {
    if (isSP) return this.name

    if (
      this._layersPath.length <= this.MAX_END_CATEGORY_LAYERS_PATH_LENGTH_ON_PC
    )
      return this._layersPath

    return (
      '...' +
      this._layersPath.substring(
        this._layersPath.length -
          this.MAX_END_CATEGORY_LAYERS_PATH_LENGTH_ON_PC,
        this._layersPath.length
      )
    )
  }

  toJSON = () => {
    return {
      id: this._id,
      layersPath: this._layersPath,
      layers: this._layers,
      subcategoryId: this.subcategoryId,
      knowledgeCategoryIds: this._knowledgeCategoryIds,
      hasSubcategory: this._hasSubcategory,
      category: this._rootCategory,
      isDivideSetting: this._isDivideSetting,
      isFaq: this._isFaq,
    }
  }
}
