import { RouteRecordRaw } from 'vue-router'
import DefaultLayout from '@/layouts/public/DefaultLayout.vue'
import DataNotFound from '@/pages/public/404.vue'
import InternalServerError from '@/pages/public/500.vue'
import FaqDetail from '@/pages/public/faq/id.vue'
import Home from '@/pages/public/faq/index.vue'
import FaqSearch from '@/pages/public/faq/search.vue'

const children: RouteRecordRaw[] = [
  {
    path: '',
    redirect: '/public/faq',
  },
  {
    path: 'faq',
    component: Home,
  },
  {
    path: 'faq/search',
    component: FaqSearch,
    meta: { noindex: true },
  },
  {
    path: 'faq/:id',
    component: FaqDetail,
  },
  {
    path: '404',
    component: DataNotFound,
  },
  {
    path: '500',
    component: InternalServerError,
  },
]

export default {
  path: '/public',
  component: DefaultLayout,
  children,
} as RouteRecordRaw
