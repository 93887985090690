import { computed } from 'vue'
import { useRouter } from 'vue-router'

export const useCustomRoute = () => {
  const { currentRoute } = useRouter()

  const isErrorPage = computed(() => {
    return (
      currentRoute.value.path.includes('/404') ||
      currentRoute.value.path.includes('/500')
    )
  })

  const isAuthQuestionerPage = computed<boolean>(
    () => currentRoute.value.path !== '/questioner/login'
  )

  const isAuthRespondentPage = computed<boolean>(
    () => currentRoute.value.path !== '/respondent/login'
  )

  const isRespondentSettingPage = computed(() => {
    return currentRoute.value.path.includes('/respondent/settings')
  })

  const isRespondentNotificationPage = computed(() => {
    return currentRoute.value.path.includes('/respondent/notification')
  })

  const isRespondentCsvDownload = computed(() => {
    return currentRoute.value.path.includes('/respondent/csv-download')
  })

  return {
    isErrorPage,
    isAuthQuestionerPage,
    isAuthRespondentPage,
    isRespondentSettingPage,
    isRespondentNotificationPage,
    isRespondentCsvDownload,
  }
}
