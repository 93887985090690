import { ApiConfig } from '@/types/apiConfig'

export const FaqApi: ApiConfig = {
  import: (file) => ({
    method: 'POST',
    url: `/api/administrator/faq/imports`,
    data: file,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }),
}
