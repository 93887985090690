import { ReferenceLevel } from '@/types/referenceLevel'

export const REFERENCE_LEVEL: ReferenceLevel[] = [
  'コールセンター',
  '社内',
  '社外',
  '一般公開',
]

export const REFERENCE_LEVEL_OPTIONS: {
  name: ReferenceLevel | string
  value: ReferenceLevel | string
}[] = REFERENCE_LEVEL.map((referenceLevel: ReferenceLevel | string) => ({
  name: referenceLevel,
  value: referenceLevel,
}))
