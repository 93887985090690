import { ApiConfig } from '@/types/apiConfig'

export const UploadApi: ApiConfig = {
  upload: (url: string, file: File) => ({
    method: 'PUT',
    url,
    data: file,
    headers: {
      'Content-Type': 'application/octet-stream',
    },
  }),
}
