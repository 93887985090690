import { reactive } from 'vue'
import { News } from '@/types/news'
import Validator from '@/utils/validator'

interface State {
  type: 'create' | 'update' | ''
  searchForms: {
    keyword: string
    startDateFrom: string
    startDateTo: string
    isRespondent: boolean
    isQuestioner: boolean
    nextCursor: string
  }
  news: News[]
  totalCount: number
  selectedNews: News | null
  editForms: {
    title: string
    contentWithTag: string
    startDate: string
    endDate: string | null
    isRespondent: boolean
    isQuestioner: boolean
  }
  errors: {
    title: string | boolean
    contentWithTag: string | boolean
    startDate: string | boolean
    referenceClassification: string | boolean
  }
}

export const useNews = (): {
  state: State
  validateForms: () => boolean
  resetForm: () => void
  resetErrors: () => void
} => {
  const state = reactive<State>({
    type: '',
    searchForms: {
      keyword: '',
      startDateFrom: '',
      startDateTo: '',
      isRespondent: false,
      isQuestioner: false,
      nextCursor: '',
    },
    news: [],
    totalCount: 0,
    selectedNews: null,
    editForms: {
      title: '',
      contentWithTag: '',
      startDate: '',
      endDate: '',
      isRespondent: false,
      isQuestioner: false,
    },
    errors: {
      title: '',
      contentWithTag: '',
      startDate: '',
      referenceClassification: '',
    },
  })

  const rules = {
    title: 'required',
    contentWithTag: 'required|valid_html',
    startDate: 'required',
  }

  const messages = {
    'required.title': 'タイトルを入力してください',
    'required.contentWithTag': '本文を入力してください',
    'valid_html.contentWithTag': '本文に不正な内容が含まれています',
    'required.startDate': '公開開始日を入力してください',
  }

  const validateForms = (): boolean => {
    resetErrors()
    const validation = new Validator(state.editForms, rules, messages)
    const isErrorReferenceClassification = checkReferenceClassification()
    if (validation.passes() && !isErrorReferenceClassification) {
      return true
    } else {
      state.errors = {
        ...state.errors,
        title: validation.errors.first('title'),
        contentWithTag: validation.errors.first('contentWithTag'),
        startDate: validation.errors.first('startDate'),
      }
      return false
    }
  }

  const checkReferenceClassification = () => {
    if (!state.editForms.isRespondent && !state.editForms.isQuestioner) {
      state.errors.referenceClassification = '参照区分は必ず選択してください'
      return true
    }
    return false
  }

  const resetForm = () => {
    state.editForms = {
      title: '',
      contentWithTag: '',
      startDate: '',
      endDate: '',
      isRespondent: false,
      isQuestioner: false,
    }
  }

  const resetErrors = () => {
    state.errors = {
      title: '',
      contentWithTag: '',
      startDate: '',
      referenceClassification: '',
    }
  }

  return {
    state,
    validateForms,
    resetForm,
    resetErrors,
  }
}
