import { reactive, watch } from 'vue'
import { createContainer } from 'vue-unstated'
import { Attachment } from '@/types/attachment'
import { Category, KnowledgeCategory } from '@/types/category'
import { ContactGroup } from '@/types/contactGroup'
import { Region } from '@/types/region'
import Validator, { getMessageMaxHTML } from '@/utils/validator'

interface State {
  type: 'create' | 'confirm' | 'completed'
  forms: {
    categoryId: number | string
    subcategoryId?: number
    knowledgeCategoryId?: number
    productNumber: string
    name: string
    branch: string
    tel: string
    email: string
    otherEmail?: string
    regionId: number | null
    title: string
    content: string
    attachments: Attachment[]
    deleteTemporarilyStoredQuestion?: boolean
  }
  errors: {
    categoryId: string | boolean
    subcategoryId: string | boolean
    knowledgeCategoryId: string | boolean
    name: string | boolean
    branch: string | boolean
    tel: string | boolean
    email: string | boolean
    otherEmail: string | boolean
    regionId: string | boolean
    title: string | boolean
    productNumber: string | boolean
    content: string | boolean
    attachments: string | boolean
  }
  regions: Region[]
  region: Region | null
  contactGroup: ContactGroup | null
  category?: Category | null
  subcategory: Category | null
  knowledgeCategory: KnowledgeCategory | null
  isRegionRequired: boolean
  isTried: boolean
  isUserWithinCompany: boolean
}

export const useQuestion = (): {
  state: State
  validateForms: () => boolean
  validateTemporarilyStoredForms: () => boolean
} => {
  const state = reactive<State>({
    type: 'create',
    forms: {
      categoryId: 0,
      subcategoryId: undefined,
      knowledgeCategoryId: undefined,
      productNumber: '',
      name: '',
      branch: '',
      tel: '',
      email: '',
      otherEmail: '',
      regionId: 0,
      title: '',
      content: '',
      attachments: [],
      deleteTemporarilyStoredQuestion: true,
    },
    errors: {
      categoryId: '',
      subcategoryId: '',
      knowledgeCategoryId: '',
      name: '',
      branch: '',
      tel: '',
      email: '',
      otherEmail: '',
      regionId: '',
      title: '',
      productNumber: '',
      content: '',
      attachments: '',
    },
    regions: [],
    region: null,
    contactGroup: null,
    category: null,
    subcategory: null,
    knowledgeCategory: null,
    isRegionRequired: false,
    isTried: false,
    isUserWithinCompany: false,
  })

  watch(
    () => state.forms,
    () => {
      if (!state.isTried) return
      validateForms()
    },
    { deep: true }
  )

  const rules: { [key: string]: string } = {
    categoryId: 'required|min:1',
    subcategoryId: 'required',
    knowledgeCategoryId: 'required',
    name: 'required|max:50',
    branch: 'required|max:60',
    tel: 'tel',
    email: 'required|email|hankaku_email',
    otherEmail: 'multiple_emails',
    title: 'required|max:80',
    productNumber: 'max:30',
    content: 'required|valid_html|max:15000',
  }

  const temporarilyStoredRules: { [key: string]: string } = {
    name: 'max:50',
    branch: 'max:60',
    tel: 'tel',
    email: 'email|hankaku_email',
    otherEmail: 'multiple_emails',
    title: 'max:80',
    productNumber: 'max:30',
    content: 'valid_html|max:15000',
  }

  const messages = {
    'required.categoryId': '商品カテゴリを選択してください',
    'min.categoryId': '地域を選択してください',
    'required.subcategoryId': '商品カテゴリ詳細を選択してください',
    'required.knowledgeCategoryId': '知りたいことを選択してください',
    'required.name': '氏名(フルネーム)を入力してください',
    'max.name': '氏名(フルネーム)は50文字以内で入力してください',
    'required.branch': '所属を入力してください',
    'max.branch': '所属は60文字以内で入力してください',
    'required.tel': '電話番号を入力してください',
    'tel.tel': '正しい電話番号を入力してください',
    'required.email': 'メールアドレスを入力してください',
    'email.email': '正しいメールアドレスの形式で入力してください',
    'hankaku_email.email': 'メールアドレスに不正な文字が含まれています',
    'multiple_emails.otherEmail':
      '@lixil.comのメールアドレスのみ許可されています。正しいメールアドレスの形式で入力してください(例: email1@lixil.com,email2@lixil.com)',
    'required.regionId': '地域を選択してください',
    'min.regionId': '地域を選択してください',
    'required.title': 'タイトルを入力してください',
    'max.title': 'タイトルは80文字以内で入力してください',
    'max.productNumber': '品番は30文字以内で入力してください',
    'required.content': 'お問い合わせ内容を入力してください',
    'valid_html.content': 'お問い合わせに不正な内容が含まれています',
    'max.content': getMessageMaxHTML('お問い合わせ'),
  }

  const getValidateRules = () => {
    if (state.isRegionRequired) {
      rules.regionId = 'required'
    }

    if (!state.isUserWithinCompany) {
      rules.tel = 'required|tel'
    }

    return rules
  }

  const validateForms = (): boolean => {
    resetErrors()
    const validation = new Validator(state.forms, getValidateRules(), messages)
    if (validation.passes()) {
      return true
    } else {
      state.errors = {
        categoryId: validation.errors.first('categoryId'),
        subcategoryId: validation.errors.first('subcategoryId'),
        knowledgeCategoryId: validation.errors.first('knowledgeCategoryId'),
        name: validation.errors.first('name'),
        branch: validation.errors.first('branch'),
        tel: validation.errors.first('tel'),
        email: validation.errors.first('email'),
        otherEmail: validation.errors.first('otherEmail'),
        regionId: validation.errors.first('regionId'),
        title: validation.errors.first('title'),
        productNumber: validation.errors.first('productNumber'),
        content: validation.errors.first('content'),
        attachments: validation.errors.first('attachments'),
      }
      return false
    }
  }

  const validateTemporarilyStoredForms = (): boolean => {
    resetErrors()
    const validation = new Validator(
      state.forms,
      temporarilyStoredRules,
      messages
    )
    if (validation.passes()) {
      return true
    } else {
      state.errors = {
        categoryId: validation.errors.first('categoryId'),
        subcategoryId: validation.errors.first('subcategoryId'),
        knowledgeCategoryId: validation.errors.first('knowledgeCategoryId'),
        name: validation.errors.first('name'),
        branch: validation.errors.first('branch'),
        tel: validation.errors.first('tel'),
        email: validation.errors.first('email'),
        otherEmail: validation.errors.first('otherEmail'),
        regionId: validation.errors.first('regionId'),
        title: validation.errors.first('title'),
        productNumber: validation.errors.first('productNumber'),
        content: validation.errors.first('content'),
        attachments: validation.errors.first('attachments'),
      }
      return false
    }
  }

  const resetErrors = () => {
    state.errors = {
      categoryId: '',
      subcategoryId: '',
      knowledgeCategoryId: '',
      name: '',
      branch: '',
      tel: '',
      email: '',
      otherEmail: '',
      regionId: '',
      title: '',
      productNumber: '',
      content: '',
      attachments: '',
    }
  }

  return {
    state,
    validateForms,
    validateTemporarilyStoredForms,
  }
}

const questionContainer = createContainer(useQuestion)

export default questionContainer
