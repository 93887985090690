import { ApiConfig } from '@/types/apiConfig'

export const NewsApi: ApiConfig = {
  searchNews: (params: { isImportant?: boolean }) => ({
    method: 'POST',
    url: `/api/respondent/news`,
    data: params,
  }),
  get: (id: number) => ({
    method: 'GET',
    url: `/api/respondent/news/${id}`,
  }),
  searchNotifications: (params: {
    excludeRead: boolean
    page: number
    perPage: number
  }) => ({
    method: 'POST',
    url: `/api/respondent/news/searches`,
    data: params,
  }),
}
