import { computed, reactive } from 'vue'
import { KnowledgeCategory } from '@/domain/knowledgeCategory/knowledgeCategory.model'
import { createKnowledgeSelectedIds } from '@/use/categoryTree/multipleCategoryTree/libs/createExpandedIds'
import { createSelectedIds } from '@/use/categoryTree/multipleCategoryTree/libs/createSelectedIds'

interface State {
  selectedCategories: KnowledgeCategory[]
  expandedCategoryIds: number[]
  categories: KnowledgeCategory[]
  rootCategoryIds: number[]
  knowledgeCategoryIds: number[]
}

export const useMultipleKnowledgeCategoryTree = () => {
  const state = reactive<State>({
    selectedCategories: [],
    expandedCategoryIds: [],
    categories: [],
    rootCategoryIds: [],
    knowledgeCategoryIds: [],
  })

  const selectedCategoryIds = computed(() =>
    state.selectedCategories.map((c) => c.id)
  )

  const knowledgeCategories = computed(() => {
    return state.categories
      .filter((c) => c.layersDepth === 1)
      .map((category) => {
        category.children = state.categories
        return category
      })
      .filter((c) => c.hasChildren)
  })

  const filteredKnowledgeCategories = computed(() => {
    return state.categories
      .filter((c) => c.layersDepth === 1)
      .map((category) => {
        category.children = state.categories
        return category
      })
      .filter((c) => c.hasChildren)
  })

  const expandCategory = async (category: KnowledgeCategory) => {
    state.expandedCategoryIds = createSelectedIds(
      state.expandedCategoryIds,
      category.id
    )
  }

  const selectCategory = (category: KnowledgeCategory) => {
    state.selectedCategories = state.selectedCategories.find(
      (c) => c.id === category.id
    )
      ? state.selectedCategories.filter((c) => c.id !== category.id)
      : [...state.selectedCategories, category]
  }

  const removeCategory = (category: KnowledgeCategory) => {
    state.selectedCategories = state.selectedCategories.filter(
      (c) => c.id !== category.id
    )
  }

  const setInitialCategoriesMultipleSelect = async (
    selectedCategories: { knowledgeCategoryId: number }[]
  ) => {
    const selectedKnowledgeCategoryIds = selectedCategories.map(
      (c) => c.knowledgeCategoryId
    )
    state.selectedCategories = state.categories.filter((c) =>
      selectedKnowledgeCategoryIds.includes(c.id)
    )
    state.expandedCategoryIds = createKnowledgeSelectedIds(
      state.categories as KnowledgeCategory[],
      state.selectedCategories as KnowledgeCategory[],
      selectedKnowledgeCategoryIds
    )
  }

  const resetCategoryTree = () => {
    state.selectedCategories = []
    state.expandedCategoryIds = []
  }

  return {
    state,
    expandCategory,
    selectCategory,
    selectedCategoryIds,
    knowledgeCategories,
    filteredKnowledgeCategories,
    removeCategory,
    setInitialCategoriesMultipleSelect,
    resetCategoryTree,
  }
}
