import { Attachment } from '@/types/attachment'
import { HistoryType } from '@/types/historyType'
import { History, Question } from '@/types/question'

export const useQuestionAnswerMemo = (): {
  hasPreviewData: (question: Question) => boolean
  transformToHistory: (
    id: string,
    content: string,
    attachments: Attachment[]
  ) => History
} => {
  const hasPreviewData = (question: Question): boolean => {
    return <boolean>(question.memo || question.memoAttachments.length > 0)
  }

  // 問い合わせの回答メモを履歴として扱うために履歴に変換
  const transformToHistory = (
    id: string,
    content = '',
    attachments: Attachment[]
  ) => {
    return {
      // IDは通常numberだが回答メモを履歴に追加するために例外としてstringを許容する
      id: id as unknown as number,
      type: '社内メモ' as HistoryType,
      branchNumber: 0,
      displayNumber: 0,
      content: content,
      elapsedTime: null,
      respondent: null,
      createdAt: '',
      attachments: attachments,
      afterContactGroup: null,
      afterKnowledgeCategory: null,
      afterRegion: null,
      afterRespondent: null,
      afterStatus: null,
      afterSubcategory: null,
      beforeContactGroup: null,
      beforeKnowledgeCategory: null,
      beforeRegion: null,
      beforeRespondent: null,
      beforeStatus: null,
      beforeSubcategory: null,
    }
  }

  return {
    transformToHistory,
    hasPreviewData,
  }
}
