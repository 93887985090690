import { computed, ComputedRef, reactive, Ref } from 'vue'
import { createContainer } from 'vue-unstated'
import { User } from '@/types/user'
import { debounce } from '@/use/useDebounce'

interface State {
  selectedUsers: User[]
  filteredUsers: User[]
  nextCursor: string
}

export const useSearchInternalUsers = (): {
  state: State
  selectedUserIds: ComputedRef<string[]>
  userKeyword: Ref<string>
} => {
  const state = reactive<State>({
    selectedUsers: [],
    filteredUsers: [],
    nextCursor: '',
  })

  const userKeyword = debounce<string>('')

  const selectedUserIds = computed<string[]>(() => {
    return state.selectedUsers.map((user: User) => user.id)
  })

  return {
    state,
    selectedUserIds,
    userKeyword,
  }
}

const searchInternalUsersContainer = createContainer(useSearchInternalUsers)

export default searchInternalUsersContainer
