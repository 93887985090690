export const sortingOptions = [
  { name: '選択してください', value: 'score-desc' },
  { name: 'IDの昇順', value: 'id-asc' },
  { name: 'IDの降順', value: 'id-desc' },
  { name: 'いいねの多い順', value: 'liked_number-desc' },
  { name: 'いいねの少ない順', value: 'liked_number-asc' },
  { name: '参照数の多い順', value: 'accessed_number-desc' },
  { name: '参照数の少ない順', value: 'accessed_number-asc' },
  { name: '最終更新日の昇順', value: 'updated_at-asc' },
  { name: '最終更新日の降順', value: 'updated_at-desc' },
  { name: '初回登録日の昇順', value: 'created_at-asc' },
  { name: '初回登録日の降順', value: 'created_at-desc' },
]
