import { ApiConfig } from '@/types/apiConfig'
import { Attachment } from '@/types/attachment'

export const FaqApi: ApiConfig = {
  get: (id: number) => ({
    method: 'GET',
    url: `/api/respondent/faq/${id}`,
  }),
  searches: (params: {
    scrollId?: string
    keyword: string
    id: string
    subcategoryId?: number
    knowledgeCategoryId?: number
    createdBy: string
    firstStartDate: string
    firstEndDate: string
    lastStartDate: string
    lastEndDate: string
    orderBy: string
    order: string
  }) => ({
    method: 'POST',
    url: `/api/respondent/faq/searches`,
    data: params,
  }),
  newTemporarilyStored: (params: {
    contact: string
    answer: string
    attachments?: Attachment[]
    remarks?: string
    subcategoryIds?: number[]
    knowledgeCategoryIds?: number[]
    contactGroupId: number
    startDate?: string
    responsibleId: string
    keywords?: string[]
    isDisplay: boolean
    priority: string
    referenceLevel: string
  }) => ({
    method: 'POST',
    url: `/api/respondent/faq/temporarily-stored`,
    data: params,
  }),
  updateTemporarilyStored: (
    id: string,
    params: {
      contact: string
      answer: string
      attachments?: Attachment[]
      remarks?: string
      subcategoryIds?: number[]
      knowledgeCategoryIds?: number[]
      contactGroupId: number
      startDate?: string
      responsibleId: string
      keywords?: string[]
      isDisplay: boolean
      priority: string
      referenceLevel: string
      updatedAt: string
    }
  ) => ({
    method: 'PUT',
    url: `/api/respondent/faq/${id}/temporarily-stored`,
    data: params,
  }),
  create: (params: {
    contact: string
    answer: string
    attachments?: Attachment[]
    remarks?: string
    subcategoryIds: number[]
    knowledgeCategoryIds: number[]
    contactGroupId: number
    startDate: string
    responsibleId: string
    keywords?: string[]
    isDisplay: boolean
    priority: string
    referenceLevel: string
  }) => ({
    method: 'POST',
    url: `/api/respondent/faq`,
    data: params,
  }),
  update: (
    id: string,
    params: {
      contact: string
      answer: string
      attachments?: Attachment[]
      remarks?: string
      subcategoryIds: number[]
      knowledgeCategoryIds: number[]
      contactGroupId: number
      startDate: string
      responsibleId: string
      keywords?: string[]
      isDisplay: boolean
      priority: string
      referenceLevel: string
      updatedAt: string
    }
  ) => ({
    method: 'PUT',
    url: `/api/respondent/faq/${id}`,
    data: params,
  }),
  delete: (id: string) => ({
    method: 'DELETE',
    url: `/api/respondent/faq/${id}`,
  }),
  export: (params: {
    keyword?: string
    id?: string
    subcategoryId?: number
    knowledgeCategoryId?: number
    createdBy?: string
    firstStartDate?: string
    firstEndDate?: string
    lastStartDate?: string
    lastEndDate?: string
    orderBy?: string
    order?: string
  }) => ({
    method: 'POST',
    url: `/api/respondent/faq/exports`,
    data: params,
  }),
  downloadReferenceLog: (params: { date: string }) => ({
    method: 'POST',
    url: `/api/respondent/faq/reference-log/download`,
    data: params,
  }),
}
