export const getColorStyle = (
  color: string
): {
  border: string
  hoverBorder: string
  groupHoverBorder: string
  text: string
  hoverText: string
  groupHoverText: string
  bg: string
  hoverBg: string
  groupHoverBg: string
} => {
  switch (color) {
    case 'lixil-green':
      return {
        border: 'border-lixil-green',
        hoverBorder: 'hover:border-lixil-green',
        groupHoverBorder: 'group-hover:border-lixil-green',
        text: 'text-lixil-green',
        hoverText: 'hover:text-lixil-green',
        groupHoverText: 'group-hover:text-lixil-green',
        bg: 'bg-lixil-green',
        hoverBg: 'hover:bg-lixil-green',
        groupHoverBg: 'group-hover:bg-lixil-green',
      }
    case 'lixil-orange':
      return {
        border: 'border-lixil-orange',
        hoverBorder: 'hover:border-lixil-orange',
        groupHoverBorder: 'group-hover:border-lixil-orange',
        text: 'text-lixil-orange',
        hoverText: 'hover:text-lixil-orange',
        groupHoverText: 'group-hover:text-lixil-orange',
        bg: 'bg-lixil-orange',
        hoverBg: 'hover:bg-lixil-orange',
        groupHoverBg: 'group-hover:bg-lixil-orange',
      }
    case 'lixil-gray':
      return {
        border: 'border-lixil-gray',
        hoverBorder: 'hover:border-lixil-gray',
        groupHoverBorder: 'group-hover:border-lixil-gray',
        text: 'text-lixil-gray',
        hoverText: 'hover:text-lixil-gray',
        groupHoverText: 'group-hover:text-lixil-gray',
        bg: 'bg-lixil-gray',
        hoverBg: 'hover:bg-lixil-gray',
        groupHoverBg: 'group-hover:bg-lixil-gray',
      }
    case 'gray-300':
      return {
        border: 'border-gray-300',
        hoverBorder: 'hover:border-gray-300',
        groupHoverBorder: 'group-hover:border-gray-300',
        text: 'text-gray-300',
        hoverText: 'hover:text-gray-300',
        groupHoverText: 'group-hover:text-gray-300',
        bg: 'bg-gray-300',
        hoverBg: 'hover:bg-gray-300',
        groupHoverBg: 'group-hover:bg-gray-300',
      }
    case 'gray-400':
      return {
        border: 'border-gray-400',
        hoverBorder: 'hover:border-gray-400',
        groupHoverBorder: 'group-hover:border-gray-400',
        text: 'text-gray-400',
        hoverText: 'hover:text-gray-400',
        groupHoverText: 'group-hover:text-gray-400',
        bg: 'bg-gray-400',
        hoverBg: 'hover:bg-gray-400',
        groupHoverBg: 'group-hover:bg-gray-400',
      }
    case 'gray-500':
      return {
        border: 'border-gray-500',
        hoverBorder: 'hover:border-gray-500',
        groupHoverBorder: 'group-hover:border-gray-500',
        text: 'text-gray-500',
        hoverText: 'hover:text-gray-500',
        groupHoverText: 'group-hover:text-gray-500',
        bg: 'bg-gray-500',
        hoverBg: 'hover:bg-text-gray-500',
        groupHoverBg: 'group-hover:bg-gray-500',
      }
    default:
      return {
        border: '',
        hoverBorder: '',
        groupHoverBorder: '',
        text: '',
        hoverText: '',
        groupHoverText: '',
        bg: '',
        hoverBg: '',
        groupHoverBg: '',
      }
  }
}
