import { reactive } from 'vue'
import { categoryMapper } from '@/domain/category/category.mapper'
import { Category } from '@/domain/category/category.model'
import { KnowledgeCategory } from '@/domain/knowledgeCategory/knowledgeCategory.model'
import { createSelectedIndexArray } from '@/use/categoryTree/singleCategoryTree/libs/createSelectedIndexArray'
import { scrollToTop } from '@/use/categoryTree/singleCategoryTree/libs/scrollToTop'

interface State {
  selectedIndexArray: number[]
  categories: Category[]
  selectedCategory: Category | null
  selectedKnowledgeCategory: KnowledgeCategory | null
  allSubcategories: Category[]
  subcategories: Category[]
  isChangingLayer: boolean
}

export const useProductSingleCategoryTree = (
  fns: {
    getSubcategories: ({
      categoryId,
      knowledgeCategoryId,
    }: {
      categoryId?: number
      knowledgeCategoryId?: number
    }) => Promise<Category[]>
    loading: (callback: () => Promise<void>) => void
  },
  options?: {
    confirmBeforeClickLayer?: (
      knowledgeCategory: KnowledgeCategory,
      subcategory: Category
    ) => Promise<boolean>
    afterClickLayer?: (category: Category | KnowledgeCategory) => Promise<void>
    trackClickEndLayerEvent?: () => void
  }
) => {
  const state = reactive<State>({
    selectedIndexArray: [],
    categories: [],
    selectedCategory: null,
    selectedKnowledgeCategory: null,
    allSubcategories: [],
    subcategories: [],
    // 応急的な対処となるが、キーワードによるカテゴリ選択中はchangeLayerイベントによって引き起こされる
    // selectedIndexArrayの変更を許可したくない
    isChangingLayer: false,
  })

  const filterSubcategories = async (categoryId: number) => {
    await fns.loading(async () => {
      state.subcategories = await fns
        .getSubcategories({
          categoryId,
          knowledgeCategoryId: state.selectedKnowledgeCategory?.id,
        })
        .then((categories) => {
          const mappedCategories = categories.map(categoryMapper)
          state.allSubcategories = mappedCategories
          return mappedCategories
            .filter((c) => c.layersDepth === 2)
            .map((c) => {
              c.children = mappedCategories
              return c
            })
        })
    })
  }

  const clickLayer = async (
    category: Category,
    index: number,
    layerDepth: number
  ) => {
    if (!category.hasChildren && options?.trackClickEndLayerEvent) {
      options.trackClickEndLayerEvent()
    }
    // パフォーマンス計測用
    // const start = performance.now()
    await fns.loading(async () => {
      if (
        options?.confirmBeforeClickLayer &&
        state.selectedKnowledgeCategory &&
        category.subcategoryId &&
        !(await options.confirmBeforeClickLayer(
          state.selectedKnowledgeCategory as KnowledgeCategory,
          category
        ))
      )
        return

      if (category.isRootCategory) {
        const { subcategories, selectedIndexArray } = await clickRootLayer(
          category,
          state.selectedIndexArray,
          index
        )
        state.selectedIndexArray = selectedIndexArray
        state.categories[state.selectedIndexArray[0]].children = subcategories
      } else {
        state.selectedIndexArray = createSelectedIndexArray(
          state.selectedIndexArray,
          index,
          layerDepth
        )
      }

      state.selectedCategory = category
    })
    scrollToTop()

    if (!category.hasChildren) {
      closeCategoryTree()
    }

    if (options && options.afterClickLayer) {
      await options.afterClickLayer(category)
    }

    // パフォーマンス計測用
    // const end = performance.now()
    // console.log('after clickLayer', end - start)
  }

  const clickRootLayer = async (
    category: Category,
    selectedIndexArray: number[],
    index: number
  ): Promise<{
    subcategories: Category[]
    selectedIndexArray: number[]
  }> => {
    return {
      subcategories: await fns
        .getSubcategories({ categoryId: category.id })
        .then((categories) => categories.map(categoryMapper)),
      selectedIndexArray: createSelectedIndexArray(
        selectedIndexArray,
        index,
        1
      ),
    }
  }

  /**
   * SPでカテゴリを変更したときのイベント
   */
  const changeLayer = (
    category: Category,
    index: number,
    layerDepth: number,
    isSubcategories = false
  ) => {
    if (state.isChangingLayer) return
    if (state.selectedIndexArray[layerDepth - 1] === index) return

    fns.loading(async () => {
      if (category.isRootCategory) {
        const { subcategories, selectedIndexArray } = await clickRootLayer(
          category,
          state.selectedIndexArray,
          index
        )
        state.selectedIndexArray = selectedIndexArray
        state.categories[state.selectedIndexArray[0]].children = subcategories
      } else {
        state.selectedIndexArray = createSelectedIndexArray(
          state.selectedIndexArray,
          index,
          isSubcategories ? layerDepth - 1 : layerDepth
        )
        if (options && options.afterClickLayer && !category.hasChildren) {
          state.selectedCategory = category
          options.afterClickLayer(category)
          closeCategoryTree()
        }
      }
    })
  }

  /**
   * SPでカテゴリを選択したときのイベント
   */
  const selectCategory = async (isQuestion = false) => {
    if (state.selectedIndexArray.length === 0) return
    const category = getCategoryOnSp(isQuestion)
    if (category && state.selectedIndexArray.length > 1) {
      state.selectedCategory = getSelectedCategory(category, isQuestion)
    } else {
      state.selectedCategory = category
    }
    const selectedCategory = state.selectedCategory
    if (!selectedCategory) return
    if (
      options?.confirmBeforeClickLayer &&
      state.selectedKnowledgeCategory &&
      selectedCategory.subcategoryId &&
      !(await options.confirmBeforeClickLayer(
        state.selectedKnowledgeCategory as KnowledgeCategory,
        selectedCategory as Category
      ))
    )
      return
    closeCategoryTree()

    if (options && options.afterClickLayer) {
      options.afterClickLayer(selectedCategory as Category)
    }
  }

  const getCategoryOnSp = (isQuestion: boolean): Category => {
    if (isQuestion) {
      return state.subcategories[state.selectedIndexArray[0]] as Category
    } else {
      return state.categories[state.selectedIndexArray[0]] as Category
    }
  }

  const getSelectedCategory = (
    category: Category,
    isQuestion: boolean
  ): Category => {
    const childCategory =
      category.children[
        state.selectedIndexArray[category.layersDepth - (isQuestion ? 1 : 0)]
      ]
    if (
      state.selectedIndexArray.length >
      category.layersDepth + (isQuestion ? 0 : 1)
    ) {
      return getSelectedCategory(childCategory, isQuestion)
    } else {
      return childCategory
    }
  }

  const selectByCategoryId = async (
    category: Category,
    options: {
      isFromSubcategory?: boolean
    } = {
      isFromSubcategory: false,
    }
  ) => {
    state.selectedIndexArray = []
    fns.loading(async () => {
      const { subcategories, selectedIndexArray } = await clickRootLayer(
        category.rootCategory || category,
        state.selectedIndexArray,
        state.categories.findIndex((c) => c.id === category.rootId)
      )
      state.selectedIndexArray = selectedIndexArray

      state.categories[state.selectedIndexArray[0]].children = subcategories
      openCategories(
        options.isFromSubcategory
          ? (state.subcategories as Category[])
          : (state.categories[state.selectedIndexArray[0]]
              .children as Category[]),
        category,
        1
      )

      if (options.isFromSubcategory) {
        state.selectedIndexArray = state.selectedIndexArray.splice(
          1,
          state.selectedIndexArray.length
        )
      }
      const index = subcategories.findIndex(
        (c) => c.id === category.subcategoryId
      )
      state.selectedCategory = subcategories[index]
      state.selectedCategory.children = state.allSubcategories
      state.isChangingLayer = true
      await setTimeout(() => {
        const dom = document.querySelectorAll<HTMLElement>(
          `[data-category-id="${category.subcategoryId}"]`
        )
        dom[0]?.click()
      }, 200)
      state.isChangingLayer = false
    })
  }

  const openCategories = (
    categories: Category[],
    category: Category,
    layerDepth: number
  ) => {
    if (category.layersDepth === 1 && category.children.length === 0) return
    const { nextCategories } = createIndexArray(categories, category)
    if (layerDepth === category.layersDepth - 1) return
    openCategories(nextCategories, category, layerDepth + 1)
  }

  const createIndexArray = (
    categories: Category[],
    selectedCategory: Category
  ) => {
    const index = categories.findIndex(
      (c) =>
        c.layers[c.layersDepth - 1] ===
        selectedCategory.layers[c.layersDepth - 1]
    )
    state.selectedIndexArray = [...state.selectedIndexArray, index]
    return {
      nextCategories: categories[index].children,
    }
  }

  const clearLayer = (layerDepth: number) => {
    state.selectedIndexArray = state.selectedIndexArray.slice(0, layerDepth - 1)
  }

  const selectCategoryByKeyword = async (category: Category, options = {}) => {
    // 末尾であるか判定する
    const categoryTree = document.getElementById('product-category')
    categoryTree?.click()
    await setTimeout(() => {
      const selectCategoryButton = document.getElementById('select-category')
      selectCategoryButton?.click()
    }, 200)
    await selectByCategoryId(categoryMapper(category), options)
    closeCategoryTree()
  }

  const resetCategory = () => {
    state.selectedCategory = null
    state.selectedIndexArray = []
  }

  return {
    state,
    clickLayer,
    clickRootLayer,
    selectByCategoryId,
    resetCategory,
    filterSubcategories,
    selectCategoryByKeyword,
    changeLayer,
    selectCategory,
    closeCategoryTree,
    clearLayer,
  }
}

const closeCategoryTree = () => {
  const target = document.getElementById('product-category')
  if (target) {
    target.click()
  }
}
