import { Category } from '@/domain/category/category.model'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const categoryMapper = (c: any) => {
  return new Category(
    c.id,
    c.layersPath,
    c.layers,
    c.sort,
    c.layers.length === 1 ? c.subcategoryId : null,
    c.knowledgeCategoryIds,
    c.hasSubcategory,
    c.category,
    c.hasDivideSetting,
    c.isDivideSetting || c.subcategoryIsDivideSetting,
    c.isFaq || c.subcategoryIsFaq,
    c.isSearchSections,
    c.isQuestionSections,
    c.subcategoryIsDivideSetting
  )
}
