<template>
  <div class="flex-1 w-1/2 pr-4 border-r border-gray-200">
    <ul class="space-y-4">
      <li>
        <router-link
          class="flex justify-between items-center py-2 pr-2 pl-4 border-l-5 border-lixil-gray bg-white font-bold hover:border-lixil-green hover:text-lixil-green"
          to="/respondent/settings/divides"
        >
          振り分け管理
          <i class="ri-arrow-right-s-line text-2xl"></i>
        </router-link>
      </li>
      <li>
        <router-link
          class="flex justify-between items-center py-2 pr-2 pl-4 border-l-5 border-lixil-gray bg-white font-bold hover:border-lixil-green hover:text-lixil-green"
          to="/respondent/settings/categories"
        >
          商品カテゴリ管理
          <i class="ri-arrow-right-s-line text-2xl"></i>
        </router-link>
      </li>
      <li>
        <router-link
          class="flex justify-between items-center py-2 pr-2 pl-4 border-l-5 border-lixil-gray bg-white font-bold hover:border-lixil-green hover:text-lixil-green"
          to="/respondent/settings/knowledge-categories"
        >
          知りたいこと管理
          <i class="ri-arrow-right-s-line text-2xl"></i>
        </router-link>
      </li>
      <li>
        <router-link
          class="flex justify-between items-center py-2 pr-2 pl-4 border-l-5 border-lixil-gray bg-white font-bold hover:border-lixil-green hover:text-lixil-green"
          to="/respondent/settings/contact-groups"
        >
          窓口管理
          <i class="ri-arrow-right-s-line text-2xl"></i>
        </router-link>
      </li>
      <li>
        <router-link
          class="flex justify-between items-center py-2 pr-2 pl-4 border-l-5 border-lixil-gray bg-white font-bold hover:border-lixil-green hover:text-lixil-green"
          to="/respondent/settings/lixil-users"
        >
          社内ユーザー管理
          <i class="ri-arrow-right-s-line text-2xl"></i>
        </router-link>
      </li>
      <li>
        <router-link
          class="flex justify-between items-center py-2 pr-2 pl-4 border-l-5 border-lixil-gray bg-white font-bold hover:border-lixil-green hover:text-lixil-green"
          to="/respondent/settings/common-templates"
        >
          共通定型文管理
          <i class="ri-arrow-right-s-line text-2xl"></i>
        </router-link>
      </li>
      <li>
        <router-link
          class="flex justify-between items-center py-2 pr-2 pl-4 border-l-5 border-lixil-gray bg-white font-bold hover:border-lixil-green hover:text-lixil-green"
          to="/respondent/settings/question-templates"
        >
          質問テンプレート管理
          <i class="ri-arrow-right-s-line text-2xl"></i>
        </router-link>
      </li>
      <li>
        <router-link
          class="flex justify-between items-center py-2 pr-2 pl-4 border-l-5 border-lixil-gray bg-white font-bold hover:border-lixil-green hover:text-lixil-green"
          to="/respondent/settings/not-support-templates"
        >
          非対応テンプレート管理
          <i class="ri-arrow-right-s-line text-2xl"></i>
        </router-link>
      </li>
      <li>
        <router-link
          class="flex justify-between items-center py-2 pr-2 pl-4 border-l-5 border-lixil-gray bg-white font-bold hover:border-lixil-green hover:text-lixil-green"
          to="/respondent/settings/news"
        >
          お知らせ管理
          <i class="ri-arrow-right-s-line text-2xl"></i>
        </router-link>
      </li>
      <li>
        <router-link
          class="flex justify-between items-center py-2 pr-2 pl-4 border-l-5 border-lixil-gray bg-white font-bold hover:border-lixil-green hover:text-lixil-green"
          to="/respondent/settings/dictionary"
        >
          検索辞書データ管理
          <i class="ri-arrow-right-s-line text-2xl"></i>
        </router-link>
      </li>
    </ul>
  </div>
</template>
