import { reactive } from 'vue'
import { createContainer } from 'vue-unstated'
import { Attachment } from '@/types/attachment'
import { Question } from '@/types/question'
import Validator, { getMessageMaxHTML } from '@/utils/validator'

interface State {
  question: Question | null
  forms: {
    memo: string
    memoAttachments: Attachment[]
    content: string
    attachments: Attachment[]
    elapsedTime: number
    isNotQa: boolean
    isFaq: boolean
    selectedUserId: string
    revisionContent: string
  }
  errors: {
    content: string | boolean
  }
  usersOptions: {
    value: number
    name: string
  }[]
  modalType: '回答区分' | '転送' | '定型文'
  templateType: '定型文' | '共通定型文'
  // Tinymceでキャレット(カーソル位置)を一時的に保存しておくために利用
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  activeEditor: any
}

export const useQuestionDetail = (): {
  state: State
  validateForms: (isTemporarilyStore?: boolean) => boolean
  changeTemplateType: () => void
  getRevisionContent: () => string | null
} => {
  const state = reactive<State>({
    question: null,
    forms: {
      memo: '',
      memoAttachments: [],
      content: '',
      attachments: [],
      elapsedTime: 0,
      isNotQa: false,
      isFaq: false,
      selectedUserId: '1',
      revisionContent: '',
    },
    errors: {
      content: '',
    },
    usersOptions: [
      {
        value: 1,
        name: '山田太郎',
      },
      { value: 2, name: '佐藤花子' },
    ],
    modalType: '回答区分',
    templateType: '定型文',
    activeEditor: null,
  })

  const rules = {
    content: 'required|valid_html|max:15000',
  }

  const messages = {
    'required.content': '回答を入力してください',
    'valid_html.content': '回答に不正な内容が含まれています',
    'max.content': getMessageMaxHTML('回答'),
  }

  const getRules = (isTemporarilyStore: boolean) => {
    if (isTemporarilyStore) {
      return { content: 'max:15000' }
    } else {
      return rules
    }
  }

  const validateForms = (isTemporarilyStore = false): boolean => {
    resetErrors()
    const validation = new Validator(
      state.forms,
      getRules(isTemporarilyStore),
      messages
    )
    if (validation.passes()) {
      return true
    } else {
      state.errors = {
        content: validation.errors.first('content'),
      }
      return false
    }
  }

  const changeTemplateType = (): void => {
    state.templateType =
      state.templateType === '定型文' ? '共通定型文' : '定型文'
  }

  const resetErrors = () => {
    state.errors = {
      content: '',
    }
  }

  const getRevisionContent = (): string | null => {
    return state.forms.revisionContent?.trim() === ''
      ? state.forms.revisionContent?.trim()
      : state.forms.revisionContent
  }

  return {
    state,
    validateForms,
    changeTemplateType,
    getRevisionContent,
  }
}

const questionDetailContainer = createContainer(useQuestionDetail)

export default questionDetailContainer
