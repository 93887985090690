import Categories from '@/pages/respondent/settings/categories/index.vue'
import CommonTemplates from '@/pages/respondent/settings/commonTemplates/index.vue'
import ContactGroups from '@/pages/respondent/settings/contactGroups/index.vue'
import Dictionary from '@/pages/respondent/settings/dictionary/index.vue'
import Divides from '@/pages/respondent/settings/divides/index.vue'
import Settings from '@/pages/respondent/settings/index.vue'
import KnowledgeCategories from '@/pages/respondent/settings/knowledgeCategories/index.vue'
import LixilUsers from '@/pages/respondent/settings/lixilUsers/index.vue'
import News from '@/pages/respondent/settings/news/index.vue'
import NotSupportTemplates from '@/pages/respondent/settings/notSupportTemplates/index.vue'
import QuestionTemplates from '@/pages/respondent/settings/questionTemplates/index.vue'

export default [
  {
    path: 'settings',
    component: Settings,
    meta: { title: 'システム管理' },
  },
  {
    path: 'settings/divides',
    component: Divides,
    meta: { title: '振り分け管理' },
  },
  {
    path: 'settings/categories',
    component: Categories,
    meta: { title: '商品カテゴリ管理' },
  },
  {
    path: 'settings/knowledge-categories',
    component: KnowledgeCategories,
    meta: { title: '知りたいこと管理' },
  },
  {
    path: 'settings/contact-groups',
    component: ContactGroups,
    meta: { title: '窓口管理' },
  },
  {
    path: 'settings/lixil-users',
    component: LixilUsers,
    meta: { title: '社内ユーザ管理' },
  },
  {
    path: 'settings/question-templates',
    component: QuestionTemplates,
    meta: { title: '質問テンプレート管理' },
  },
  {
    path: 'settings/common-templates',
    component: CommonTemplates,
    meta: { title: '共通定型文管理' },
  },
  {
    path: 'settings/not-support-templates',
    component: NotSupportTemplates,
    meta: { title: '非対応テンプレート管理' },
  },
  {
    path: 'settings/news',
    component: News,
    meta: { title: 'お知らせ管理' },
  },
  {
    path: 'settings/dictionary',
    component: Dictionary,
    meta: { title: '検索辞書データ管理' },
  },
]
