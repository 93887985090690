import { reactive } from 'vue'
import Validator from '@/utils/validator'

interface State {
  forms: {
    files: File[]
  }
  errors: {
    files: string | boolean
  }
}

export const useDictionaryImport = (): {
  state: State
  validateForms: () => boolean
} => {
  const state = reactive<State>({
    forms: {
      files: [],
    },
    errors: {
      files: '',
    },
  })

  const rules = {
    files: 'required',
  }

  const messages = {
    'required.files': 'ファイルを選択してください',
  }

  const validateForms = (): boolean => {
    const validation = new Validator(state.forms, rules, messages)
    if (validation.passes()) {
      return true
    } else {
      state.errors = {
        files: validation.errors.first('files'),
      }
      return false
    }
  }

  return {
    state,
    validateForms,
  }
}
