import { ApiConfig } from '@/types/apiConfig'
import { Section } from '@/types/section'
import { Support } from '@/types/support'

export const DivideSettingApi: ApiConfig = {
  searches: (params: {
    knowledgeCategoryGroupId: number
    subcategoryId: number
  }) => ({
    method: 'POST',
    url: `/api/administrator/divide-settings/searches`,
    data: params,
  }),
  create: (params: {
    knowledgeCategoryGroupId: number
    subcategoryId: number
    regionIds: number[]
    sections?: Section[]
    support: Support
    contactGroupId: number
    questionTemplateId: number
    message: string
    guideUrl: string
  }) => ({
    method: 'POST',
    url: `/api/administrator/divide-settings`,
    data: params,
  }),
  update: (params: {
    divideSettingId?: number
    divideSettingDetailIds?: number[]
    regionIds: number[]
    sections: Section[]
    support: Support
    contactGroupId: number
    questionTemplateId: number
    message: string
    guideUrl: string
  }) => ({
    method: 'PUT',
    url: `/api/administrator/divide-settings`,
    data: params,
  }),
  delete: (params: {
    divideSettingId?: number
    divideSettingDetailIds?: number[]
  }) => ({
    method: 'POST',
    url: `/api/administrator/divide-settings/deletes`,
    data: params,
  }),
  export: () => ({
    method: 'GET',
    url: `/api/administrator/divide-settings/exports`,
  }),
}
