import { reactive } from 'vue'
import { createContainer } from 'vue-unstated'

interface State {
  subcategories: {
    subcategoryId: number
    name: string
    code?: string
    knowledgeCategories: {
      id: number
      name: string
    }[]
  }[]
  selectedSubcategoryId: number
}

export const useSubcategory = (): {
  state: State
  selectedSubcategory: (subcategoryId: number) => void
} => {
  const state = reactive<State>({
    subcategories: [],
    selectedSubcategoryId: 0,
  })

  const selectedSubcategory = (subcategoryId: number) => {
    state.selectedSubcategoryId = subcategoryId
  }

  return {
    state,
    selectedSubcategory,
  }
}
const subcategoryContainer = createContainer(useSubcategory)

export default subcategoryContainer
