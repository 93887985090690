import { computed, ComputedRef, reactive } from 'vue'
import { createContainer } from 'vue-unstated'
import { QUESTIONER_PER_PAGE } from '@/config/page'

interface State {
  currentPage: number
  totalPage: number
  totalCount: number
  currentPageLength: number
}

export const usePage = (): {
  state: State
  getShowFirstPageNo: ComputedRef<number>
  getShowLastPageNo: ComputedRef<number>
  needStartPage: ComputedRef<boolean>
  needEndPage: ComputedRef<boolean>
  hasMoreAfterPage: ComputedRef<boolean>
  hasMoreBeforePage: ComputedRef<boolean>
  startItemNumber: ComputedRef<number>
  endItemNumber: ComputedRef<number>
  goToNumber: (page: number) => void
} => {
  const state = reactive<State>({
    currentPage: 1,
    totalPage: 0,
    totalCount: 0,
    currentPageLength: 0,
  })

  // 表示される最小ページ
  const getShowFirstPageNo = computed(() => {
    if (state.currentPage === 1) {
      return 1
    } else if (state.currentPage > 1 && state.currentPage - 2 >= 1) {
      return state.currentPage - 2
    } else {
      return state.currentPage - 1
    }
  })

  // 表示される最大ページ
  const getShowLastPageNo = computed(() => {
    if (state.currentPage === state.totalPage) {
      return state.totalPage
    } else if (
      state.currentPage < state.totalPage &&
      state.currentPage + 2 <= state.totalPage
    ) {
      return state.currentPage + 2
    } else {
      return state.currentPage + 1
    }
  })

  // 最初を出すかどうかの出し分け
  const needStartPage = computed(() => {
    return state.totalPage > 5 && state.currentPage > 4
  })

  // 前の [...] 出し分け
  const hasMoreBeforePage = computed(() => {
    return state.currentPage - 3 >= 1
  })

  // 後の [...] 出し分け
  const hasMoreAfterPage = computed(() => {
    return state.totalPage > 3 && state.currentPage + 3 <= state.totalPage
  })

  // 最後のページを出すかどうかの出し分け
  const needEndPage = computed(() => {
    return state.totalPage > 5 && state.totalPage - state.currentPage > 3
  })

  const startItemNumber = computed(
    () => QUESTIONER_PER_PAGE * (state.currentPage - 1) + 1
  )

  const endItemNumber = computed<number>(() => {
    return startItemNumber.value + state.currentPageLength - 1
  })

  const goToNumber = (page: number) => {
    state.currentPage = page
  }

  return {
    state,
    goToNumber,
    hasMoreAfterPage,
    hasMoreBeforePage,
    getShowFirstPageNo,
    getShowLastPageNo,
    needStartPage,
    needEndPage,
    startItemNumber,
    endItemNumber,
  }
}

const pageContainer = createContainer(usePage)

export default pageContainer
