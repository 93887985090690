import { RouteRecordRaw } from 'vue-router'
import DefaultLayout from '@/layouts/questioner/DefaultLayout.vue'
import QuestionLayout from '@/layouts/questioner/QuestionLayout.vue'
import DataNotFound from '@/pages/questioner/404.vue'
import InternalServerError from '@/pages/questioner/500.vue'
import Faq from '@/pages/questioner/faq/id.vue'
import Favorites from '@/pages/questioner/favorites.vue'
import Home from '@/pages/questioner/index.vue'
import Login from '@/pages/questioner/login.vue'
import News from '@/pages/questioner/news/id.vue'
import NotSupport from '@/pages/questioner/notSupport.vue'
import Notes from '@/pages/questioner/notes.vue'
import Notifications from '@/pages/questioner/notifications.vue'
import QuestionComplete from '@/pages/questioner/question/complete.vue'
import QuestionConfirm from '@/pages/questioner/question/confirm.vue'
import Question from '@/pages/questioner/question/index.vue'
import QuestionDetail from '@/pages/questioner/questions/id.vue'
import Questions from '@/pages/questioner/questions/index.vue'
import { isAuthenticated } from '@/utils/auth'

const children: RouteRecordRaw[] = [
  {
    path: '',
    component: Home,
    meta: { title: 'TOP' },
  },
  {
    path: 'faq/:id',
    component: Faq,
    meta: { title: 'FAQ詳細' },
  },
  {
    path: 'questions',
    component: Questions,
    meta: { title: 'お問い合せ履歴' },
  },
  {
    path: 'questions/:id',
    component: QuestionDetail,
    meta: { title: 'お問い合わせ詳細' },
  },
  {
    path: 'question',
    component: QuestionLayout,
    children: [
      {
        path: '',
        component: Question,
        meta: { title: 'お問い合わせ入力' },
      },
      {
        path: 'confirm',
        component: QuestionConfirm,
        meta: { title: 'お問い合わせ確認' },
      },
      {
        path: 'complete',
        component: QuestionComplete,
        meta: { title: 'お問い合わせ完了' },
      },
    ],
  },
  {
    path: 'notes',
    component: Notes,
    meta: { title: 'お願い・注意事項' },
  },
  {
    path: 'not-support',
    component: NotSupport,
    meta: { title: '非対応お問い合わせ' },
  },
  {
    path: 'notification/:id',
    component: News,
    meta: { title: 'お知らせ' },
  },
  {
    path: 'notifications',
    component: Notifications,
    meta: { title: 'お知らせ一覧' },
  },
  {
    path: 'favorites',
    component: Favorites,
    meta: { title: 'お気に入り' },
  },
  {
    path: '404',
    component: DataNotFound,
  },
  {
    path: '500',
    component: InternalServerError,
  },
]

if (process.env.NODE_ENV === 'development') {
  children.push({
    path: 'login',
    component: Login,
  })
}

export default {
  path: '/questioner',
  component: DefaultLayout,
  children,
  beforeEnter: (to, from, next) => {
    if (to.path === '/questioner/login' && isAuthenticated())
      next({ path: '/questioner' })

    if (to.path !== '/questioner/login' && !isAuthenticated())
      next({ path: '/' })

    next()
  },
} as RouteRecordRaw
