export const createSelectedIndexArray = (
  selectedIndexArray: number[],
  index: number,
  layerDepth: number
) => {
  if (selectedIndexArray.length < layerDepth) {
    return [...selectedIndexArray, index]
  } else {
    return [...selectedIndexArray.slice(0, layerDepth - 1), index]
  }
}
