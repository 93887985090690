import { useGtm } from '@gtm-support/vue-gtm'
import { useRoute } from 'vue-router'
import { useScreenSize } from '@/use/useScreenSize'

export const useTrackCustomEvent = () => {
  const route = useRoute()
  const { SP } = useScreenSize()
  const gtm = useGtm()

  const getEventLabel = (eventLabel: string): string => {
    if (route.path.startsWith('/questioner')) {
      return SP.value ? `sp_${eventLabel}` : eventLabel
    }

    return eventLabel
  }

  const trackEvent = (
    eventCategory: string,
    eventAction: string,
    eventLabel: string
  ) => {
    if (eventCategory || eventAction || eventLabel) {
      gtm?.trackEvent({
        event: 'Click',
        category: eventCategory,
        action: eventAction,
        label: getEventLabel(eventLabel),
      })
    }
  }

  return { trackEvent }
}
