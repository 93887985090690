import { reactive } from 'vue'
import { createContainer } from 'vue-unstated'

interface State {
  breadcrumbs: {
    title: string
    to?: string
  }[]
}

export const useBreadcrumbs = (): {
  state: State
} => {
  const state = reactive<State>({
    breadcrumbs: [],
  })

  return {
    state,
  }
}

const breadcrumbsContainer = createContainer(useBreadcrumbs)

export default breadcrumbsContainer
