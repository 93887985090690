<template>
  <div class="loading-mask">
    <div class="loading-wrapper">
      <div
        class="loading-container animate-spin h-20 w-20 border-4 rounded-full border-t-transparent"
      ></div>
    </div>
  </div>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
.loading-mask {
  position: fixed;
  z-index: 12000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
}

.loading-wrapper {
  display: table-cell;
  vertical-align: middle;
  padding: 10px;
}

.loading-container {
  position: relative;
  margin: 0px auto;
}
</style>
