import { ApiConfig } from '@/types/apiConfig'

export const KnowledgeCategoryApi: ApiConfig = {
  get: (id: number) => ({
    method: 'GET',
    url: `/api/administrator/knowledge-categories/${id}`,
  }),
  create: (params: {
    parentKnowledgeLayers: string
    knowledgeCategoryName: string
    knowledgeCategoryGroupId: number
  }) => ({
    method: 'POST',
    url: `/api/administrator/knowledge-categories`,
    data: params,
  }),
  update: (
    id: number,
    params: {
      knowledgeCategoryName: string
      knowledgeCategoryGroupId: number
    }
  ) => ({
    method: 'PUT',
    url: `/api/administrator/knowledge-categories/${id}`,
    data: params,
  }),
  delete: (id: number) => ({
    method: 'DELETE',
    url: `/api/administrator/knowledge-categories/${id}`,
  }),
  sort: (params: { knowledgeCategoryIds: number[] }) => ({
    method: 'PUT',
    url: `/api/administrator/knowledge-categories/sort`,
    data: params,
  }),
}
