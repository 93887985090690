import { computed, reactive } from 'vue'
import { createContainer } from 'vue-unstated'

interface State {
  subcategories: {
    subcategoryId: number
    name: string
    code?: string
    knowledgeCategories: {
      id: number
      name: string
    }[]
  }[]
  knowledgeCategories: any
  selectedSubcategoryId: number
}

export const useKnowledgeCategory = (): {
  state: State
  selectedSubcategory: (subcategoryId: number) => void
} => {
  const state = reactive<State>({
    subcategories: [],
    knowledgeCategories: [],
    selectedSubcategoryId: 0,
  })

  const selectedSubcategory = (subcategoryId: number) => {
    state.selectedSubcategoryId = subcategoryId
  }

  state.knowledgeCategories = computed(() => {
    return state.subcategories.find(
      (category) => category.subcategoryId === state.selectedSubcategoryId
    )?.knowledgeCategories
  })

  return {
    state,
    selectedSubcategory,
  }
}
const knowledgeCategoryContainer = createContainer(useKnowledgeCategory)

export default knowledgeCategoryContainer
