import Validator from 'validatorjs'
import { validateHTML } from '@/utils/sanitize'

const excludesZenkaku = (str: string) => {
  return !!str.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]+$/)
}

Validator.register('tel', (value): boolean => {
  return typeof value === 'string'
    ? !!value.match(/^\d{2,4}-\d{2,4}-\d{4}$/) || !!value.match(/^(0\d{9,10})$/)
    : false
})

Validator.register('hankaku_email', (value): boolean => {
  if (typeof value !== 'string') return false
  return excludesZenkaku(value)
})

Validator.register('multiple_emails', (value): boolean => {
  if (typeof value !== 'string') return false
  const emailsArray = value.split(',')
  for (let i = 0; i < emailsArray.length; i++) {
    if (
      !emailsArray[i].match(
        /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/
      )
    )
      return false

    if (!emailsArray[i].includes('@lixil.com')) return false
  }
  return true
})

Validator.register('not_include_new_line', (value): boolean => {
  return typeof value === 'string' ? !includeNewLine(value) : false
})

Validator.register('valid_html', (value): boolean => {
  if (typeof value !== 'string') return false
  return validateHTML(value)
})

export const includeNewLine = (value: string) => {
  return value.includes('\n')
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isValidFilename = (_isValidFilename: any, fileName: string) => {
  const format = /[!@#$%^&*=[\]{};':"\\|,.<>/?]+/
  return _isValidFilename(fileName) && !format.test(fileName)
}

export const getMessageMaxHTML = (name: string): string => {
  return (
    `${name}の文字数が装飾を含めて制限を超えています。<br />` +
    `他のアプリケーションから入力内容を貼り付ける場合は Ctrl + 右クリックのメニューから貼り付けてみてください。`
  )
}

export default Validator
