import { nextTick, reactive } from 'vue'
import { useRoute } from 'vue-router'
import { createContainer } from 'vue-unstated'
import { Category, KnowledgeCategory, Subcategory } from '@/types/category'
import { useTrackEvent } from '@/use/useTrackEvent'

interface State {
  productCategories: Category[]
  knowledgeCategories: KnowledgeCategory[]
  selectedInitialProductCategories: (Category | Subcategory)[]
  selectedProductCategoryByKeyword: Subcategory | null
  selectedInitialKnowledgeCategories: KnowledgeCategory[]
  selectedKnowledgeCategoryByKeyword: KnowledgeCategory | null
  productCategory: Subcategory | null
  knowledgeCategory: KnowledgeCategory | null
  subcategory: Subcategory | null
  productKeyword: string
  knowledgeKeyword: string
}

export const useCategoriesTree = (): {
  state: State
  setInitialKnowledgeCategories: (selectedRootCategoryId: string) => void
  createCategoriesPath: (
    layer: number
  ) => (
    isProductCategory: boolean
  ) => (
    category: Subcategory | KnowledgeCategory,
    isEndCategory?: boolean
  ) => void
  closeCategoryTree: (isProductCategories: boolean) => void
  selectOptionByKeyword: (
    isProductCategories: boolean,
    checkIsEndCategory: (
      selectedCategory: KnowledgeCategory | Subcategory
    ) => KnowledgeCategory | Subcategory
  ) => (selectedOption: Subcategory | KnowledgeCategory) => void
} => {
  const state = reactive<State>({
    productCategories: [],
    knowledgeCategories: [],
    selectedInitialProductCategories: [],
    selectedInitialKnowledgeCategories: [],
    selectedProductCategoryByKeyword: null,
    selectedKnowledgeCategoryByKeyword: null,
    productCategory: null,
    knowledgeCategory: null,
    subcategory: null,
    productKeyword: '',
    knowledgeKeyword: '',
  })

  /**
   * setInitialKnowledgeCategories
   * 大カテゴリに応じて知りたいことを、
   * 全ての知りたいことから絞り込む
   */
  const setInitialKnowledgeCategories = async (
    selectedRootCategoryId: string
  ): Promise<void> => {
    state.selectedInitialKnowledgeCategories = []

    await nextTick()

    const rootCategory = state.productCategories.find(
      (category: Category) => category.id.toString() === selectedRootCategoryId
    ) as Category

    if (!rootCategory) return

    // 結びついている知りたいことのみに絞り込む
    const selectedKnowledgeCategories = state.knowledgeCategories.filter(
      (knowledgeCategory: KnowledgeCategory) =>
        rootCategory.knowledgeCategoryIds.includes(knowledgeCategory.id)
    )

    // 絞り込んだ知りたいことから親を含んだカテゴリに絞り込む
    state.selectedInitialKnowledgeCategories = state.knowledgeCategories.filter(
      (knowledgeCategory: KnowledgeCategory) => {
        let isIncluded = false
        for (let i = 0; i < selectedKnowledgeCategories.length; i++) {
          if (
            selectedKnowledgeCategories[i].layersPath.includes(
              knowledgeCategory.layersPath
            )
          ) {
            isIncluded = true
            break
          }
        }
        return isIncluded
      }
    )
  }

  /**
   * createCategoriesPath
   * ツリー構造コンポーネントを表示する際にクリックしたボタンへ
   * パスの文字列を表示させる処理
   */
  const createCategoriesPath =
    (layer: number) =>
    (isProductCategory: boolean) =>
    async (
      category: Subcategory | KnowledgeCategory,
      isEndCategory = true,
      isAutofill = false
    ): Promise<void> => {
      // 既に末端判定が行われている場合
      if (category.isEndCategory === false) {
        isEndCategory = false
      }

      if (isProductCategory) {
        state.subcategory = { ...category, isEndCategory } as Subcategory
        state.selectedInitialKnowledgeCategories = []
        await nextTick()
        await setInitialKnowledgeCategories(getRootCategoryId(layer))
      } else {
        state.knowledgeCategory = {
          ...category,
          isEndCategory,
        } as KnowledgeCategory
      }

      if (!category.hasChildren && !category.subcategoryId && !isAutofill) {
        closeCategoryTree(isProductCategory)
      }
    }

  const getRootCategoryId = (layer: number): string => {
    if (layer !== 1) return state.subcategory?.category.id.toString() || ''

    if (state.subcategory?.category) {
      return state.subcategory?.category.id.toString() || ''
    } else {
      return state.subcategory?.id.toString() || ''
    }
  }

  /**
   * closeCategoryTree
   * 画面の何もないところをクリックしてツリー構造を閉じたい
   */
  const closeCategoryTree = (isProductCategories = false): void => {
    const target = isProductCategories
      ? document.getElementById('product-category')
      : document.getElementById('knowledge-category')
    if (target) {
      target.click()
    }
  }

  const route = useRoute()
  const { trackEvent: trackKnowledgeCategoryKeywordEvent } = useTrackEvent(
    '/respondent/questions/preview',
    'keyword-search',
    'knowledge-category'
  )
  const { trackEvent: trackCategoryKeywordEvent } = useTrackEvent(
    '/respondent/questions/preview',
    'keyword-search',
    'category'
  )

  /**
   * selectOptionByKeyword
   * キーワード検索でカテゴリーを選択した際の処理
   */
  const selectOptionByKeyword =
    (
      isProductCategories = false,
      checkIsEndCategory:
        | ((
            selectedCategory: KnowledgeCategory | Subcategory
          ) => KnowledgeCategory | Subcategory)
        | null = null
    ) =>
    async (selectedOption: Subcategory | KnowledgeCategory): Promise<void> => {
      if (
        route.path.startsWith('/respondent/questions') &&
        !isProductCategories
      ) {
        trackKnowledgeCategoryKeywordEvent()
      }

      if (
        route.path.startsWith('/respondent/questions') &&
        isProductCategories
      ) {
        trackCategoryKeywordEvent()
      }

      if (isProductCategories) {
        state.subcategory = checkIsEndCategory
          ? (checkIsEndCategory(selectedOption as Subcategory) as Subcategory)
          : (selectedOption as Subcategory)
        state.selectedProductCategoryByKeyword = state.subcategory
      } else {
        state.knowledgeCategory = checkIsEndCategory
          ? (checkIsEndCategory(
              selectedOption as KnowledgeCategory
            ) as KnowledgeCategory)
          : (selectedOption as KnowledgeCategory)
        state.selectedKnowledgeCategoryByKeyword = state.knowledgeCategory
      }
      closeCategoryTree(isProductCategories)
    }

  return {
    state,
    createCategoriesPath,
    closeCategoryTree,
    selectOptionByKeyword,
    setInitialKnowledgeCategories,
  }
}

const categoriesTreeContainer = createContainer(useCategoriesTree)

export default categoriesTreeContainer
