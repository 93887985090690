import { ApiConfig } from '@/types/apiConfig'

export const CommonTemplateGroupApi: ApiConfig = {
  create: (params: { name: string }) => ({
    method: 'POST',
    url: `/api/administrator/common-template-groups`,
    data: params,
  }),
  update: (id: number, params: { name: string }) => ({
    method: 'PUT',
    url: `/api/administrator/common-template-groups/${id}`,
    data: params,
  }),
  delete: (id: number) => ({
    method: 'DELETE',
    url: `/api/administrator/common-template-groups/${id}`,
  }),
}
