import { ApiConfig } from '@/types/apiConfig'
import { Attachment } from '@/types/attachment'

export const QuestionApi: ApiConfig = {
  index: (params: { page: number }) => ({
    method: 'POST',
    url: `/api/questions/questioner`,
    data: params,
  }),
  searches: (params: {
    page: number
    keyword?: string
    subcategoryId?: number
    knowledgeCategoryId?: number
    perPage?: number
  }) => ({
    method: 'POST',
    url: `/api/questions/searches`,
    data: params,
  }),
  get: (id: string, keyword?: string) => {
    const query = keyword ? `?keyword=${keyword}` : ''
    return {
      method: 'GET',
      url: `/api/questions/${id}${query}`,
    }
  },
  create: (params: {
    subcategoryId: number
    knowledgeCategoryId: number
    productNumber: string
    name: string
    branch: string
    tel: string
    email: string
    otherEmail?: string
    regionId: string
    title: string
    content: string
    attachments: Attachment[]
    deleteTemporarilyStoredQuestion?: boolean
  }) => ({
    method: 'POST',
    url: `/api/questions`,
    data: params,
  }),
  createEvaluation: (
    id: string,
    params: {
      evaluation: string
      comment: string
    }
  ) => ({
    method: 'POST',
    url: `/api/questions/${id}/evaluations`,
    data: params,
  }),
  add: (
    id: string,
    params: {
      content: string
      attachments: Attachment[]
    }
  ) => ({
    method: 'POST',
    url: `/api/questions/${id}/adds`,
    data: params,
  }),
  getLatestTemporarilyStored: () => ({
    method: 'GET',
    url: `/api/questions/temporarily-stored/latest`,
  }),
  createTemporarilyStored: (params: {
    categoryId: number
    subcategoryId: number
    knowledgeCategoryId: number
    regionId: number
    productNumber: string
    name: string
    tel: string
    email: string
    branch: string
    otherEmail?: string
    title: string
    content: string
    attachments: Attachment[]
  }) => ({
    method: 'POST',
    url: `/api/questions/temporarily-stored`,
    data: params,
  }),
}
