import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')
dayjs.locale('ja')

export const now = () => dayjs().tz().format('YYYY/MM/DD HH:mm:ss')

export const formatDate = (date: string) => {
  const formatDate = dayjs(date)
  return formatDate.isValid() ? formatDate.format('YYYY/MM/DD') : ''
}

export const formatDateTime = (date: string) => {
  const formatDateTime = dayjs(date)
  return formatDateTime.isValid()
    ? formatDateTime.format('YYYY/MM/DD HH:mm')
    : ''
}

export const formatQuestionUpdatedTime = (date: string) => {
  const formatDateTime = dayjs(date)
  return formatDateTime.isValid() ? formatDateTime.format('YY/MM/DD HH:mm') : ''
}

export const formatDateTimeWithoutSeconds = (date: string) => {
  const formatDateTime = dayjs(date)
  return formatDateTime.isValid()
    ? formatDateTime.format('YYYY/MM/DD HH:mm')
    : ''
}

// お知らせに公開開始日一週間以内ならNEWをつけたい
export const isWithinAWeek = (date: string) => {
  return dayjs(date).isAfter(dayjs().subtract(1, 'w'), 'day')
}

// 指定の日付が1年以上前
export const isPastAYear = (date: string) => {
  return dayjs(date).isBefore(dayjs().subtract(1, 'y'), 'day')
}

export default dayjs
