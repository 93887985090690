import { ApiConfig } from '@/types/apiConfig'

export const UserApi: ApiConfig = {
  get: (id: string) => ({
    method: 'GET',
    url: `/api/users/${id}`,
  }),
  update: (params: { regionId: number }) => ({
    method: 'PUT',
    url: `/api/users`,
    data: params,
  }),
}
