import { reactive } from 'vue'
import { AuthApi } from '@/api/auth'
import { UNAUTHORIZED_REDIRECT_PATH_KEY } from '@/config/sesstion'
import router from '@/router'
import httpContainer from '@/use/useHttp'
import userContainer from '@/use/useUser'
import { resetAuth, setAuth } from '@/utils/auth'

interface State {
  userId: string
  code: string
  authState: string
  error: string
}

export const useAuth = (
  role: 'questioner' | 'respondent'
): {
  state: State
  login: (code: string, state: string) => void
  logout: (isForbiddenUser?: boolean | Event) => void
} => {
  const { exec, execWithout422ErrorAlert } = httpContainer.useContainer()
  const {
    state: userState,
    resetViewedNotes,
    isFaqCreator,
    noRole,
  } = userContainer.useContainer()

  const state = reactive<State>({
    userId: 'test000001',
    code: 'test12345abcdefg',
    authState: '',
    error: '',
  })

  const login = async (code: string, authState: string | Event) => {
    const query: {
      code?: string
      state?: string
      userId?: string
    } = {}
    if (typeof authState === 'string') {
      query.code = code
      query.state = authState
    } else {
      query.userId = state.userId
      query.code = state.code
      query.state = state.authState
    }
    const res = await execWithout422ErrorAlert(AuthApi.login(query))
    if (res?.status === 200) {
      state.error = ''
      setAuth(res.data)
      userState.currentUser = res.data

      const path = localStorage.getItem(UNAUTHORIZED_REDIRECT_PATH_KEY) || ''
      if (path) {
        localStorage.removeItem(UNAUTHORIZED_REDIRECT_PATH_KEY)
        return await router.push(path)
      }

      if (role === 'respondent' && query.code && !isFaqCreator.value) {
        return await router.push(`/respondent`)
      }

      if (query.code || noRole.value) {
        return await router.push(`/questioner`)
      }

      if (role === 'respondent' && isFaqCreator.value) {
        return await router.push(`/respondent/faq`)
      }

      await router.push(`/${role}`)
    } else if (res?.status === 422 && res.data.message.externalUserForbidden) {
      await logout(true)
    } else {
      state.error = 'ログインできませんでした'
      await logout()
    }
  }

  const logout = async (isForbiddenUser?: boolean | Event) => {
    await exec(AuthApi.logout())
    resetAuth()
    userState.currentUser = null
    resetViewedNotes()
    if (process.env.NODE_ENV === 'development') {
      await router.push(`/${role}/login`)
    } else {
      const returnUrl =
        typeof isForbiddenUser === 'boolean' && isForbiddenUser
          ? `${process.env.VUE_APP_BASE_URL}/manual/not_authorized.html`
          : process.env.VUE_APP_BASE_URL
      window.location.href = `${process.env.VUE_APP_EAA_OIDC_URL}/api/v2/logout?return_url=${returnUrl}`
    }
  }

  return {
    state,
    login,
    logout,
  }
}
