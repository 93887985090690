import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import { createContainer } from 'vue-unstated'
import { Faq } from '@/types/faq'

interface State {
  forms: {
    keyword: string
    subcategoryId: number
    knowledgeCategoryId: number
  }
  faq: {
    data: Faq[]
    total: number
    lastPage: number
    currentPage: number
  }
  isSearched: boolean
}

export const useSearchFaq = (): {
  state: State
  updateSearchConditionsFromUrlQuery: () => void
} => {
  const router = useRouter()

  const state = reactive<State>({
    forms: {
      keyword: '',
      subcategoryId: 0,
      knowledgeCategoryId: 0,
    },
    faq: {
      data: [],
      total: 0,
      lastPage: 0,
      currentPage: 0,
    },
    isSearched: false,
  })
  /**
   * URLパラメータを更新する
   */
  const updateSearchConditionsFromUrlQuery = () => {
    router.push({
      path: '/public/faq/search',
      query: createParams(state.forms),
    })
  }

  /**
   * URLパラメータを作成する
   */
  const createParams = (searchConditions: {
    keyword?: string
    subcategoryId?: number
    knowledgeCategoryId?: number
  }) => {
    const params: {
      keyword?: string
      subcategoryId?: number
      knowledgeCategoryId?: number
    } = {}
    if (searchConditions.keyword) {
      params.keyword = searchConditions.keyword.trim()
    }
    if (searchConditions.subcategoryId) {
      params.subcategoryId = searchConditions.subcategoryId
    }
    if (searchConditions.knowledgeCategoryId) {
      params.knowledgeCategoryId = searchConditions.knowledgeCategoryId
    }
    return params
  }

  return {
    state,
    updateSearchConditionsFromUrlQuery,
  }
}

const searchFaqContainer = createContainer(useSearchFaq)

export default searchFaqContainer
