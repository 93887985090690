import { reactive, watch } from 'vue'
import { KnowledgeCategory, Subcategory } from '@/types/category'
import { debounce } from '@/use/useDebounce'
import loadingSpinnerContainer from '@/use/useLoadingSpinner'

interface State<T> {
  loading: boolean
  options: T[]
  count: number
}

export const useVSelectSearch = <U>(
  search: (keyword: string) => Promise<void>
): {
  state: State<U>
  setSearchWord: (search: string) => void
  checkIsEndCategory: (
    selectedCategory: KnowledgeCategory | Subcategory
  ) => KnowledgeCategory | Subcategory
} => {
  const searchWord = debounce<string>('')
  const state = reactive({
    loading: false,
    options: [],
    count: 0,
  })

  const { loading } = loadingSpinnerContainer.useContainer()

  const setSearchWord = (search: string, reset = false): void => {
    if (!search && !reset) return
    searchWord.value = search
  }

  watch(
    () => searchWord.value,
    async (newValue, oldValue) => {
      if (newValue === oldValue) return
      loading(async () => {
        state.loading = true
        await search(newValue)
        state.loading = false
      })
    }
  )

  // カテゴリーの末端チェック時に使用
  // カテゴリーツリーのデータではなく、optionsから特定する必要があるため
  // このcomposable内に関数を定義している
  const checkIsEndCategory = (
    selectedCategory: KnowledgeCategory | Subcategory
  ): KnowledgeCategory | Subcategory => {
    const filteredCategories = state.options.filter((category) =>
      (category as KnowledgeCategory | Subcategory).layersPath.includes(
        selectedCategory.layersPath
      )
    )

    if (filteredCategories.length > 1) {
      selectedCategory.isEndCategory = false
      return selectedCategory
    }

    return selectedCategory
  }

  return {
    state,
    setSearchWord,
    checkIsEndCategory,
  }
}
