import { RouteRecordRaw } from 'vue-router'
import DefaultLayout from '@/layouts/respondent/DefaultLayout.vue'
import DataNotFound from '@/pages/respondent/404.vue'
import InternalServerError from '@/pages/respondent/500.vue'
import CsvDownload from '@/pages/respondent/csvDownload/index.vue'
import FaqCreate from '@/pages/respondent/faq/create.vue'
import FaqDetail from '@/pages/respondent/faq/id.vue'
import Faq from '@/pages/respondent/faq/index.vue'
import Login from '@/pages/respondent/login.vue'
import News from '@/pages/respondent/news/id.vue'
import Notifications from '@/pages/respondent/notifications.vue'
import QaDetail from '@/pages/respondent/qa/id.vue'
import Qa from '@/pages/respondent/qa/index.vue'
import QuestionsDetailFaq from '@/pages/respondent/questions/id/faq.vue'
import QuestionsDetail from '@/pages/respondent/questions/id/index.vue'
import QuestionTransfer from '@/pages/respondent/questions/id/transfer.vue'
import Questions from '@/pages/respondent/questions/index.vue'
import RawData from '@/pages/respondent/rawData/index.vue'
import RealtimeReports from '@/pages/respondent/realtimeReports/index.vue'
import SettingsRoutes from '@/router/settings'

import {
  isAdmin,
  isAuthenticated,
  isFaqCreator,
  isRespondent,
} from '@/utils/auth'

const children: RouteRecordRaw[] = [
  {
    path: '',
    redirect: '/respondent/questions',
  },
  {
    path: 'questions',
    component: Questions,
    meta: { title: 'お問い合わせ管理' },
  },
  {
    path: 'questions/:id',
    component: QuestionsDetail,
    meta: { title: 'お問い合わせ詳細' },
  },
  {
    path: 'questions/:id/faq',
    component: QuestionsDetailFaq,
    meta: { title: 'FAQ登録（お問い合わせ）' },
  },
  {
    path: 'questions/:id/transfer',
    component: QuestionTransfer,
    meta: { title: '転送' },
  },
  {
    path: 'faq',
    component: Faq,
    meta: { title: 'FAQ管理' },
  },
  {
    path: 'faq/create',
    component: FaqCreate,
    meta: { title: 'FAQ登録' },
  },
  {
    path: 'faq/:id',
    component: FaqDetail,
    meta: { title: 'FAQ編集' },
  },
  {
    path: 'qa',
    component: Qa,
    meta: { title: '過去QA管理' },
  },
  {
    path: 'qa/:id',
    component: QaDetail,
    meta: { title: 'FAQ登録（過去QA）' },
  },
  {
    path: 'csv-download',
    component: CsvDownload,
    meta: { title: 'ダウンロード管理' },
  },
  {
    path: 'notification/:id',
    component: News,
    meta: { title: 'お知らせ' },
  },
  {
    path: 'notifications',
    component: Notifications,
    meta: { title: 'お知らせ' },
  },
  {
    path: 'realtime-reports',
    component: RealtimeReports,
    meta: { title: 'レポート' },
  },
  {
    path: 'raw-data',
    component: RawData,
    meta: { title: 'アクセスデータ' },
  },
  {
    path: '404',
    component: DataNotFound,
  },
  {
    path: '500',
    component: InternalServerError,
  },
  ...SettingsRoutes,
]

if (process.env.NODE_ENV === 'development') {
  children.push({
    path: 'login',
    component: Login,
  })
}

export default {
  path: '/respondent',
  component: DefaultLayout,
  children,
  beforeEnter: (to, from, next) => {
    if (isAuthenticated()) {
      if (to.path === '/respondent/login') next({ path: '/respondent' })

      if (!isRespondent() && !isAdmin() && !isFaqCreator())
        next({ path: '/questioner' })

      if (
        (to.path === '/respondent/questions' ||
          to.path === '/respondent/qa' ||
          to.path === '/respondent/raw-data' ||
          to.path === '/respondent/realtime-reports') &&
        isFaqCreator()
      )
        next({ path: '/respondent/faq' })

      if (to.path.includes('/respondent/settings') && !isAdmin())
        next({ path: '/respondent' })

      next()
    } else {
      if (to.path !== '/respondent/login') next({ path: '/' })
    }
    next()
  },
} as RouteRecordRaw
