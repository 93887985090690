import { reactive } from 'vue'
import { Category } from '@/domain/category/category.model'
import { knowledgeCategoryMapper } from '@/domain/knowledgeCategory/knowledgeCategory.mapper'
import { KnowledgeCategory } from '@/domain/knowledgeCategory/knowledgeCategory.model'
import { createSelectedIndexArray } from '@/use/categoryTree/singleCategoryTree/libs/createSelectedIndexArray'
import { scrollToTop } from '@/use/categoryTree/singleCategoryTree/libs/scrollToTop'
import { useProductSingleCategoryTree } from '@/use/categoryTree/singleCategoryTree/useProductSingleCategoryTree'

interface State {
  selectedIndexArray: number[]
  categories: KnowledgeCategory[]
  selectedCategory: KnowledgeCategory | null
  filteredKnowledgeCategories: KnowledgeCategory[]
}

export const useSingleCategoryTree = (
  fns: {
    getSubcategories: ({
      categoryId,
      knowledgeCategoryId,
    }: {
      categoryId?: number
      knowledgeCategoryId?: number
    }) => Promise<Category[]>
    loading: (callback: () => Promise<void>) => void
  },
  options: {
    confirmBeforeClickLayer?: (
      knowledgeCategory: KnowledgeCategory,
      category: Category
    ) => Promise<boolean>
    afterClickLayer?: (category: Category | KnowledgeCategory) => Promise<void>
    trackClickEndLayerEvent?: () => void
  } = {}
) => {
  const productSingleCategoryTree = useProductSingleCategoryTree(fns, options)

  const state = reactive<State>({
    selectedIndexArray: [],
    categories: [],
    selectedCategory: null,
    filteredKnowledgeCategories: [],
  })

  const filterKnowledgeCategories = (rootId: number) => {
    const rootCategory = productSingleCategoryTree.state.categories.find(
      (c) => c.id === rootId
    ) as Category
    const knowledgeCategoryIds = rootCategory?.knowledgeCategoryIds
    if (!knowledgeCategoryIds) {
      state.filteredKnowledgeCategories = []
      return
    }
    state.filteredKnowledgeCategories = state.categories
      .filter((c) => c.layersDepth === 1)
      .map((category) => {
        category.children = state.categories.filter((c) =>
          knowledgeCategoryIds?.includes(c.id)
        )
        return category
      })
      .filter((c) => c.hasChildren)
  }

  const clickLayer = async (
    category: KnowledgeCategory,
    index: number,
    layerDepth: number
  ) => {
    state.selectedIndexArray = createSelectedIndexArray(
      state.selectedIndexArray,
      index,
      layerDepth
    )

    state.selectedCategory = category
    productSingleCategoryTree.state.selectedKnowledgeCategory = category

    scrollToTop()

    if (!category.hasChildren) {
      closeCategoryTree()
    }

    if (options && options.afterClickLayer) {
      await options.afterClickLayer(category)
    }
  }

  /**
   * SPでカテゴリを変更したときのイベント
   */
  const changeLayer = (
    category: KnowledgeCategory,
    index: number,
    layerDepth: number
  ) => {
    productSingleCategoryTree.state.selectedKnowledgeCategory = category
    state.selectedIndexArray = createSelectedIndexArray(
      state.selectedIndexArray,
      index,
      layerDepth
    )
  }

  /**
   * SPでカテゴリを選択したときのイベント
   */
  const selectCategory = () => {
    if (state.selectedIndexArray.length === 0) return
    const category = state.filteredKnowledgeCategories[
      state.selectedIndexArray[0]
    ] as KnowledgeCategory
    if (category && state.selectedIndexArray.length > 1) {
      state.selectedCategory = getSelectedCategory(category)
    } else {
      state.selectedCategory = category
    }
    closeCategoryTree()
  }

  const getSelectedCategory = (
    category: KnowledgeCategory
  ): KnowledgeCategory => {
    const childCategory =
      category.children[state.selectedIndexArray[category.layersDepth]]
    if (state.selectedIndexArray.length > category.layersDepth + 1) {
      return getSelectedCategory(childCategory)
    } else {
      return childCategory
    }
  }

  const selectByCategoryId = (category: KnowledgeCategory) => {
    state.selectedCategory = category
    productSingleCategoryTree.state.selectedKnowledgeCategory = category
    const rootIndex = state.filteredKnowledgeCategories.findIndex(
      (c) => c.layers[0] === category.layers[0]
    )
    const secondIndex = state.filteredKnowledgeCategories[
      rootIndex
    ].children.findIndex((c) => c.layers[1] === category.layers[1])
    if (secondIndex === -1) {
      state.selectedCategory = state.filteredKnowledgeCategories[rootIndex]
      state.selectedIndexArray = [rootIndex]
    } else {
      state.selectedIndexArray = [rootIndex, secondIndex]
    }
  }

  const selectCategoryByKeyword = (category: KnowledgeCategory) => {
    selectByCategoryId(knowledgeCategoryMapper(category))
    closeCategoryTree()
  }

  const resetCategory = () => {
    state.selectedCategory = null
    state.selectedIndexArray = []
  }

  const clearLayer = (layerDepth: number) => {
    state.selectedIndexArray = state.selectedIndexArray.slice(0, layerDepth - 1)
  }

  return {
    productSingleCategoryTree,
    knowledgeSingleCategoryTree: {
      state,
      filterKnowledgeCategories,
      clickLayer,
      selectByCategoryId,
      resetCategory,
      selectCategoryByKeyword,
      changeLayer,
      selectCategory,
      closeCategoryTree,
      clearLayer,
    },
  }
}

const closeCategoryTree = () => {
  const target = document.getElementById('knowledge-category')
  if (target) {
    target.click()
  }
}
