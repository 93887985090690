import { ApiConfig } from '@/types/apiConfig'

export const DivideSettingApi: ApiConfig = {
  getNotSupport: (
    knowledgeCategoryId: number,
    subcategoryId: number,
    params: {
      regionId: string | null
    }
  ) => ({
    method: 'POST',
    url: `/api/divide-settings/${knowledgeCategoryId}/${subcategoryId}/not-support`,
    data: params,
  }),
  getRequiredRegion: (knowledgeCategoryId: number, subcategoryId: number) => ({
    method: 'GET',
    url: `/api/divide-settings/${knowledgeCategoryId}/${subcategoryId}/required-regions`,
  }),
  getContactGroup: (
    knowledgeCategoryId: number,
    subcategoryId: number,
    params: {
      regionId: number | null
    }
  ) => ({
    method: 'POST',
    url: `/api/divide-settings/${knowledgeCategoryId}/${subcategoryId}/contact-group`,
    data: params,
  }),
}
