import { reactive } from 'vue'
import { KnowledgeCategory } from '@/types/category'
import { Section } from '@/types/section'
import Validator from '@/utils/validator'

export type FormType = 'create' | 'edit' | 'createRoot' | 'editRoot' | ''

interface State {
  formType: FormType
  selectedCategoryId: string
  knowledgeCategories: KnowledgeCategory[]
  forms: {
    subcategoryName: string
    isFaq: boolean
    isDivideSetting: boolean
    isSearchSections: Section[]
    isQuestionSections: Section[]
  }
  errors: {
    subcategoryName: boolean | string
  }
}

export const useCategories = (): {
  state: State
  validateForms: () => boolean
  resetForm: () => void
  resetErrors: () => void
} => {
  const state = reactive<State>({
    formType: '',
    selectedCategoryId: '',
    knowledgeCategories: [],
    forms: {
      subcategoryName: '',
      isFaq: false,
      isDivideSetting: false,
      isQuestionSections: [],
      isSearchSections: [],
    },
    errors: {
      subcategoryName: '',
    },
  })

  const rules = {
    subcategoryName: 'required',
  }

  const messages = {
    'required.subcategoryName': '商品カテゴリ名を入力してください',
  }

  const validateForms = (): boolean => {
    resetErrors()
    const validation = new Validator(state.forms, rules, messages)
    if (validation.passes()) {
      return true
    } else {
      state.errors = {
        subcategoryName: validation.errors.first('subcategoryName'),
      }
      return false
    }
  }

  const resetForm = () => {
    state.forms = {
      subcategoryName: '',
      isFaq: false,
      isDivideSetting: false,
      isSearchSections: [],
      isQuestionSections: [],
    }
  }

  const resetErrors = () => {
    state.errors = {
      subcategoryName: '',
    }
  }

  return {
    state,
    validateForms,
    resetForm,
    resetErrors,
  }
}
