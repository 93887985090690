import { reactive } from 'vue'
import { KnowledgeCategory } from '@/types/category'
import { Section } from '@/types/section'
import Validator from '@/utils/validator'

export type FormType = 'create' | 'edit' | 'createRoot' | ''

interface State {
  formType: FormType
  selectedCategoryId: string
  knowledgeCategories: KnowledgeCategory[]
  forms: {
    knowledgeCategoryName: string
    knowledgeCategoryGroupId: number | null
    isSearchSections: Section[]
  }
  errors: {
    knowledgeCategoryName: boolean | string
    knowledgeCategoryGroupId: boolean | string
  }
}

export const useKnowledgeCategory = (): {
  state: State
  validateForms: (needKnowledgeGroupId?: boolean) => boolean
  resetForm: () => void
  resetErrors: () => void
} => {
  const state = reactive<State>({
    formType: '',
    selectedCategoryId: '',
    knowledgeCategories: [],
    forms: {
      knowledgeCategoryName: '',
      knowledgeCategoryGroupId: 0,
      isSearchSections: [],
    },
    errors: {
      knowledgeCategoryName: '',
      knowledgeCategoryGroupId: '',
    },
  })

  const validateForms = (needKnowledgeGroupId = false): boolean => {
    resetErrors()

    const rules: { [key: string]: string } = {
      knowledgeCategoryName: 'required',
    }

    const messages: { [key: string]: string } = {
      'required.knowledgeCategoryName': '知りたいこと名を入力してください',
    }

    if (needKnowledgeGroupId) {
      rules.knowledgeCategoryGroupId = 'required'
      messages['required.knowledgeCategoryGroupId'] =
        '知りたいことグループを入力してください'
    }

    const params = { ...state.forms }

    if (state.forms.knowledgeCategoryGroupId === 0) {
      params.knowledgeCategoryGroupId = null
    }

    const validation = new Validator(params, rules, messages)
    if (validation.passes()) {
      return true
    } else {
      state.errors = {
        knowledgeCategoryName: validation.errors.first('knowledgeCategoryName'),
        knowledgeCategoryGroupId: validation.errors.first(
          'knowledgeCategoryGroupId'
        ),
      }
      return false
    }
  }

  const resetForm = () => {
    state.forms = {
      knowledgeCategoryName: '',
      knowledgeCategoryGroupId: 0,
      isSearchSections: [],
    }
  }

  const resetErrors = () => {
    state.errors = {
      knowledgeCategoryName: '',
      knowledgeCategoryGroupId: '',
    }
  }

  return {
    state,
    validateForms,
    resetForm,
    resetErrors,
  }
}
