import { reactive } from 'vue'
import { Faq } from '@/types/faq'
import { Qa } from '@/types/qa'

interface State {
  forms: {
    keyword: string
    subcategoryIds: string
  }
  errors: {
    keyword: boolean | string
  }
  faq: {
    data: Faq[]
    total: number
    lastPage: number
    currentPage: number
  }
  qa: {
    data: Qa[]
    total: number
    lastPage: number
    currentPage: number
  }
}

export const useSearchFaqAndQa = (): {
  state: State
} => {
  const state = reactive<State>({
    forms: {
      keyword: '',
      subcategoryIds: '',
    },
    errors: {
      keyword: '',
    },
    faq: {
      data: [],
      total: 0,
      lastPage: 0,
      currentPage: 0,
    },
    qa: {
      data: [],
      total: 0,
      lastPage: 0,
      currentPage: 0,
    },
  })

  return {
    state,
  }
}
