import { ApiConfig } from '@/types/apiConfig'

export const UserTemplateApi: ApiConfig = {
  searches: (params: { keyword: string; perPage: number }) => ({
    method: 'POST',
    url: `/api/respondent/user-templates/searches`,
    data: params,
  }),
  create: (params: { keyword: string; perPage: number }) => ({
    method: 'POST',
    url: `/api/respondent/user-templates`,
    data: params,
  }),
  update: (id: number, params: { contentWithTag: string }) => ({
    method: 'PUT',
    url: `/api/respondent/user-templates/${id}`,
    data: params,
  }),
  delete: (id: number) => ({
    method: 'DELETE',
    url: `/api/respondent/user-templates/${id}`,
  }),
}
