import { reactive } from 'vue'
import { Attachment } from '@/types/attachment'
import { ContactGroup } from '@/types/contactGroup'
import Validator from '@/utils/validator'

export type FormatResponder = {
  userId: string
  name: string
  contactGroupId: number
  contactGroupName: string
}

interface State {
  transfer: '所属窓口以外' | '対応履歴'
  forms: {
    content: string
    attachments: Attachment[]
  }
  errors: {
    contactGroupId: string | boolean
  }
  selectedContactGroup: ContactGroup | null
  selectedResponder: FormatResponder | null
  responders: FormatResponder[]
}

export const useTransfer = (): {
  state: State
  createParams: () => {
    contactGroupId?: number
    userId?: string
    content: string
  }
  selectContactGroupOptionByKeyword: (option: ContactGroup) => void
  selectResponderOptionByKeyword: (option: FormatResponder) => void
  validateForms: (params: {
    contactGroupId?: string
    userId?: string
    content: string
  }) => boolean
} => {
  const state = reactive<State>({
    transfer: '所属窓口以外',
    forms: {
      content: '',
      attachments: [],
    },
    errors: {
      contactGroupId: '',
    },
    selectedContactGroup: null,
    selectedResponder: null,
    responders: [],
  })

  const rules = {
    contactGroupId: 'required',
  }

  const messages = {
    'required.contactGroupId': '窓口もしくは回答者を必ず選択してください',
  }

  const validateForms = (params: {
    contactGroupId?: string
    userId?: string
    content: string
  }): boolean => {
    resetErrors()
    const validation = new Validator(params, rules, messages)
    if (validation.passes()) {
      return true
    } else {
      state.errors = {
        contactGroupId: validation.errors.first('contactGroupId'),
      }
      return false
    }
  }

  const resetErrors = () => {
    state.errors.contactGroupId = ''
  }

  const createParams = (): {
    contactGroupId?: number
    userId?: string
    content: string
  } => {
    const params: {
      contactGroupId?: number
      userId?: string
      content: string
      attachments: Attachment[]
    } = {
      content: state.forms.content,
      attachments: state.forms.attachments,
    }

    if (state.transfer === '所属窓口以外') {
      params.contactGroupId = state.selectedContactGroup?.id
    } else if (state.transfer === '対応履歴') {
      params.contactGroupId = state.selectedResponder?.contactGroupId
      params.userId = state.selectedResponder?.userId
    }

    return params
  }

  const selectContactGroupOptionByKeyword = (option: ContactGroup) => {
    state.selectedContactGroup = option
  }

  const selectResponderOptionByKeyword = (option: FormatResponder) => {
    state.selectedResponder = option
  }

  return {
    state,
    createParams,
    selectContactGroupOptionByKeyword,
    selectResponderOptionByKeyword,
    validateForms,
  }
}
