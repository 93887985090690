import { ApiConfig } from '@/types/apiConfig'

export const UsersApi: ApiConfig = {
  searches: (
    params: {
      keyword?: string
      per_page?: number
    },
    cursor: string
  ) => ({
    method: 'POST',
    url: `/api/users/searches?cursor=${cursor}`,
    data: params,
  }),
}
