import { computed, reactive, watch } from 'vue'

export const useMultipleCheck = <T extends { id: number; name: string }>() => {
  interface State {
    allItems: T[]
    checkedItems: T[]
    filteredItems: T[]
    isAllChecked: boolean
    keyword: string
  }

  const state = reactive<State>({
    allItems: [],
    checkedItems: [],
    filteredItems: [],
    isAllChecked: false,
    keyword: '',
  })

  const check = (value: number) => {
    const selectedItem = state.filteredItems.find(
      (item) => item.id === Number(value)
    )

    if (!selectedItem) return

    if (state.checkedItems.some((item) => item.id === Number(value))) {
      state.checkedItems = state.checkedItems.filter(
        (i) => i.id !== Number(value)
      )
    } else {
      state.checkedItems = [...state.checkedItems, selectedItem]
    }
  }

  const removeItem = (selectedItem: T) => {
    state.checkedItems = state.checkedItems.filter(
      (item) => item.id !== selectedItem.id
    )
  }

  watch(
    () => state.keyword,
    (keyword: string) => {
      state.filteredItems = state.allItems.filter((item) =>
        item.name.includes(keyword)
      )
    }
  )

  watch(
    () => state.isAllChecked,
    () => {
      if (state.isAllChecked) {
        state.checkedItems = state.filteredItems
      } else {
        state.checkedItems = []
      }
    }
  )

  const selectedIds = computed(() => {
    return state.checkedItems.map((item) => item.id)
  })

  return {
    state,
    check,
    removeItem,
    selectedIds,
  }
}
