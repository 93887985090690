import { reactive } from 'vue'
import { ContactGroup } from '@/types/contactGroup'
import Validator from '@/utils/validator'

interface State {
  type: 'create' | 'update' | ''
  searchForms: {
    contactGroupId: string
    contactGroupName: string
    email: string
    includeDeleted: boolean
    nextCursor: string
  }
  contactGroups: ContactGroup[]
  totalCount: number
  selectedContactGroup: ContactGroup | null
  editForms: {
    contactGroupName: string
    email: string
    endDate: string
    isQuestion: boolean
    isFaq: boolean
  }
  errors: {
    contactGroupName: string | boolean
    email: string | boolean
    endDate: string | boolean
  }
  isShownWithoutFilter: boolean
}

export const useContactGroup = (): {
  state: State
  validateForms: () => boolean
  resetFormError: () => void
} => {
  const state = reactive<State>({
    type: '',
    searchForms: {
      contactGroupId: '',
      contactGroupName: '',
      email: '',
      includeDeleted: false,
      nextCursor: '',
    },
    contactGroups: [],
    totalCount: 0,
    selectedContactGroup: null,
    editForms: {
      contactGroupName: '',
      email: '',
      endDate: '',
      isQuestion: false,
      isFaq: false,
    },
    errors: {
      contactGroupName: '',
      email: '',
      endDate: '',
    },
    isShownWithoutFilter: false,
  })

  const rules = {
    contactGroupName: 'required',
    email: 'email',
    endDate: 'required',
  }

  const messages = {
    'required.contactGroupName': '窓口名を入力してください',
    'email.email': '正しいメールアドレスの形式で入力してください',
    'required.endDate': '受付終了時間を入力してください',
  }

  const validateForms = (): boolean => {
    resetFormError()
    const validation = new Validator(state.editForms, rules, messages)
    if (validation.passes()) {
      return true
    } else {
      state.errors = {
        contactGroupName: validation.errors.first('contactGroupName'),
        email: validation.errors.first('email'),
        endDate: validation.errors.first('endDate'),
      }
      return false
    }
  }

  const resetFormError = () => {
    state.errors = {
      contactGroupName: '',
      email: '',
      endDate: '',
    }
  }

  return {
    state,
    validateForms,
    resetFormError,
  }
}
