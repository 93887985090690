import { Status } from '@/types/Status'
import { ApiConfig } from '@/types/apiConfig'
import { Attachment } from '@/types/attachment'

export const QuestionApi: ApiConfig = {
  get: (id: string) => ({
    method: 'GET',
    url: `/api/respondent/questions/${id}`,
  }),
  searches: (params: {
    scrollId?: string
    keyword?: string
    id?: string
    processStatus?: string
    contactGroupIds?: number[]
    respondentId?: string
    orderBy: string
    order: string
  }) => ({
    method: 'POST',
    url: `/api/respondent/questions/searches`,
    data: params,
  }),
  createTemporarilyStore: (
    id: string,
    params: {
      memo: string
      elapsedTime: number
      content: string
      attachments: Attachment[]
    }
  ) => ({
    method: 'POST',
    url: `/api/respondent/questions/${id}/temporarily-stored`,
    data: params,
  }),
  createInitialResponse: (
    id: string,
    params: {
      memo: string
      elapsedTime: number
      content: string
      attachments: Attachment[]
    }
  ) => ({
    method: 'POST',
    url: `/api/respondent/questions/${id}/initial-responses`,
    data: params,
  }),
  createAnswer: (
    id: string,
    params: {
      memo: string
      elapsedTime: number
      content: string
      attachments: Attachment[]
      firstSectionId: number
      secondSectionId?: number
    }
  ) => ({
    method: 'POST',
    url: `/api/respondent/questions/${id}/answers`,
    data: params,
  }),
  transfer: (
    id: number,
    params: {
      contactGroupId?: number
      userId?: string
      content: string
    }
  ) => ({
    method: 'POST',
    url: `/api/respondent/questions/${id}/transfer`,
    data: params,
  }),
  getHistoryResponders: (id: number) => ({
    method: 'GET',
    url: `/api/respondent/questions/${id}/history-responders`,
  }),
  changeCategories: (
    id: number,
    params: {
      subcategoryId: number
      knowledgeCategoryId: number
      regionId?: number
    }
  ) => ({
    method: 'POST',
    url: `/api/respondent/questions/${id}/change-categories`,
    data: params,
  }),
  checkChangeContactGroup: (
    id: number,
    params: {
      subcategoryId: number
      knowledgeCategoryId: number
      regionId?: number
    }
  ) => ({
    method: 'POST',
    url: `/api/respondent/questions/${id}/change-contact-groups/checks`,
    data: params,
  }),
  changeStatus: (id: string, params: { status: Status }) => ({
    method: 'POST',
    url: `/api/respondent/questions/${id}/change-statuses`,
    data: params,
  }),
  changeResponder: (
    id: number,
    params: {
      userId: string
    }
  ) => ({
    method: 'POST',
    url: `/api/respondent/questions/${id}/change-responder`,
    data: params,
  }),
  updateSettings: (
    id: string,
    params: {
      isNotQa: boolean
      isFaq: boolean
    }
  ) => ({
    method: 'PUT',
    url: `/api/respondent/questions/${id}/settings`,
    data: params,
  }),
  answerMemo: (
    id: string,
    params: {
      memo: string
      memoAttachments: Attachment[]
      revisionContent: string
    }
  ) => ({
    method: 'POST',
    url: `/api/respondent/questions/${id}/answer-memo`,
    data: params,
  }),
}
