import { ApiConfig } from '@/types/apiConfig'

export const UserApi: ApiConfig = {
  update: (params: { userId: string; tel: string; role: string }) => ({
    method: 'PUT',
    url: `/api/administrator/users`,
    data: params,
  }),
  searches: (
    params: {
      userId?: string
      globalId?: string
      name: string
      branch: string
      contactGroupId?: number
      canContactGroupUser?: boolean
    },
    cursor: string
  ) => ({
    method: 'POST',
    url: `/api/administrator/users/searches?cursor=${cursor}`,
    data: params,
  }),
}
