import { HistoryType } from '@/types/historyType'
import { History } from '@/types/question'

export const useQuestionHistory = (): {
  transformType: (history: History) => HistoryType | '回答'
} => {
  const transformType = (history: History): HistoryType | '回答' => {
    return history.type === '本格回答' || history.type === '一次回答'
      ? '回答'
      : history.type
  }

  return {
    transformType,
  }
}
