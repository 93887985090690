export const MYME_TYPES = `application/vnd.ms-excel,
           application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
           application/vnd.ms-excel.sheet.macroEnabled.12,
           text/csv,
           application/msword,
           application/vnd.openxmlformats-officedocument.wordprocessingml.document,
           application/oxps,
           application/vnd.ms-xpsdocument
           application/pdf,
           text/plain,
           application/rtf
           application/vnd.ms-powerpoint
           application/vnd.openxmlformats-officedocument.presentationml.presentation
           text/html
           image/jpeg
           image/tiff
           image/jpeg
           image/bmp
           image/png
           video/mp4
           video/x-msvideo
           video/quicktime
           video/webm
           video/x-flv
           message/rfc822
           application/zip
           application/x-lzh-compressed`

export const EXTENSIONS =
  /\.(xls|xlsx|xlsm|csv|doc|docx|xps|pdf|txt|rtf|ppt|pptx|htm|html|jpg|tif|jpeg|bmp|png|mp4|avi|mov|webm|flv|eml|zip|lzh)$/i

export const VIEWABLE_EXTENSIONS = /\.(pdf|jpg|jpeg|bmp|png)$/i

export const VIEWABLE_MODAL_EXTENSIONS = /\.(jpg|jpeg|bmp|png)$/i

export const SIZE_LIMIT = 20000000 // 20MB

export const CSV_MYME_TYPE = 'text/csv'

export const TEXT_MYME_TYPE = 'text/plain'

export const CSV_SIZE_LIMIT = 10000000 // 10MB

export const TEXT_SIZE_LIMIT = 10000000 // 10MB
