import { Section } from '@/types/section'

export const SECTIONS: Section[] = [
  '社内',
  'サッシ代理店',
  'コールセンター',
  '販売・流通店',
  '一般公開',
  'システム運用',
]

export const MEMBER_SECTIONS: Section[] = [
  '社内',
  'サッシ代理店',
  'コールセンター',
  '販売・流通店',
  'システム運用',
]

export const LIXIL_SECTIONS: Section[] = ['社内', 'コールセンター']

export const ALL_SECTION_OPTIONS: {
  name: Section
  value: Section
}[] = SECTIONS.map((section: Section) => ({
  name: section,
  value: section,
}))

export const MEMBER_SECTION_OPTIONS: {
  name: Section
  value: Section
}[] = MEMBER_SECTIONS.map((section: Section) => ({
  name: section,
  value: section,
}))

export const LIXIL_SECTION_OPTIONS: {
  name: Section
  value: Section
}[] = LIXIL_SECTIONS.map((section: Section) => ({
  name: section,
  value: section,
}))
