import { Evaluation } from '@/types/evaluation'

export const EVALUATIONS: Evaluation[] = [
  'とても悪かった',
  '悪かった',
  'よかった',
  'とてもよかった',
]

export const EVALUATION_OPTIONS: {
  name: Evaluation
  value: Evaluation
}[] = EVALUATIONS.map((evaluation: Evaluation) => ({
  name: evaluation,
  value: evaluation,
}))
