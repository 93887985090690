import { Support } from '@/types/support'

export const SUPPORTS: Support[] = ['対応', '非対応', '非表示']

export const SUPPORT_OPTIONS: {
  name: Support | '質問テンプレート'
  value: Support
}[] = SUPPORTS.map((support: Support) => ({
  name: support,
  value: support,
}))
