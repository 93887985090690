import { ApiConfig } from '@/types/apiConfig'

export const FaqApi: ApiConfig = {
  get: (id: string, keyword?: string) => {
    const query = keyword ? `?keyword=${keyword}` : ''
    return {
      method: 'GET',
      url: `/api/faq/${id}${query}`,
    }
  },
  searches: (params: {
    page: number
    keyword?: string
    subcategoryId?: number
    knowledgeCategoryId?: number
    perPage?: number
    isSaveKeyword?: boolean
  }) => ({
    method: 'POST',
    url: `/api/faq/searches`,
    data: params,
  }),
  favorites: (params: { page: number; perPage?: number }) => ({
    method: 'POST',
    url: `/api/faq/favorites/searches`,
    data: params,
  }),
  like: (
    faqId: string,
    params: {
      isLike: boolean
    }
  ) => ({
    method: 'POST',
    url: `/api/faq/${faqId}/likes`,
    data: params,
  }),
  print: (faqId: string) => ({
    method: 'POST',
    url: `/api/faq/${faqId}/print`,
  }),
  urlCopy: (faqId: string) => ({
    method: 'POST',
    url: `/api/faq/${faqId}/url-copy`,
  }),
  favorite: (
    faqId: string,
    params: {
      isFavorite: boolean
    }
  ) => ({
    method: 'POST',
    url: `/api/faq/${faqId}/favorites`,
    data: params,
  }),
  checkFaqExists: (id: string) => ({
    method: 'GET',
    url: `/api/faq/${id}/exists/checks`,
  }),
}
