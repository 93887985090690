import { ContactGroup } from '@/types/contactGroup'
import { User } from '@/types/user'

export const useGetContactGroups = (
  getContactGroups: () => Promise<{ contactGroups: ContactGroup[] }>
) => {
  const getAllContactGroups = async (
    user: User,
    showAllContactGroups = false
  ) => {
    if (!user) return []
    const { contactGroups } = await getContactGroups()

    if (user.role.name === '管理者' || showAllContactGroups) {
      return contactGroups
    }

    // 管理者でないなら所属窓口のみ表示する
    return contactGroups.filter((contactGroup: ContactGroup) =>
      user.contactGroup.some((c) => c.id === contactGroup.id)
    )
  }

  return {
    getAllContactGroups,
  }
}
