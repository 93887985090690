import { reactive, watch } from 'vue'
import { User } from '@/types/user'

interface State {
  forms: {
    globalId: string
    name: string
    branch: string
    nextCursor: string
  }
  users: (User & {
    isHidden: boolean
  })[]
  totalCount: number
  isRedoSearch: boolean
}

export const useSearchUsers = (): {
  state: State
  resetForm: () => void
} => {
  const state = reactive<State>({
    forms: {
      globalId: '',
      name: '',
      branch: '',
      nextCursor: '',
    },
    users: [],
    totalCount: 0,
    isRedoSearch: true,
  })

  watch(
    () => [state.forms.globalId, state.forms.branch, state.forms.name],
    () => {
      state.isRedoSearch = true
    }
  )

  const resetForm = () => {
    state.forms = {
      name: '',
      branch: '',
      globalId: '',
      nextCursor: '',
    }
  }

  return {
    state,
    resetForm,
  }
}
