import { ApiConfig } from '@/types/apiConfig'
import { Section } from '@/types/section'

export const CategoryApi: ApiConfig = {
  searches: (params: { knowledgeCategoryGroupId: number }) => ({
    method: 'POST',
    url: `/api/administrator/categories/searches`,
    data: params,
  }),
  create: (params: {
    categoryName: string
    isFaq: boolean
    isDivideSetting: boolean
    knowledgeCategoryIds: number[]
    isSearchSections: Section[]
    isQuestionSections: Section[]
  }) => ({
    method: 'POST',
    url: `/api/administrator/categories`,
    data: params,
  }),
  update: (
    id: number,
    params: {
      categoryName: string
      isFaq: boolean
      isDivideSetting: boolean
      knowledgeCategoryIds: number[]
    }
  ) => ({
    method: 'PUT',
    url: `/api/administrator/categories/${id}`,
    data: params,
  }),
  delete: (id: number) => ({
    method: 'DELETE',
    url: `/api/administrator/categories/${id}`,
  }),
  sort: (params: { categoryIds: number[] }) => ({
    method: 'PUT',
    url: `/api/administrator/categories/sort`,
    data: params,
  }),
}
