import { ApiConfig } from '@/types/apiConfig'

export const ElasticsearchApi: ApiConfig = {
  userdictImport: (file: File) => ({
    method: 'POST',
    url: `/api/administrator/es/userdict/imports`,
    data: file,
    headers: {
      'Content-Type': 'application/octet-stream',
    },
  }),
  synonymImport: (file: File) => ({
    method: 'POST',
    url: `/api/administrator/es/synonym/imports`,
    data: file,
    headers: {
      'Content-Type': 'application/octet-stream',
    },
  }),
  userdictDownload: () => ({
    method: 'GET',
    url: `/api/administrator/es/userdict/download`,
  }),
  synonymDownload: () => ({
    method: 'GET',
    url: `/api/administrator/es/synonym/download`,
  }),
}
