import { ApiConfig } from '@/types/apiConfig'

export const PresignedUrlApi: ApiConfig = {
  downloadUrl: (path: string, inline = false, faq_id: string) => ({
    method: 'POST',
    url: `/api/public/presigned-url/download`,
    data: {
      path,
      inline,
      faq_id,
    },
  }),
}
