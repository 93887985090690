import { ApiConfig } from '@/types/apiConfig'

export const ContactGroupApi: ApiConfig = {
  create: (params: {
    contactGroupName: string
    email: string
    endDate: string
    displayOrder?: string
  }) => ({
    method: 'POST',
    url: `/api/administrator/contact-groups`,
    data: params,
  }),
  update: (
    id: number,
    params: {
      contactGroupName: string
      email: string
      endDate: string
      displayOrder?: string
    }
  ) => ({
    method: 'PUT',
    url: `/api/administrator/contact-groups/${id}`,
    data: params,
  }),
  delete: (id: number) => ({
    method: 'DELETE',
    url: `/api/administrator/contact-groups/${id}`,
  }),
  sort: (
    id: number,
    params: {
      displayOrder: number
    }
  ) => ({
    method: 'PUT',
    url: `/api/administrator/contact-groups/${id}/sort`,
    data: params,
  }),
  createUser: (
    id: number,
    params: {
      userId: string
    }
  ) => ({
    method: 'POST',
    url: `/api/administrator/contact-groups/${id}/users`,
    data: params,
  }),
  deleteUser: (
    id: number,
    params: {
      userIds: string[]
    }
  ) => ({
    method: 'POST',
    url: `/api/administrator/contact-groups/${id}/users/delete`,
    data: params,
  }),
}
