import { Priority } from '@/types/priority'

export const PRIORITY: Priority[] = ['低', '中', '高']

export const PRIORITY_OPTIONS: {
  name: Priority | string
  value: Priority | string
}[] = PRIORITY.map((priority: Priority | string) => ({
  name: priority,
  value: priority,
}))
