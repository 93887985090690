import { computed, onMounted, reactive, Ref, watch } from 'vue'
import { useInterval } from 'vue-composable'

interface State {
  seconds: number
  totalSeconds: number
}

export const useTimer = (
  createTemporarilyStored: (isCausedByTimer: boolean) => void
): {
  state: State
  timer: Ref<string>
  formatNumber: (num: number) => string
  start: () => void
  remove: () => void
} => {
  const state = reactive<State>({
    seconds: 0,
    totalSeconds: 0,
  })

  const { start, remove } = useInterval(() => {
    // サーバーに送信するのはページを開いてから、
    // もしくは前回の一時保存が送信されてからの時間
    // ページを開いていた総時間数ではない

    state.totalSeconds++
    if (state.seconds > 59) {
      state.seconds = 0
      createTemporarilyStored(true)
    } else {
      state.seconds += 1
    }
  }, 1000)

  watch(
    () => state.seconds,
    () => {
      if (state.totalSeconds === 359999) {
        remove()
      }
    }
  )

  const timer = computed<string>(() => {
    const totalSeconds = state.totalSeconds
    const hour = formatNumber(Math.floor(totalSeconds / 3600))
    const min = formatNumber(Math.floor((totalSeconds % 3600) / 60))
    const rem = formatNumber(totalSeconds % 60)
    // 00:00:00
    return `${hour}:${min}:${rem}`
  })

  onMounted(() => {
    start()
  })

  const formatNumber = (num: number): string => ('00' + num).slice(-2)

  return {
    state,
    timer,
    formatNumber,
    start,
    remove,
  }
}
