import { reactive } from 'vue'
import Validator from '@/utils/validator'

interface State {
  forms: {
    evaluation: string
    comment: string
  }
  errors: {
    evaluation: string | boolean
  }
}

export const useQuestionEvaluation = (): {
  state: State
  validateForms: () => boolean
} => {
  const state = reactive<State>({
    forms: {
      evaluation: '',
      comment: '',
    },
    errors: {
      evaluation: '',
    },
  })

  const rules = {
    evaluation: 'required',
  }

  const messages = {
    'required.evaluation': '評価を選択してください',
  }

  const validateForms = (): boolean => {
    const validation = new Validator(state.forms, rules, messages)
    if (validation.passes()) {
      return true
    } else {
      state.errors = {
        evaluation: validation.errors.first('evaluation'),
      }
      return false
    }
  }

  return {
    state,
    validateForms,
  }
}
