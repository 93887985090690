import { TrackEventOptions } from '@gtm-support/vue-gtm'
import { useRoute } from 'vue-router'
import { useScreenSize } from '@/use/useScreenSize'

export const useTrackEvent = (
  eventCategory: string,
  eventAction: string,
  eventLabel?: string
) => {
  const route = useRoute()
  const { SP } = useScreenSize()
  // const gtm = useGtm()

  const getEventLabel = (eventLabel: string): string => {
    if (route.path.startsWith('/questioner')) {
      return SP.value ? `sp_${eventLabel}` : eventLabel
    }
    if (route.path.startsWith('/public')) {
      return SP.value ? `sp_${eventLabel}` : eventLabel
    }
    return eventLabel
  }

  const trackEventOptions: TrackEventOptions = {
    event: 'Click',
  }

  if (eventCategory) {
    trackEventOptions.eventCategory = eventCategory
  }

  if (eventAction) {
    trackEventOptions.eventAction = eventAction
  }

  if (eventLabel) {
    trackEventOptions.eventLabel = getEventLabel(eventLabel)
  }

  const trackEvent = () => {
    if (eventCategory || eventAction || eventLabel) {
      // gtm?.trackEvent(trackEventOptions)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dataLayer.push(trackEventOptions)
    }
  }

  const trackCustomEvent = (
    eventCategory: string,
    eventAction: string,
    eventLabel: string
  ) => {
    if (eventCategory || eventAction || eventLabel) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dataLayer.push({
        event: 'Click',
        eventCategory: eventCategory,
        eventAction: eventAction,
        eventLabel: getEventLabel(eventLabel),
      })
    }
  }

  return { trackEvent, trackCustomEvent }
}
