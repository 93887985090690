import { reactive } from 'vue'

interface State {
  isAccordionOpen: boolean
}

export const useAccordion = (
  isAccordionOpen: boolean
): {
  state: State
  toggleAccordion: () => void
  beforeEnter: (el: HTMLElement) => void
  enter: (el: HTMLElement) => void
  beforeLeave: (el: HTMLElement) => void
  leave: (el: HTMLElement) => void
} => {
  const state = reactive<State>({
    isAccordionOpen,
  })

  const toggleAccordion = () => {
    state.isAccordionOpen = !state.isAccordionOpen
  }

  const beforeEnter = (el: HTMLElement) => {
    el.style.height = '0'
  }

  const enter = (el: HTMLElement) => {
    el.style.height = el.scrollHeight + 'px'
  }

  const beforeLeave = (el: HTMLElement) => {
    el.style.height = el.scrollHeight + 'px'
  }

  const leave = (el: HTMLElement) => {
    el.style.height = '0'
  }

  return {
    state,
    toggleAccordion,
    beforeEnter,
    enter,
    beforeLeave,
    leave,
  }
}
