import { ApiConfig } from '@/types/apiConfig'

export const CommonTemplateApi: ApiConfig = {
  searches: (
    params: {
      commonTemplateGroupId: number
      keyword: string
    },
    cursor: string
  ) => ({
    method: 'POST',
    url: `/api/respondent/common-templates/searches?cursor=${cursor}`,
    data: params,
  }),
}
