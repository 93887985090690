import { Role } from '@/types/role'

export const ROLES: Role[] = ['管理者', '回答者', 'FAQ作成者', '未設定']

export const ROLE_OPTIONS: {
  name: Role
  value: Role
}[] = ROLES.map((role: Role) => ({
  name: role,
  value: role,
}))
