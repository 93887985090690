import { ApiConfig } from '@/types/apiConfig'

export const NewsApi: ApiConfig = {
  searches: (
    params: {
      name: string
      keyword: string
      startDateFrom: string
      startDateTo: string
      isRespondent: boolean
      isQuestioner: boolean
      perPage?: number
    },
    cursor = ''
  ) => ({
    method: 'POST',
    url: `/api/administrator/news/searches?cursor=${cursor}`,
    data: params,
  }),
  create: (params: {
    title: string
    contentWithTag: string
    startDate: string
    endDate: string
    isRespondent: boolean
    isQuestioner: boolean
    isImportant: boolean
  }) => ({
    method: 'POST',
    url: `/api/administrator/news`,
    data: params,
  }),
  update: (
    id: number,
    params: {
      title: string
      contentWithTag: string
      startDate: string
      endDate: string
      isRespondent: boolean
      isQuestioner: boolean
      isImportant: boolean
    }
  ) => ({
    method: 'PUT',
    url: `/api/administrator/news/${id}`,
    data: params,
  }),
  delete: (id: number) => ({
    method: 'DELETE',
    url: `/api/administrator/news/${id}`,
  }),
}
