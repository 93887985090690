import { reactive } from 'vue'
import { createContainer } from 'vue-unstated'
import { ContactGroup } from '@/types/contactGroup'
import { useMultipleCheck } from '@/use/useMultipleCheck'

interface State {
  usersOptions: {
    value: number
    name: string
  }[]
  selectedUserId: string
  isInitialLoad: boolean
}

export const useSearchContactGroupUsers = () => {
  const state = reactive<State>({
    usersOptions: [],
    selectedUserId: '',
    // watchで担当者リセットされてしまっているので、初回ロードで担当者が復元されるようにしたい
    isInitialLoad: true,
  })

  const multipleCheck = useMultipleCheck<ContactGroup>()

  return {
    state,
    multipleCheck,
  }
}

const searchContactGroupUsersContainer = createContainer(
  useSearchContactGroupUsers
)

export default searchContactGroupUsersContainer
