import { computed, ComputedRef, reactive } from 'vue'
import { createContainer } from 'vue-unstated'
import { Auth } from '@/types/auth'
import { ContactGroup } from '@/types/contactGroup'
import { getAuth, setAuth } from '@/utils/auth'

interface State {
  currentUser: Auth | null
}

export const useUser = (): {
  state: State
  viewedNotes: () => void
  resetViewedNotes: () => void
  isAdmin: ComputedRef<boolean>
  isRespondent: ComputedRef<boolean>
  isFaqCreator: ComputedRef<boolean>
  isLixilUser: ComputedRef<boolean>
  isUserWithinCompany: ComputedRef<boolean>
  contactGroupIds: ComputedRef<number[]>
  contactGroupsForFaq: ComputedRef<ContactGroup[]>
  noRole: ComputedRef<boolean>
} => {
  const state = reactive<State>({
    currentUser: getAuth(),
  })

  const viewedNotes = () => {
    if (!state.currentUser) return
    state.currentUser.user.isFirst = false
    setAuth(state.currentUser)
  }

  const resetViewedNotes = () => {
    if (!state.currentUser) return
    state.currentUser.user.isFirst = true
    setAuth(state.currentUser)
  }

  const isAdmin = computed<boolean>(() => {
    if (!state.currentUser) return false
    return state.currentUser.user.role.name === '管理者'
  })

  const isRespondent = computed<boolean>(() => {
    if (!state.currentUser) return false
    return state.currentUser.user.role.name === '回答者'
  })

  const isFaqCreator = computed<boolean>(() => {
    if (!state.currentUser) return false
    return state.currentUser?.user.role.name === 'FAQ作成者'
  })

  const noRole = computed<boolean>(() => {
    if (!state.currentUser) return false
    return state.currentUser?.user.role.name === null
  })

  const isLixilUser = computed<boolean>(() => {
    return state.currentUser?.user.section === '社内'
  })

  const isUserWithinCompany = computed<boolean>(() => {
    const section = state.currentUser?.user.section
    return section === '社内' || section === 'コールセンター'
  })

  const contactGroupsForFaq = computed<ContactGroup[]>(() => {
    const currentUser = state.currentUser
    if (!currentUser) return []

    return currentUser.user.contactGroup.filter(
      (contactGroup) => contactGroup.isFaq
    )
  })

  const contactGroupIds = computed<number[]>(() => {
    const currentUser = state.currentUser
    if (!currentUser) return []

    return currentUser.user.contactGroup.map(
      (contactGroup: ContactGroup) => contactGroup.id
    )
  })
  return {
    state,
    viewedNotes,
    resetViewedNotes,
    isAdmin,
    isRespondent,
    isFaqCreator,
    isLixilUser,
    isUserWithinCompany,
    contactGroupIds,
    noRole,
    contactGroupsForFaq,
  }
}

const userContainer = createContainer(useUser)

export default userContainer
