import { Section } from '@/types/section'

export class KnowledgeCategory {
  private readonly _id: number
  private readonly _layersPath: string
  private readonly _layers: string[]
  private readonly _knowledgeCategoryGroupId: number
  private readonly _isSearchSections: Section[]

  private readonly MAX_END_CATEGORY_NAME_LENGTH = 20
  private readonly MAX_END_CATEGORY_LAYERS_PATH_LENGTH_ON_PC = 40

  constructor(
    id: number,
    layersPath: string,
    layers: string[],
    knowledgeCategoryGroupId: number,
    sort: number,
    isSearchSections?: Section[]
  ) {
    this._id = id
    this._layersPath = layersPath
    this._layers = layers
    this._knowledgeCategoryGroupId = knowledgeCategoryGroupId
    this._sort = sort
    this._isSearchSections = isSearchSections || []
  }

  private _isExpanded = false

  get isExpanded() {
    return this._isExpanded
  }

  set isExpanded(isExpanded: boolean) {
    this._isExpanded = isExpanded
  }

  private _isChecked = false

  get isChecked() {
    return this._isChecked
  }

  set isChecked(isChecked: boolean) {
    this._isChecked = isChecked
  }

  private _sort: number

  get sort() {
    return this._sort
  }

  set sort(sort) {
    this._sort = sort
  }

  private _children: KnowledgeCategory[] = []

  get children() {
    return this._children
      .filter((c) => {
        return (
          c.layersDepth === this.layersDepth + 1 &&
          c._layers[this.layersDepth - 1] === this.name
        )
      })
      .map((c) => {
        c.children = this._children
        return c
      })
  }

  set children(children: KnowledgeCategory[]) {
    this._children = children
  }

  get id() {
    return this._id
  }

  get name() {
    return this._layers[this._layers.length - 1]
  }

  get parentName() {
    return this._layers[this.layersDepth - 2]
  }

  get layersPath() {
    return this._layersPath
  }

  get layers() {
    return this._layers
  }

  get layersDepth() {
    return this._layers.length
  }

  get hasChildren() {
    return this.children.length > 0
  }

  get knowledgeCategoryGroupId() {
    return this._knowledgeCategoryGroupId
  }

  get isSearchSections() {
    return this._isSearchSections
  }

  get isRootCategory() {
    return this.layersDepth === 1
  }

  // カテゴリ名の末尾を20文字に切り詰める
  get endCategoryName() {
    if (this._layersPath.length <= this.MAX_END_CATEGORY_NAME_LENGTH)
      return this._layersPath

    return (
      '...' +
      this._layersPath.substring(
        this._layersPath.length - this.MAX_END_CATEGORY_NAME_LENGTH,
        this._layersPath.length
      )
    )
  }

  // PCカテゴリーツリーで表示するカテゴリ名を取得する。SPなら末尾だけ表示
  public endCategoryLayersPath(isSP = false) {
    if (isSP) return this.name

    if (
      this._layersPath.length <= this.MAX_END_CATEGORY_LAYERS_PATH_LENGTH_ON_PC
    )
      return this._layersPath

    return (
      '...' +
      this._layersPath.substring(
        this._layersPath.length -
          this.MAX_END_CATEGORY_LAYERS_PATH_LENGTH_ON_PC,
        this._layersPath.length
      )
    )
  }

  toJSON = () => {
    return {
      id: this._id,
      layersPath: this._layersPath,
      layers: this._layers,
    }
  }
}
