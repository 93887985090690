import { reactive } from 'vue'
import { NotSupportTemplate } from '@/types/notSupportTemplate'
import Validator from '@/utils/validator'

interface State {
  type: 'create' | 'update' | ''
  searchForms: {
    name: string
    keyword: string
    nextCursor: string
  }
  notSupportTemplates: NotSupportTemplate[]
  totalCount: number
  selectedNotSupportTemplate: NotSupportTemplate | null
  editForms: {
    name: string
    contentWithTag: string
  }
  errors: {
    name: string | boolean
    contentWithTag: string | boolean
  }
}

export const useNotSupportTemplate = (): {
  state: State
  validateForms: () => boolean
  resetForm: () => void
  resetErrors: () => void
} => {
  const state = reactive<State>({
    type: '',
    searchForms: {
      name: '',
      keyword: '',
      nextCursor: '',
    },
    notSupportTemplates: [],
    totalCount: 0,
    selectedNotSupportTemplate: null,
    editForms: {
      name: '',
      contentWithTag: '',
    },
    errors: {
      name: '',
      contentWithTag: '',
    },
  })

  const rules = {
    name: 'required',
    contentWithTag: 'required|valid_html',
  }

  const messages = {
    'required.name': 'テンプレート名を入力してください',
    'required.contentWithTag': 'テンプレート文を入力してください',
    'valid_html.contentWithTag': 'テンプレート文に不正な内容が含まれています',
  }

  const validateForms = (): boolean => {
    resetErrors()
    const validation = new Validator(state.editForms, rules, messages)
    if (validation.passes()) {
      return true
    } else {
      state.errors = {
        name: validation.errors.first('name'),
        contentWithTag: validation.errors.first('contentWithTag'),
      }
      return false
    }
  }

  const resetForm = () => {
    state.editForms = {
      name: '',
      contentWithTag: '',
    }
  }

  const resetErrors = () => {
    state.errors = {
      name: '',
      contentWithTag: '',
    }
  }

  return {
    state,
    validateForms,
    resetForm,
    resetErrors,
  }
}
