/**
 * titleをセットする
 * @param title
 */
export const setTitle = (title: string) => {
  document.title = title
}

/**
 * メタタグのdescriptionをセットする
 * @param description
 */
export const setMetaDescription = (description: string) => {
  const meta = document.querySelector('meta[name="description"]')
  meta?.setAttribute('content', description)
}

/**
 * メタタグのrobotsをセットする
 * @param robots
 */
export const setMetaRobots = (robots: string) => {
  let meta = document.querySelector('meta[name="robots"]')
  if (meta) {
    meta.setAttribute('content', robots)
  } else {
    meta = document.createElement('meta')
    meta.setAttribute('name', 'robots')
    meta.setAttribute('content', robots)
    document.head.appendChild(meta)
  }
}

/**
 * メタタグのrobotsを削除する
 */
export const removeMetaRobots = () => {
  const meta = document.querySelector('meta[name="robots"]')
  meta?.remove()
}
