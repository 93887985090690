import { ApiConfig } from '@/types/apiConfig'

export const PresignedUrlApi: ApiConfig = {
  uploadUrl: (directory: string, fileNames: string[]) => ({
    method: 'POST',
    url: `/api/${directory}/presigned-url/upload`,
    data: {
      fileNames,
    },
  }),
  downloadUrl: (path: string, inline = false) => ({
    method: 'POST',
    url: `/api/presigned-url/download`,
    data: {
      path,
      inline,
    },
  }),
}
