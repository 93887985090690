import { reactive, watch } from 'vue'
import { Attachment } from '@/types/attachment'
import Validator, { includeNewLine } from '@/utils/validator'

interface State {
  knowledgeCategories: {
    name: string
    value: number
  }[]
  administratorsOptions: {
    name: string
    value: string
  }[]
  forms: {
    contact: string
    answer: string
    attachments: Attachment[]
    remarks: string
    subcategoryIds: number[]
    knowledgeCategoryIds: number[]
    contactGroupId: string
    startDate: string
    responsibleId: string
    keyword: string
    isNotDisplay: boolean
    priority: string
    referenceLevel: string
  }
  errors: {
    contact: string | boolean
    answer: string | boolean
    subcategoryIds: string | boolean
    knowledgeCategoryIds: string | boolean
    contactGroupId: string | boolean
    startDate: string | boolean
    responsibleId: string | boolean
    referenceLevel: string | boolean
    priority: string | boolean
  }
}

export const useFaqDetail = (): {
  state: State
  validateForms: () => boolean
  validateTemporarilyStoredForms: () => boolean
  resetErrors: () => void
} => {
  const state = reactive<State>({
    knowledgeCategories: [],
    administratorsOptions: [],
    forms: {
      contact: '',
      answer: '',
      attachments: [],
      remarks: '',
      subcategoryIds: [],
      knowledgeCategoryIds: [],
      contactGroupId: '',
      startDate: '',
      responsibleId: '',
      keyword: '',
      isNotDisplay: false,
      referenceLevel: '',
      priority: '',
    },
    errors: {
      contact: '',
      answer: '',
      subcategoryIds: '',
      knowledgeCategoryIds: '',
      contactGroupId: '',
      startDate: '',
      responsibleId: '',
      referenceLevel: '',
      priority: '',
    },
  })

  watch(
    () => state.forms.contact,
    (value: string) => {
      state.errors.contact = includeNewLine(value) ? '改行が含まれています' : ''
    }
  )

  const temporarilyStoredRules = {
    contact: 'required|not_include_new_line',
    answer: 'required|valid_html',
    contactGroupId: 'required',
    responsibleId: 'required',
    referenceLevel: 'required',
    priority: 'required',
  }

  const storedRules = {
    ...temporarilyStoredRules,
    subcategoryIds: 'required',
    knowledgeCategoryIds: 'required',
    startDate: 'required',
  }

  const temporarilyStoredMessages = {
    required: '質問を入力してください',
    'required.answer': '回答を入力してください',
    'valid_html.answer': '回答に不正な内容が含まれています',
    'required.contactGroupId': '窓口を入力してください',
    'required.responsibleId': '責任者を入力してください',
    'required.referenceLevel': '公開レベルを入力してください',
    'required.priority': '優先レベルを入力してください',
    'not_include_new_line.contact': '改行が含まれています',
  }

  const storedMessages = {
    ...temporarilyStoredMessages,
    'required.subcategoryIds': 'カテゴリを入力してください',
    'required.knowledgeCategoryIds': '知りたいことを入力してください',
    'required.startDate': '公開開始日を入力してください',
  }

  const validateForms = (): boolean => {
    resetErrors()
    const validation = new Validator(state.forms, storedRules, storedMessages)
    if (validation.passes()) {
      return true
    } else {
      state.errors = {
        contact: validation.errors.first('contact'),
        answer: validation.errors.first('answer'),
        subcategoryIds: validation.errors.first('subcategoryIds'),
        knowledgeCategoryIds: validation.errors.first('knowledgeCategoryIds'),
        contactGroupId: validation.errors.first('contactGroupId'),
        startDate: validation.errors.first('startDate'),
        responsibleId: validation.errors.first('responsibleId'),
        referenceLevel: validation.errors.first('referenceLevel'),
        priority: validation.errors.first('priority'),
      }
      return false
    }
  }

  const validateTemporarilyStoredForms = (): boolean => {
    resetErrors()
    const validation = new Validator(
      state.forms,
      temporarilyStoredRules,
      temporarilyStoredMessages
    )
    if (validation.passes()) {
      return true
    } else {
      state.errors = {
        contact: validation.errors.first('contact'),
        answer: validation.errors.first('answer'),
        subcategoryIds: validation.errors.first('subcategoryIds'),
        knowledgeCategoryIds: validation.errors.first('knowledgeCategoryIds'),
        contactGroupId: validation.errors.first('contactGroupId'),
        startDate: validation.errors.first('startDate'),
        responsibleId: validation.errors.first('responsibleId'),
        referenceLevel: validation.errors.first('referenceLevel'),
        priority: validation.errors.first('priority'),
      }
      return false
    }
  }

  const resetErrors = () => {
    state.errors = {
      contact: '',
      answer: '',
      subcategoryIds: '',
      knowledgeCategoryIds: '',
      contactGroupId: '',
      startDate: '',
      responsibleId: '',
      referenceLevel: '',
      priority: '',
    }
  }

  return {
    state,
    validateForms,
    validateTemporarilyStoredForms,
    resetErrors,
  }
}
