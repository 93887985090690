import { computed, reactive } from 'vue'
import { categoryMapper } from '@/domain/category/category.mapper'
import { Category } from '@/domain/category/category.model'
import { createExpandedIds } from '@/use/categoryTree/multipleCategoryTree/libs/createExpandedIds'
import { createSelectedIds } from '@/use/categoryTree/multipleCategoryTree/libs/createSelectedIds'

interface State {
  selectedCategories: Category[]
  expandedCategoryIds: number[]
  categories: Category[]
}

export const useMultipleCategories = (fns: {
  getSubcategories: (id: number) => Promise<Category[]>
  loading: (callback: () => Promise<void>) => void
}) => {
  const state = reactive<State>({
    selectedCategories: [],
    expandedCategoryIds: [],
    categories: [],
  })

  const selectedCategoryIds = computed(() =>
    state.selectedCategories.map((c) => c.subcategoryId)
  )

  const clickRootLayer = async (category: Category): Promise<Category[]> => {
    return await fns.getSubcategories(category.id).then((categories) =>
      categories.map((c) => {
        const category = categoryMapper(c)
        category.children = categories
        return category
      })
    )
  }

  const expandCategory = async (category: Category) => {
    state.expandedCategoryIds = createSelectedIds(
      state.expandedCategoryIds,
      category.subcategoryId
    )
    const index = state.categories.findIndex(
      (c) => c.subcategoryId === category.subcategoryId
    )
    if (category.layersDepth === 1) {
      fns.loading(async () => {
        state.categories[index].isExpanded = true
        state.categories[index].children = await clickRootLayer(category)
      })
    }
  }

  const selectCategory = (category: Category) => {
    state.selectedCategories = state.selectedCategories.find(
      (c) => c.id === category.id
    )
      ? state.selectedCategories.filter((c) => c.id !== category.id)
      : [...state.selectedCategories, category]
  }

  const removeCategory = (category: Category) => {
    state.selectedCategories = state.selectedCategories.filter(
      (c) => c.id !== category.id
    )
  }

  const setInitialCategoriesMultipleSelect = async (
    selectedCategories: { layers: string; subcategoryId: number }[]
  ) => {
    const subcategoryIds = selectedCategories.map((c) => c.subcategoryId)
    const rootCategoryNames = selectedCategories.map(
      (c) => c.layers.split('/')[0]
    )
    const rootCategoryIds = [
      ...new Set(
        state.categories
          .filter((c) => rootCategoryNames.includes(c.name))
          .map((c) => c.id)
      ),
    ]
    for (let i = 0; i < rootCategoryIds.length; i++) {
      const category = state.categories.find(
        (c) => c.id === rootCategoryIds[i]
      ) as Category
      await expandCategory(category)
    }
    const result = await Promise.all(
      rootCategoryIds.map(async (id: number) => fns.getSubcategories(id))
    )
    const subcategories = result.flat().map(categoryMapper)
    state.selectedCategories = subcategories.filter((c) =>
      subcategoryIds.includes(c.subcategoryId)
    )
    state.expandedCategoryIds = createExpandedIds(
      subcategories,
      state.selectedCategories as Category[],
      state.expandedCategoryIds
    )
  }

  return {
    state,
    expandCategory,
    selectCategory,
    selectedCategoryIds,
    removeCategory,
    setInitialCategoriesMultipleSelect,
  }
}
