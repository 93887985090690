import { reactive } from 'vue'
import { createContainer } from 'vue-unstated'
import { AnswerSection } from '@/types/answerSection'
import Validator from '@/utils/validator'

interface State {
  forms: {
    firstSectionId: string
    secondSectionId: string
  }
  errors: {
    firstSectionId: string | boolean
  }
  answerSection1: AnswerSection[]
  answerSection2: AnswerSection[]
}

export const useQuestionSection = (): {
  state: State
  validateForms: () => boolean
} => {
  const state = reactive<State>({
    forms: {
      firstSectionId: '',
      secondSectionId: '',
    },
    errors: {
      firstSectionId: '',
    },
    answerSection1: [],
    answerSection2: [],
  })

  const rules = {
    firstSectionId: 'required',
  }

  const messages = {
    'required.firstSectionId': '区分1を選択してください',
  }

  const validateForms = (): boolean => {
    const validation = new Validator(state.forms, rules, messages)
    if (validation.passes()) {
      return true
    } else {
      state.errors = {
        firstSectionId: validation.errors.first('firstSectionId'),
      }
      return false
    }
  }

  return {
    state,
    validateForms,
  }
}

const questionSectionContainer = createContainer(useQuestionSection)

export default questionSectionContainer
