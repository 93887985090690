export const partition = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends ReadonlyArray<any>,
  U extends T[number],
  V extends Exclude<T[number], U>
>(
  array: T,
  predicate: (value: T[number]) => boolean
): [U[], V[]] =>
  array.reduce(
    ([left, right], value) =>
      predicate(value) ? [[...left, value], right] : [left, [...right, value]],
    [[], []]
  )
