import { useAxios } from '@vue-composable/axios'
import { AxiosResponse } from 'axios'
import applyCaseMiddleware from 'axios-case-converter'
import { reactive, ref, Ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { createContainer } from 'vue-unstated'
import { AuthApi } from '@/api/auth'
import { UNAUTHORIZED_REDIRECT_PATH_KEY } from '@/config/sesstion'
import { isAuthenticated, isExpired, resetAuth, setAuth } from '@/utils/auth'
import { now } from '@/utils/date'

interface State {
  error: Error | null
  isRedirected: boolean
  isDisplay404ErrorAlert: boolean
  isNotDisplay422ErrorAlert: boolean
}

export const useHttp = (): {
  state: State
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exec: (args: any) => Promise<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  execWith404ErrorAlert: (args: any) => Promise<AxiosResponse | undefined>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  execWithout422ErrorAlert: (args: any) => Promise<AxiosResponse | undefined>
  error: Ref<Error>
  handleUnprocessedContent: (res: AxiosResponse) => boolean
} => {
  const AUTH_KEY = 'auth'

  const { client, exec, error } = useAxios()
  const route = useRoute()

  const state = reactive<State>({
    error: null,
    isRedirected: false,
    isDisplay404ErrorAlert: false,
    isNotDisplay422ErrorAlert: false,
  })

  const isSessionExpired = ref(false)
  const isNotAuthenticated = ref(false)

  watch(
    () => [isSessionExpired.value, isNotAuthenticated.value],
    async () => {
      if (isSessionExpired.value && isNotAuthenticated.value) return
      resetAuth()
      localStorage.setItem(UNAUTHORIZED_REDIRECT_PATH_KEY, route.fullPath)
      window.location.href = '/'
    }
  )

  applyCaseMiddleware(client)

  client.interceptors.request.use(async (config) => {
    config.baseURL = process.env.VUE_APP_API_URL
    config.headers = {
      'Content-type': 'application/json',
    }

    if (isAuthenticated()) {
      config.headers = await getAuthHeader(config.url === '/api/refresh')
    }

    return config
  })

  client.interceptors.response.use(
    (response: AxiosResponse) => {
      state.isRedirected = false
      return response
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (e: any) => {
      const url = e.response.config.url

      let role: string
      if (url.includes('api/public')) {
        role = 'public'
      } else if (
        url.includes('api/respondent') ||
        url.includes('api/administrator')
      ) {
        role = 'respondent'
      } else {
        role = 'questioner'
      }

      switch (e.response.status) {
        case 401: {
          isSessionExpired.value = true
          break
        }
        case 403: {
          isNotAuthenticated.value = true
          break
        }
        case 404: {
          if (state.isDisplay404ErrorAlert) {
            alert('該当のデータが見つかりませんでした。')
          } else {
            window.location.href = `/${role}/404`
          }
          break
        }
        case 422: {
          if (state.isNotDisplay422ErrorAlert) {
            return e.response
          }
          handleUnprocessedContent(e.response)
          break
        }
        default:
          if (window.location.pathname.includes('/callback')) {
            // 認証フローでのエラーは強制ログアウトのため戻します
            return e.response
          } else {
            window.location.href = `/${role}/500`
          }
          break
      }
    }
  )

  const handleUnprocessedContent = (res: AxiosResponse): boolean => {
    let hasError = false
    const message = res.data.message
    Object.keys(message).forEach((key, index) => {
      if (index === 0) {
        hasError = true
        alert(message[key])
      }
    })
    return hasError
  }

  const getAuthHeader = async (
    isRefreshRequest = false
  ): Promise<{ Authorization: string }> => {
    const { exec } = useHttp()
    let auth = JSON.parse(localStorage.getItem(AUTH_KEY) || '')
    if (
      !isSessionExpired.value &&
      !isRefreshRequest &&
      auth &&
      auth.expiresIn &&
      isExpired(now(), auth.expiresIn)
    ) {
      const res = await exec(AuthApi.refresh())
      if (res?.status === 200) {
        setAuth(res.data)
        auth = res.data
      } else {
        resetAuth()
        localStorage.setItem(UNAUTHORIZED_REDIRECT_PATH_KEY, route.fullPath)
        window.location.href = '/'
      }
    }
    // セッション切れの動作確認時には以下のコメントアウトした行を使ってください
    // 質問者のお問い合わせ画面は複数APIを同時に呼び出すので動作確認におすすめです
    // return { Authorization: 'Bearer ' + /*auth.accessToken ||*/ '' }
    return { Authorization: 'Bearer ' + auth.accessToken || '' }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const execWith404ErrorAlert = async (args: any) => {
    state.isDisplay404ErrorAlert = true
    const res: AxiosResponse | undefined = await exec(args)
    state.isDisplay404ErrorAlert = false
    return res
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const execWithout422ErrorAlert = async (args: any) => {
    state.isNotDisplay422ErrorAlert = true
    const res: AxiosResponse | undefined = await exec(args)
    state.isNotDisplay422ErrorAlert = false
    return res
  }

  return {
    state,
    exec,
    execWith404ErrorAlert,
    execWithout422ErrorAlert,
    error,
    handleUnprocessedContent,
  }
}

const httpContainer = createContainer(useHttp)

export default httpContainer
