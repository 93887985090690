import { ApiConfig } from '@/types/apiConfig'

export const SubcategoryApi: ApiConfig = {
  get: (id: number) => ({
    method: 'GET',
    url: `/api/administrator/subcategories/${id}`,
  }),
  searches: (params: {
    categoryId: number
    knowledgeCategoryGroupId: number
    isDivideSetting: boolean
  }) => ({
    method: 'POST',
    url: `/api/administrator/subcategories/searches`,
    data: params,
  }),
  create: (params: {
    layersPath: string
    subcategoryName: string
    isFaq: boolean
    isDivideSetting: boolean
  }) => ({
    method: 'POST',
    url: `/api/administrator/subcategories`,
    data: params,
  }),
  update: (
    id: number,
    params: {
      subcategoryName: string
      isFaq: boolean
      isDivideSetting: boolean
    }
  ) => ({
    method: 'PUT',
    url: `/api/administrator/subcategories/${id}`,
    data: params,
  }),
  delete: (id: number) => ({
    method: 'DELETE',
    url: `/api/administrator/subcategories/${id}`,
  }),
  sort: (params: { subcategoryIds: number[] }) => ({
    method: 'PUT',
    url: `/api/administrator/subcategories/sort`,
    data: params,
  }),
  import: (file) => ({
    method: 'POST',
    url: `/api/administrator/subcategories/imports`,
    data: file,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }),
  export: () => ({
    method: 'POST',
    url: `/api/administrator/subcategories/exports`,
  }),
}
