import { ApiConfig } from '@/types/apiConfig'

export const CategoryApi: ApiConfig = {
  getAllCategories: () => ({
    method: 'GET',
    url: `/api/categories`,
  }),
  getAllQuestionerCategories: () => ({
    method: 'GET',
    url: `/api/categories/questioner/search`,
  }),
  getAllQuestionerQuestionCategories: () => ({
    method: 'GET',
    url: `/api/categories/questioner/question`,
  }),
  getFaqAllCategories: () => ({
    method: 'GET',
    url: `/api/categories/faq`,
  }),
  getAllKnowledgeCategories: () => ({
    method: 'GET',
    url: `/api/knowledge-categories`,
  }),
  getSubcategory: (id: number) => ({
    method: 'GET',
    url: `/api/subcategories/${id}`,
  }),
  getKnowledgeCategory: (id: number) => ({
    method: 'GET',
    url: `/api/knowledge-categories/${id}`,
  }),
  searchKnowledgeCategories: (params: { keyword: string }) => ({
    method: 'POST',
    url: `/api/knowledge-categories/searches`,
    data: params,
  }),
  getAllKnowledgeCategoryGroups: (query: {
    code?: string
    userId?: string
  }) => ({
    method: 'GET',
    url: `/api/knowledge-categories/groups`,
    params: query,
  }),
  searchKnowledgeCategoriesByKeyword: (params: {
    categoryId: number
    keyword: string
  }) => ({
    method: 'POST',
    url: `/api/knowledge-categories/keyword-searches`,
    data: params,
  }),
  searchSubcategories: (params: {
    categoryId?: number
    knowledgeCategoryId?: number
    keyword?: string
  }) => ({
    method: 'POST',
    url: `/api/subcategories/searches`,
    data: params,
  }),
  searchSubcategoriesByKeyword: (params: {
    categoryId: number
    keyword: string
    knowledgeCategoryId: number
    regionId: number
  }) => ({
    method: 'POST',
    url: `/api/subcategories/keyword-searches`,
    data: params,
  }),
  searchFaqSubcategories: (params: {
    categoryId?: number
    keyword?: string
  }) => ({
    method: 'POST',
    url: `/api/subcategories/faq/searches`,
    data: params,
  }),
  searchFaqSubcategoriesByKeyword: (params: {
    categoryId: number
    keyword: string
    knowledgeCategoryId: number
    regionId: number
  }) => ({
    method: 'POST',
    url: `/api/subcategories/faq/keyword-searches`,
    data: params,
  }),
}
