import { ApiConfig } from '@/types/apiConfig'

export const QuestionTemplateApi: ApiConfig = {
  searches: (
    params: {
      name: string
      keyword: string
      perPage?: number
    },
    cursor = ''
  ) => ({
    method: 'POST',
    url: `/api/administrator/question-templates/searches?cursor=${cursor}`,
    data: params,
  }),
  create: (params: { name: string; contentWithTag: string }) => ({
    method: 'POST',
    url: `/api/administrator/question-templates`,
    data: params,
  }),
  update: (id: number, params: { name: string; contentWithTag: string }) => ({
    method: 'PUT',
    url: `/api/administrator/question-templates/${id}`,
    data: params,
  }),
  delete: (id: number) => ({
    method: 'DELETE',
    url: `/api/administrator/question-templates/${id}`,
  }),
}
