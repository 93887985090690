import { reactive } from 'vue'
import { createContainer } from 'vue-unstated'

interface State {
  isLoading: boolean
}

export const useLoadingSpinner = (): {
  state: State
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loading: (callback: any) => void
} => {
  const state = reactive<State>({
    isLoading: false,
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const loading = async (callback: any) => {
    state.isLoading = true
    try {
      await callback()
    } catch (e) {
      state.isLoading = false
      throw e
    }
    state.isLoading = false
  }

  return {
    state,
    loading,
  }
}

const loadingSpinnerContainer = createContainer(useLoadingSpinner)

export default loadingSpinnerContainer
