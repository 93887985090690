import { useFavicon } from '@vueuse/core'
import { useTitle } from 'vue-composable'
import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouterOptions,
} from 'vue-router'
import Callback from '@/pages/callback.vue'
import Home from '@/pages/home.vue'
import PublicRoutes from '@/router/public'
import QuestionerRoutes from '@/router/questioner'
import RespondentRoutes from '@/router/respondent'
import { setMetaRobots, removeMetaRobots } from '@/utils/metaTags'

const title = useTitle()
const icon = useFavicon()

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/callback',
    component: Callback,
  },
  PublicRoutes,
  QuestionerRoutes,
  RespondentRoutes,
] as Array<RouteRecordRaw>

const scrollBehavior = (): { top: number; left: number } => {
  return { top: 0, left: 0 }
}

const router = createRouter(<RouterOptions>{
  history: createWebHistory(),
  routes,
  scrollBehavior,
})

router.beforeEach((to, from, next) => {
  // favicon設定
  const favicon = to.path.includes('/respondent')
    ? '/favicon-respondent.ico'
    : '/favicon-questioner.ico'

  icon.value = process.env.VUE_APP_BASE_URL + favicon

  if (to.path.startsWith('/public') && to.matched.length === 0)
    next('/public/404')

  if (to.path.startsWith('/respondent') && to.matched.length === 0)
    next('/respondent/404')

  if (!to.path.startsWith('/respondent') && to.matched.length === 0)
    next('/questioner/404')

  // title設定
  title.value = to.meta.title
    ? `${to.meta.title} | 商品QAシステム`
    : '商品QAシステム'

  // meta robotsにnoindexを設定
  if (to.meta.noindex) {
    setMetaRobots('noindex')
  } else {
    removeMetaRobots()
  }

  next()
})

export default router
