import { Ref, ref } from 'vue'
import { createContainer } from 'vue-unstated'

export const useInfiniteLoading = (): {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  infiniteLoading: Ref<any>
  isOverloaded: Ref<boolean>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  finishLoadingByClickSearch: (list: any[], nextCursor: string) => void
  finishLoadingByLoadMore: (nextCursor: string) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  finishESLoading: (list: any[], allList: any[]) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isOverload: (list: any[]) => void
} => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const infiniteLoading = ref<any>(null)
  const isOverloaded = ref<boolean>(false)

  /**
   * クリックイベント、もしくは初期表示でES以外のLaravelカーソルローディングを終わらせる
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const finishLoadingByClickSearch = (list: any[], nextCursor: string) => {
    if (!infiniteLoading.value) return

    if (nextCursor) {
      // 次のページあり
      infiniteLoading.value.stateChanger.loaded()
    } else if (list.length > 0) {
      // 次のページなし、検索結果あり
      infiniteLoading.value.stateChanger.loaded()
      infiniteLoading.value.stateChanger.complete()
    } else {
      // 次のページなし、検索結果なし
      infiniteLoading.value.stateChanger.complete()
    }
  }

  /**
   * スクロールによるES以外のLaravelカーソルローディングを終わらせる
   */
  const finishLoadingByLoadMore = (nextCursor: string) => {
    if (!infiniteLoading.value) return

    if (nextCursor) {
      // 次のページあり
      infiniteLoading.value.stateChanger.loaded()
    } else {
      // 次のページなし
      infiniteLoading.value.stateChanger.complete()
    }
  }

  /**
   * ESのカーソルローディングを終わらせる
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const finishESLoading = (list: any[], allList: any[]) => {
    isOverload(allList)

    // isOverloaded.value = true
    if (list.length > 0) {
      // データあり
      infiniteLoading.value.stateChanger.loaded()
    } else {
      // データなし
      infiniteLoading.value.stateChanger.complete()
    }
  }

  /**
   * 1000件以上ページングでデータがたまったら、それ以上は読み込まない
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isOverload = (list: any[]) => {
    if (list.length > 1000) {
      isOverloaded.value = true
      infiniteLoading.value.stateChanger.loaded()
      infiniteLoading.value.stateChanger.complete()
    }
  }

  return {
    infiniteLoading,
    isOverloaded,
    finishLoadingByClickSearch,
    finishLoadingByLoadMore,
    finishESLoading,
    isOverload,
  }
}

const infiniteLoadingContainer = createContainer(useInfiniteLoading)

export default infiniteLoadingContainer
