import { computed, reactive } from 'vue'
import { KnowledgeCategoryGroup } from '@/types/knowledgeCategoryGroup'

interface State {
  knowledgeCategoryGroups: KnowledgeCategoryGroup[]
  editForms: {
    knowledgeCategoryGroupName: string
  }
}

export const useKnowledgeCategoryGroup = () => {
  const state = reactive<State>({
    knowledgeCategoryGroups: [],
    editForms: {
      knowledgeCategoryGroupName: '',
    },
  })

  const knowledgeCategoryGroupOptions = computed(() =>
    state.knowledgeCategoryGroups.map((c) => ({
      name: c.name,
      value: c.id,
    }))
  )

  return {
    state,
    knowledgeCategoryGroupOptions,
  }
}
