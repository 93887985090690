import { reactive } from 'vue'
import { KnowledgeCategory, Subcategory } from '@/types/category'
import Validator from '@/utils/validator'

export type FormatResponder = {
  userId: number
  name: string
  contactGroupId: number
  contactGroupName: string
}

interface State {
  forms: {
    subcategory: Subcategory | null
    knowledgeCategory: KnowledgeCategory | null
  }
  errors: {
    subcategoryId: string | boolean
    knowledgeCategoryId: string | boolean
  }
  changedKnowledgeCategory: KnowledgeCategory | null
  changedSubcategory: Subcategory | null
}

export const useChangeCategories = (): {
  state: State
  createParams: (regionId: number) => {
    subcategoryId?: number
    knowledgeCategoryId?: number
    regionId?: number
  }
  validateForms: (params: {
    subcategoryId: string
    knowledgeCategoryId: string
    regionId?: number
  }) => boolean
} => {
  const state = reactive<State>({
    forms: {
      subcategory: null,
      knowledgeCategory: null,
    },
    errors: {
      subcategoryId: '',
      knowledgeCategoryId: '',
    },
    changedKnowledgeCategory: null,
    changedSubcategory: null,
  })

  const rules = {
    subcategoryId: 'required',
    knowledgeCategoryId: 'required',
  }

  const messages = {
    'required.subcategoryId': '商品カテゴリーを必ず選択してください',
    'required.knowledgeCategoryId': '知りたいことを必ず選択してください',
  }

  const validateForms = (params: {
    subcategoryId: string
    knowledgeCategoryId: string
    regionId?: number
  }): boolean => {
    resetErrors()
    const validation = new Validator(params, rules, messages)
    if (validation.passes()) {
      return true
    } else {
      state.errors = {
        subcategoryId: validation.errors.first('subcategoryId'),
        knowledgeCategoryId: validation.errors.first('knowledgeCategoryId'),
      }
      return false
    }
  }

  const resetErrors = () => {
    state.errors = {
      subcategoryId: '',
      knowledgeCategoryId: '',
    }
  }

  const createParams = (
    regionId: number
  ): {
    subcategoryId?: number
    knowledgeCategoryId?: number
    regionId?: number
  } => {
    const params: {
      subcategoryId?: number
      knowledgeCategoryId?: number
      regionId?: number
    } = {
      subcategoryId: state.forms.subcategory?.subcategoryId,
      knowledgeCategoryId: state.forms.knowledgeCategory?.id,
    }

    if (regionId) {
      params.regionId = regionId
    }

    return params
  }

  return {
    state,
    validateForms,
    createParams,
  }
}
