import { reactive } from 'vue'
import { CommonTemplateGroup } from '@/types/commonTemplateGroup'

interface State {
  commonTemplateGroups: CommonTemplateGroup[]
  editForms: {
    name: string
  }
}

export const useCommonTemplateGroup = (): {
  state: State
} => {
  const state = reactive<State>({
    commonTemplateGroups: [],
    editForms: {
      name: '',
    },
  })

  return {
    state,
  }
}
