import { ApiConfig } from '@/types/apiConfig'

export const AuthApi: ApiConfig = {
  login: (query: { code?: string; userId?: string }) => ({
    method: 'GET',
    url: `/api/login`,
    params: query,
  }),
  refresh: () => ({
    method: 'GET',
    url: `/api/refresh`,
  }),
  logout: () => ({
    method: 'GET',
    url: `/api/logout`,
  }),
  url: () => ({
    method: 'GET',
    url: `/api/auth/url`,
  }),
}
