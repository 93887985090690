import { ApiConfig } from '@/types/apiConfig'

export const QueueApi: ApiConfig = {
  getQueues: () => ({
    method: 'GET',
    url: `/api/respondent/queues`,
  }),
  csvDownload: (id: string) => ({
    method: 'GET',
    url: `/api/respondent/queues/${id}/download`,
  }),
}
