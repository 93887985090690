import { KnowledgeCategory } from '@/domain/knowledgeCategory/knowledgeCategory.model'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const knowledgeCategoryMapper = (c: any) =>
  new KnowledgeCategory(
    c.id,
    c.layersPath,
    c.layers,
    c.knowledgeCategoryGroupId,
    c.sort,
    c.isSearchSections
  )
