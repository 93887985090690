import { computed, reactive } from 'vue'
import {
  ContactGroupRealtime,
  ContactGroupUsersRealtime,
  TimeList,
} from '@/types/TimeList'
import Validator from '@/utils/validator'

interface State {
  contactGroupRealtime: ContactGroupRealtime
  contactGroupUsersRealtime: ContactGroupUsersRealtime | null
  forms: {
    date: string
  }
  errors: {
    date: string | boolean
  }
}

export const useRealtimeReport = () => {
  const state = reactive<State>({
    contactGroupRealtime: [],
    contactGroupUsersRealtime: null,
    forms: {
      date: '',
    },
    errors: {
      date: '',
    },
  })

  const rules = {
    date: 'required',
  }

  const messages = {
    'required.date': '日付を入力してください',
  }

  const validateForms = (): boolean => {
    resetFormError()
    const validation = new Validator(state.forms, rules, messages)
    if (validation.passes()) {
      return true
    } else {
      state.errors = {
        date: validation.errors.first('date'),
      }
      return false
    }
  }

  const resetFormError = () => {
    state.errors = {
      date: '',
    }
  }

  const summarizedContactGroupsReport = computed(() => {
    const array12Number = Array(12).fill(0)
    return state.contactGroupRealtime.reduce(
      (previousValue, currentValue) => {
        return {
          report: {
            receiveCounts: previousValue.report.receiveCounts.map(
              (count, index) => count + currentValue.report.receiveCounts[index]
            ),
            accumulatedAnswerCounts:
              previousValue.report.accumulatedAnswerCounts.map(
                (count, index) =>
                  count + currentValue.report.accumulatedAnswerCounts[index]
              ),
            answerCounts: previousValue.report.answerCounts.map(
              (count, index) => count + currentValue.report.answerCounts[index]
            ),
            inProcessingCounts: previousValue.report.inProcessingCounts.map(
              (count, index) =>
                count + currentValue.report.inProcessingCounts[index]
            ),
            unreadCounts: previousValue.report.unreadCounts.map(
              (count, index) => count + currentValue.report.unreadCounts[index]
            ),
            answerCountTotal:
              previousValue.report.answerCountTotal +
              currentValue.report.answerCountTotal,
            receiveCountTotal:
              previousValue.report.receiveCountTotal +
              currentValue.report.receiveCountTotal,
          },
          lastWeekSummary: {
            receiveCountTotal:
              previousValue.lastWeekSummary.receiveCountTotal +
              currentValue.lastWeekSummary.receiveCountTotal,
            accumulatedAnswerCount:
              previousValue.lastWeekSummary.accumulatedAnswerCount +
              currentValue.lastWeekSummary.accumulatedAnswerCount,
            answerCountTotal:
              previousValue.lastWeekSummary.answerCountTotal +
              currentValue.lastWeekSummary.answerCountTotal,
            inProcessingCount:
              previousValue.lastWeekSummary.inProcessingCount +
              currentValue.lastWeekSummary.inProcessingCount,
            unreadCount:
              previousValue.lastWeekSummary.unreadCount +
              currentValue.lastWeekSummary.unreadCount,
          },
        }
      },
      {
        report: {
          receiveCounts: array12Number,
          accumulatedAnswerCounts: array12Number,
          answerCounts: array12Number,
          inProcessingCounts: array12Number,
          unreadCounts: array12Number,
          answerCountTotal: 0,
          receiveCountTotal: 0,
        },
        lastWeekSummary: {
          receiveCountTotal: 0,
          accumulatedAnswerCount: 0,
          answerCountTotal: 0,
          inProcessingCount: 0,
          unreadCount: 0,
        },
      }
    )
  })

  const contactGroupTimeList = computed<TimeList[]>(() => {
    return state.contactGroupRealtime ? createContactGroupTimeList() : []
  })

  const contactGroupUsersAverageList = computed(() => {
    return state.contactGroupUsersRealtime
      ? createContactGroupUsersAverageList(state.contactGroupUsersRealtime)
      : []
  })

  const contactGroupUsersTimeList = computed(() => {
    return state.contactGroupUsersRealtime
      ? createContactGroupUsersTimeList(state.contactGroupUsersRealtime)
      : []
  })

  const createContactGroupTimeList = (): TimeList[] =>
    [
      {
        time: '8時より前',
        receiveCounts:
          summarizedContactGroupsReport.value.report.receiveCounts[0],
        answerCounts:
          summarizedContactGroupsReport.value.report.answerCounts[0],
        accumulatedAnswerCounts:
          summarizedContactGroupsReport.value.report.accumulatedAnswerCounts[0],
        inProcessingCounts:
          summarizedContactGroupsReport.value.report.inProcessingCounts[0],
        unreadCounts:
          summarizedContactGroupsReport.value.report.unreadCounts[0],
      },
    ]
      .concat(
        [...Array(10)].map((_, index) => {
          const assignedIndex = index + 1
          return {
            time: `${index + 8}時台`,
            receiveCounts:
              summarizedContactGroupsReport.value.report.receiveCounts[
                assignedIndex
              ],
            answerCounts:
              summarizedContactGroupsReport.value.report.answerCounts[
                assignedIndex
              ],
            accumulatedAnswerCounts:
              summarizedContactGroupsReport.value.report
                .accumulatedAnswerCounts[assignedIndex],
            inProcessingCounts:
              summarizedContactGroupsReport.value.report.inProcessingCounts[
                assignedIndex
              ],
            unreadCounts:
              summarizedContactGroupsReport.value.report.unreadCounts[
                assignedIndex
              ],
          }
        })
      )
      .concat([
        {
          time: '18時以降',
          receiveCounts:
            summarizedContactGroupsReport.value.report.receiveCounts[11],
          answerCounts:
            summarizedContactGroupsReport.value.report.answerCounts[11],
          accumulatedAnswerCounts:
            summarizedContactGroupsReport.value.report
              .accumulatedAnswerCounts[11],
          inProcessingCounts:
            summarizedContactGroupsReport.value.report.inProcessingCounts[11],
          unreadCounts:
            summarizedContactGroupsReport.value.report.unreadCounts[11],
        },
        {
          time: '合計',
          receiveCounts:
            summarizedContactGroupsReport.value.report.receiveCountTotal,
          answerCounts:
            summarizedContactGroupsReport.value.report.answerCountTotal,
          accumulatedAnswerCounts: null,
          inProcessingCounts: null,
          unreadCounts: null,
        },
      ])

  const createContactGroupUsersAverageList = (
    realtime: ContactGroupUsersRealtime
  ) =>
    [
      {
        name: '窓口合計',
        ...realtime.contactGroupSummary,
      },
    ].concat(realtime.reports as any)

  const createContactGroupUsersTimeList = (
    realtime: ContactGroupUsersRealtime
  ) =>
    [
      {
        time: '8時より前',
        answerResults: realtime.reports.map((report) => ({
          answerCount: report.answerCounts[0],
          accumulatedAnswerCount: report.accumulatedAnswerCounts[0],
        })),
      },
    ].concat(
      [...Array(10)].map((_, index) => {
        const assignedIndex = index + 1
        return {
          time: `${index + 8}時台`,
          answerResults: realtime.reports.map((report) => ({
            answerCount: report.answerCounts[assignedIndex],
            accumulatedAnswerCount:
              report.accumulatedAnswerCounts[assignedIndex],
          })),
        }
      }),
      [
        {
          time: '18時以降',
          answerResults: realtime.reports.map((report) => ({
            answerCount: report.answerCounts[11],
            accumulatedAnswerCount: report.accumulatedAnswerCounts[11],
          })),
        },
      ]
    )

  return {
    state,
    validateForms,
    resetFormError,
    contactGroupUsersAverageList,
    contactGroupTimeList,
    contactGroupUsersTimeList,
    summarizedContactGroupsReport,
  }
}
