import { reactive } from 'vue'
import { categoryMapper } from '@/domain/category/category.mapper'
import { Category } from '@/domain/category/category.model'
import { createSelectedIds } from '@/use/categoryTree/multipleCategoryTree/libs/createSelectedIds'
import {
  moveDown,
  moveUp,
} from '@/use/categoryTree/multipleCategoryTree/libs/sort'

interface State {
  expandedIds: number[]
  categories: Category[]
  selectedCategory: Category | null
}

export const useMultipleCategoryTree = (fns: {
  getSubcategories: (id: number) => Promise<Category[]>
  sortCategories: (params: {
    categoryIds?: number[]
    subcategoryIds?: number[]
  }) => Promise<void>
  sortSubcategories: (params: { subcategoryIds: number[] }) => Promise<void>
  getAllCategories: () => Promise<void>
  loading: (callback: () => Promise<void>) => void
}) => {
  const state = reactive<State>({
    expandedIds: [],
    categories: [],
    selectedCategory: null,
  })

  const expandCategory = async (category: Category, index: number) => {
    fns.loading(async () => {
      state.expandedIds = createSelectedIds(
        state.expandedIds,
        category.subcategoryId
      )
      if (category.layersDepth === 1) {
        state.categories[index].isExpanded = true
        state.categories[index].children = await clickRootLayer(category)
      }
    })
  }

  const clickRootLayer = async (category: Category): Promise<Category[]> => {
    return await fns.getSubcategories(category.id).then((categories) =>
      categories.map((c) => {
        const category = categoryMapper(c)
        category.children = categories
        return category
      })
    )
  }

  const selectCategory = (category: Category) => {
    state.selectedCategory = category
  }

  const updateCategoryTree = async (category: Category, isCreate = true) => {
    const rootIndex = state.categories.findIndex(
      (c) => c.id === category.rootCategory.id
    )

    const isExpanded = isCreate ? true : state.categories[rootIndex].isExpanded

    if (category.isRootCategory) {
      state.categories[rootIndex] = category
    }
    if (isCreate) {
      state.categories[rootIndex].hasSubcategory = true
    }
    state.categories[rootIndex].isExpanded = isExpanded

    state.categories[rootIndex].children = await clickRootLayer(
      category.rootCategory
    ).then((categories) => {
      return categories.map((c) => {
        c.isExpanded = state.expandedIds.includes(c.subcategoryId)
        c.children = categories
        return c
      })
    })
  }

  const sort = async (
    category: Category,
    categories: Category[],
    isMoveUp = false
  ) => {
    const index = categories.findIndex(
      (c) => c.subcategoryId === category.subcategoryId
    )
    if (category.isRootCategory) {
      const params: {
        categoryIds: number[]
      } = {
        categoryIds: [],
      }

      if (isMoveUp) {
        params.categoryIds = moveUp(index, categories)
      } else {
        params.categoryIds = moveDown(index, categories)
      }

      await fns.sortCategories(params)
      await fns.getAllCategories()
      const rootIndex = state.categories.findIndex(
        (c) => c.id === category.rootId
      )
      if (state.categories[rootIndex].isExpanded) {
        expandCategory(category.rootCategory, rootIndex)
      }
    } else {
      const params: {
        subcategoryIds: number[]
      } = {
        subcategoryIds: [],
      }

      if (isMoveUp) {
        params.subcategoryIds = moveUp(index, categories)
      } else {
        params.subcategoryIds = moveDown(index, categories)
      }

      await fns.sortSubcategories(params)

      const rootIndex = state.categories.findIndex(
        (c) => c.id === category.rootId
      )
      state.categories[rootIndex].isExpanded = true
      state.categories[rootIndex].children = await clickRootLayer(
        state.categories[rootIndex] as Category
      )
    }
  }

  return {
    state,
    expandCategory,
    selectCategory,
    updateCategoryTree,
    clickRootLayer,
    sort,
  }
}
