import { reactive } from 'vue'
import { UserTemplate } from '@/types/userTemplate'
import Validator from '@/utils/validator'

interface State {
  type: 'create' | 'update' | null
  searchForms: {
    keyword: string
  }
  userTemplates: UserTemplate[]
  totalCount: number
  selectedUserTemplate: UserTemplate | null
  editForms: {
    contentWithTag: string
  }
  errors: {
    contentWithTag: string | boolean
  }
}

export const useUserTemplate = (): {
  state: State
  validateForms: () => boolean
  resetForm: () => void
} => {
  const state = reactive<State>({
    type: null,
    searchForms: {
      keyword: '',
    },
    userTemplates: [],
    totalCount: 0,
    selectedUserTemplate: null,
    editForms: {
      contentWithTag: '',
    },
    errors: {
      contentWithTag: '',
    },
  })

  const rules = {
    contentWithTag: 'required|valid_html',
  }

  const messages = {
    'required.contentWithTag': '定型文を入力してください',
    'valid_html.contentWithTag': '定型文に不正な内容が入力されています',
  }

  const validateForms = (): boolean => {
    resetErrors()
    const validation = new Validator(state.editForms, rules, messages)
    if (validation.passes()) {
      return true
    } else {
      state.errors = {
        contentWithTag: validation.errors.first('contentWithTag'),
      }
      return false
    }
  }

  const resetForm = () => {
    state.editForms = {
      contentWithTag: '',
    }
  }

  const resetErrors = () => {
    state.errors = {
      contentWithTag: '',
    }
  }

  return {
    state,
    validateForms,
    resetForm,
  }
}
