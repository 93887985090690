import { Category } from '@/domain/category/category.model'
import { KnowledgeCategory } from '@/domain/knowledgeCategory/knowledgeCategory.model'

export const createExpandedIds = (
  categories: Category[],
  selectedCategories: Category[],
  expandedCategoryIds: number[]
) => {
  return categories.reduce((previousValue, category) => {
    if (category.layersDepth === 1) return previousValue

    const filteredCategories = selectedCategories.filter(
      (c) =>
        c.layers.slice(0, category.layersDepth).join('/') ===
        category.layers.join('/')
    )

    if (filteredCategories.length > 0) {
      return [...previousValue, category.subcategoryId]
    }

    return previousValue
  }, expandedCategoryIds)
}

export const createKnowledgeSelectedIds = (
  categories: KnowledgeCategory[],
  selectedCategories: KnowledgeCategory[],
  expandedCategoryIds: number[]
) => {
  return categories.reduce((previousValue, category) => {
    const filteredCategories = selectedCategories.filter(
      (c) =>
        c.layers.slice(0, category.layersDepth).join('/') ===
        category.layers.join('/')
    )

    if (filteredCategories.length > 0) {
      return [...previousValue, category.id]
    }

    return previousValue
  }, expandedCategoryIds)
}
