import {
  QUESTIONER_CLOSED_NEWS_IDS_KEY,
  RESPONDENT_CLOSED_NEWS_IDS_KEY,
  RESPONDENT_IS_MENU_OPEN,
  RESPONDENT_SEARCH_CONDITIONS_KEY,
} from '@/config/localStrage'
import { UNAUTHORIZED_REDIRECT_PATH_KEY } from '@/config/sesstion'
import { Auth } from '@/types/auth'
import dayjs from '@/utils/date'

const AUTH_KEY = 'auth'

export const getAuth = (): Auth | null => {
  const jsonAuth = localStorage.getItem(AUTH_KEY)
  return jsonAuth ? JSON.parse(jsonAuth) : null
}

export const setAuth = (data: Auth): void => {
  const jsonAuth = localStorage.getItem(AUTH_KEY) || ''
  const auth = jsonAuth ? JSON.parse(jsonAuth) : {}
  localStorage.setItem(
    AUTH_KEY,
    JSON.stringify({
      ...auth,
      ...data,
    })
  )
}

export const resetAuth = (): void => {
  localStorage.removeItem(AUTH_KEY)
  localStorage.removeItem(QUESTIONER_CLOSED_NEWS_IDS_KEY)
  localStorage.removeItem(RESPONDENT_CLOSED_NEWS_IDS_KEY)
  localStorage.removeItem(RESPONDENT_SEARCH_CONDITIONS_KEY)
  localStorage.removeItem(RESPONDENT_IS_MENU_OPEN)
  localStorage.removeItem(UNAUTHORIZED_REDIRECT_PATH_KEY)
}

export const isAuthenticated = (): boolean => {
  // 一般公開ページは認証不要
  if (window.location.pathname.includes('/public')) {
    return false
  }
  const auth = getAuth()
  if (
    !auth?.accessToken &&
    window.location.pathname !== '/' &&
    !window.location.pathname.includes('/login') &&
    !window.location.pathname.includes('/callback')
  ) {
    localStorage.setItem(
      UNAUTHORIZED_REDIRECT_PATH_KEY,
      window.location.pathname + window.location.search
    )
  }
  return !!auth?.accessToken
}

export const isExpired = (currentTime: string, expiresIn: string): boolean => {
  return (
    dayjs(currentTime)
      // 現在時刻と認証の有効期限がフロントの非同期の最中に切れる可能性がある。
      // なので意図的に一分早くリフレッシュするようにする
      .add(1, 'minute')
      .isAfter(dayjs(expiresIn))
  )
}

export const isAdmin = (): boolean => {
  const auth = getAuth()
  return auth?.user.role.name === '管理者'
}

export const isRespondent = (): boolean => {
  const auth = getAuth()
  return auth?.user.role.name === '回答者'
}

export const isFaqCreator = (): boolean => {
  const auth = getAuth()
  return auth?.user.role.name === 'FAQ作成者'
}
