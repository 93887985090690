import { reactive } from 'vue'
import { Attachment } from '@/types/attachment'
import { Question } from '@/types/question'
import Validator, { getMessageMaxHTML } from '@/utils/validator'

interface State {
  question: Question | null
  detailType: 'question' | 'qa' | 'none'
  forms: {
    content: string
    attachments: Attachment[]
  }
  errors: {
    content: string | boolean
  }
  canAddQuestions: boolean
}

export const useQuestionDetail = (): {
  state: State
  validateForms: () => boolean
} => {
  const state = reactive<State>({
    question: null,
    detailType: 'none',
    forms: {
      content: '',
      attachments: [],
    },
    errors: {
      content: '',
    },
    canAddQuestions: false,
  })

  const rules = {
    content: 'required|valid_html|max:15000',
  }

  const messages = {
    'required.content': 'お問い合わせ内容を入力してください',
    'valid_html.content': 'お問い合わせに不正な内容が含まれています',
    'max.content': getMessageMaxHTML('お問い合わせ'),
  }

  const validateForms = (): boolean => {
    const validation = new Validator(state.forms, rules, messages)
    if (validation.passes()) {
      return true
    } else {
      state.errors = {
        content: validation.errors.first('content'),
      }
      return false
    }
  }

  return {
    state,
    validateForms,
  }
}
