import { ApiConfig } from '@/types/apiConfig'

export type ExportUnit = 'detail' | 'branch'

interface SearchParams {
  scrollId?: string
  keyword: string
  id: string
  contactGroupIds?: string[]
  respondentId?: string
  subcategoryId?: number
  knowledgeCategoryId?: number
  createdBy: string
  answerStartDate?: string
  answerEndDate?: string
  orderBy?: string
  order?: string
  exportUnit?: ExportUnit
}

export const QaApi: ApiConfig = {
  get: (id: string) => ({
    method: 'GET',
    url: `/api/respondent/qa/${id}`,
  }),
  searches: (params: SearchParams) => ({
    method: 'POST',
    url: `/api/respondent/qa/searches`,
    data: params,
  }),
  export: (params: SearchParams) => ({
    method: 'POST',
    url: `/api/respondent/qa/exports`,
    data: params,
  }),
  exportHistories: (params: SearchParams) => ({
    method: 'POST',
    url: `/api/respondent/qa/histories/exports`,
    data: params,
  }),
  downloadReferenceLog: (params: { date: string }) => ({
    method: 'POST',
    url: `/api/respondent/qa/reference-log/download`,
    data: params,
  }),
}
