import { reactive } from 'vue'

interface State {
  isHamburgerOpen: boolean
}

export const useHamburgerMenu = (): {
  state: State
  toggleHamburgerMenu: () => void
} => {
  const state = reactive<State>({
    isHamburgerOpen: false,
  })

  const toggleHamburgerMenu = () => {
    state.isHamburgerOpen = !state.isHamburgerOpen
  }

  return {
    state,
    toggleHamburgerMenu,
  }
}
