import { ApiConfig } from '@/types/apiConfig'

export const CommonTemplateApi: ApiConfig = {
  create: (params: {
    commonTemplateGroupId: number
    contentWithTag: string
  }) => ({
    method: 'POST',
    url: `/api/administrator/common-templates`,
    data: params,
  }),
  update: (
    id: number,
    params: { commonTemplateGroupId: string; contentWithTag: string }
  ) => ({
    method: 'PUT',
    url: `/api/administrator/common-templates/${id}`,
    data: params,
  }),
  delete: (id: number) => ({
    method: 'DELETE',
    url: `/api/administrator/common-templates/${id}`,
  }),
}
