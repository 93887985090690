import { reactive } from 'vue'
import { User } from '@/types/user'
import Validator from '@/utils/validator'

interface State {
  user: User | null
  forms: {
    tel: string
    role: string
    section: string
  }
  errors: {
    tel: string | boolean
  }
}

export const useUpdateUser = (): {
  state: State
  validateForms: () => boolean
  resetError: () => void
} => {
  const state = reactive<State>({
    user: null,
    forms: {
      tel: '',
      role: '',
      section: '',
    },
    errors: {
      tel: '',
    },
  })

  const rules = {
    tel: 'tel',
  }

  const messages = {
    'tel.tel': '正しい電話番号を入力してください',
  }

  const validateForms = (): boolean => {
    const validation = new Validator(state.forms, rules, messages)
    resetError()
    if (validation.passes()) {
      return true
    } else {
      state.errors = {
        tel: validation.errors.first('tel'),
      }
      return false
    }
  }

  const resetError = () => {
    state.errors = {
      tel: '',
    }
  }

  return {
    state,
    validateForms,
    resetError,
  }
}
