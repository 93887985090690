import { computed, reactive } from 'vue'
import { KnowledgeCategory } from '@/domain/knowledgeCategory/knowledgeCategory.model'
import { createSelectedIds } from '@/use/categoryTree/multipleCategoryTree/libs/createSelectedIds'
import {
  moveDown,
  moveUp,
} from '@/use/categoryTree/multipleCategoryTree/libs/sort'

interface State {
  expandedCategoryIds: number[]
  categories: KnowledgeCategory[]
  selectedCategory: KnowledgeCategory | null
}

export const useMultipleKnowledgeCategoryTree = (fns: {
  getKnowledgeCategories: () => Promise<void>
  sortKnowledgeCategories: (params: {
    knowledgeCategoryIds: number[]
  }) => Promise<void>
  loading: (callback: () => Promise<void>) => void
}) => {
  const state = reactive<State>({
    expandedCategoryIds: [],
    categories: [],
    selectedCategory: null,
  })

  const knowledgeCategories = computed(() => {
    return state.categories
      .filter((c) => c.layersDepth === 1)
      .map((category) => {
        category.children = state.categories
        return category
      })
  })

  const expandCategory = async (category: KnowledgeCategory) => {
    state.expandedCategoryIds = createSelectedIds(
      state.expandedCategoryIds,
      category.id
    )
  }

  const selectCategory = (category: KnowledgeCategory) => {
    state.selectedCategory = category
  }

  const updateCategoryTree = (category: KnowledgeCategory) => {
    const index = state.categories.findIndex((c) => c.id === category.id)
    state.categories[index] = category
    state.selectedCategory = category
  }

  const sort = async (
    category: KnowledgeCategory,
    categories: KnowledgeCategory[],
    isMoveUp = false
  ) => {
    const index = categories.findIndex((c) => c.id === category.id)
    const params: {
      knowledgeCategoryIds: number[]
    } = {
      knowledgeCategoryIds: [],
    }

    if (isMoveUp) {
      params.knowledgeCategoryIds = moveUp(index, categories)
    } else {
      params.knowledgeCategoryIds = moveDown(index, categories)
    }

    await fns.sortKnowledgeCategories(params)
  }

  return {
    state,
    knowledgeCategories,
    expandCategory,
    selectCategory,
    updateCategoryTree,
    sort,
  }
}
