import { reactive, watch } from 'vue'
import { CommonTemplate } from '@/types/commonTemplate'
import Validator from '@/utils/validator'

interface State {
  type: 'create' | 'update' | null
  searchForms: {
    commonTemplateGroupId: string
    keyword: string
    nextCursor: string
  }
  commonTemplates: CommonTemplate[]
  totalCount: number
  isRedoSearch: boolean
  selectedCommonTemplate: CommonTemplate | null
  editForms: {
    commonTemplateGroupId: string
    contentWithTag: string
  }
  errors: {
    commonTemplateGroupId: string | boolean
    contentWithTag: string | boolean
  }
}

export const useCommonTemplate = (): {
  state: State
  validateForms: () => boolean
  resetForm: () => void
  resetErrors: () => void
} => {
  const state = reactive<State>({
    type: null,
    searchForms: {
      commonTemplateGroupId: '',
      keyword: '',
      nextCursor: '',
    },
    commonTemplates: [],
    totalCount: 0,
    isRedoSearch: true,
    selectedCommonTemplate: null,
    editForms: {
      commonTemplateGroupId: '',
      contentWithTag: '',
    },
    errors: {
      commonTemplateGroupId: '',
      contentWithTag: '',
    },
  })

  watch(
    () => [state.searchForms.commonTemplateGroupId, state.searchForms.keyword],
    () => {
      state.isRedoSearch = true
    }
  )

  const rules = {
    commonTemplateGroupId: 'required',
    contentWithTag: 'required|valid_html',
  }

  const messages = {
    'required.commonTemplateGroupId': '共通定型文グループを選択してください',
    'required.contentWithTag': '共通定型文を入力してください',
    'valid_html.contentWithTag': '共通定型文に不正な内容が含まれています',
  }

  const validateForms = (): boolean => {
    resetErrors()
    const validation = new Validator(state.editForms, rules, messages)
    if (validation.passes()) {
      return true
    } else {
      state.errors = {
        commonTemplateGroupId: validation.errors.first('commonTemplateGroupId'),
        contentWithTag: validation.errors.first('contentWithTag'),
      }
      return false
    }
  }

  const resetForm = () => {
    state.editForms = {
      commonTemplateGroupId: '',
      contentWithTag: '',
    }
  }

  const resetErrors = () => {
    state.errors = {
      commonTemplateGroupId: '',
      contentWithTag: '',
    }
  }

  return {
    state,
    validateForms,
    resetForm,
    resetErrors,
  }
}
