import { Rules } from 'validatorjs'
import { computed, reactive, watch } from 'vue'
import { Subcategory } from '@/types/category'
import { ContactGroup } from '@/types/contactGroup'
import { KnowledgeCategoryGroup } from '@/types/knowledgeCategoryGroup'
import { NotSupportTemplate } from '@/types/notSupportTemplate'
import { QuestionTemplate } from '@/types/questionTemplate'
import { Region } from '@/types/region'
import { Section } from '@/types/section'
import { Support } from '@/types/support'
import Validator from '@/utils/validator'

export type Setting = {
  id?: number
  ids?: number[]
  contactGroup: ContactGroup
  guideUrl: string
  message: string
  questionTemplate: QuestionTemplate
  notSupportTemplate: NotSupportTemplate
  subcategory: Subcategory
  support: Support
  knowledgeCategoryGroup: KnowledgeCategoryGroup
  region: Region
  sections: Section[]
}

interface State {
  knowledgeCategoryGroups: {
    name: string
    value: number
  }[]
  isCreate: boolean
  isDefault: boolean
  regions: {
    name: string
    value: number
  }[]
  contactGroups: {
    name: string
    value: number
  }[]
  questionTemplates: {
    name: string
    value: number
  }[]
  notSupportTemplates: {
    name: string
    value: number
  }[]
  divideSettings: {
    default: Setting | null
    details: (Setting & {
      region: Region
      section: Section
    })[]
  }
  selectedDivideSetting: Setting | null
  forms: {
    regions: number[]
    sections: Section[]
    support: Support
    contactGroupId: number
    questionTemplateId: number
    notSupportTemplateId: number
  }
  errors: {
    contactGroupId: string | boolean
    notSupportTemplateId: string | boolean
  }
}

export type CreateParams = {
  knowledgeCategoryGroupId: number
  subcategoryId: number
  regionIds: number[]
  sections?: Section[]
  support: Support
  contactGroupId?: number
  questionTemplateId?: number
  notSupportTemplateId?: number
}

export type UpdateParams = {
  divideSettingId?: number
  divideSettingDetailIds?: number[]
  regionIds: number[]
  sections: Section[]
  support: Support
  contactGroupId?: number
  questionTemplateId?: number
  notSupportTemplateId?: number
}

export const useDivideForm = (): {
  state: State
  getCreateParams: (
    knowledgeCategoryGroupId: number,
    subcategoryId: number
  ) => CreateParams
  getUpdateParams: () => UpdateParams
  validateForms: () => boolean
} => {
  const state = reactive<State>({
    knowledgeCategoryGroups: [],
    isCreate: true,
    isDefault: true,
    regions: [],
    contactGroups: [],
    questionTemplates: [],
    notSupportTemplates: [],
    divideSettings: {
      default: null,
      details: [],
    },
    selectedDivideSetting: null,
    forms: {
      regions: [],
      sections: [],
      support: '対応',
      contactGroupId: 0,
      questionTemplateId: 0,
      notSupportTemplateId: 0,
    },
    errors: {
      contactGroupId: '',
      notSupportTemplateId: '',
    },
  })

  watch(
    () => state.forms.support,
    () => {
      if (!state.isCreate) return

      state.forms = {
        ...state.forms,
        contactGroupId: 0,
        questionTemplateId: 0,
        notSupportTemplateId: 0,
      }
    }
  )

  const getCreateParams = (
    knowledgeCategoryGroupId: number,
    subcategoryId: number
  ): CreateParams => {
    const params: CreateParams = {
      knowledgeCategoryGroupId,
      subcategoryId,
      regionIds: state.forms.regions,
      sections: state.forms.sections,
      support: state.forms.support,
    }

    if (state.forms.contactGroupId && state.forms.contactGroupId !== 0) {
      params.contactGroupId = state.forms.contactGroupId
    }

    if (
      state.forms.notSupportTemplateId &&
      state.forms.notSupportTemplateId !== 0
    ) {
      params.notSupportTemplateId = state.forms.notSupportTemplateId
    }

    if (state.forms.support === '対応' && state.forms.questionTemplateId) {
      params.questionTemplateId = state.forms.questionTemplateId
    }

    return params
  }

  const getUpdateParams = (): UpdateParams => {
    const params: UpdateParams = {
      regionIds: state.forms.regions,
      sections: state.forms.sections,
      support: state.forms.support,
    }

    if (state.forms.contactGroupId && state.forms.contactGroupId !== 0) {
      params.contactGroupId = state.forms.contactGroupId
    }

    if (
      state.forms.notSupportTemplateId &&
      state.forms.notSupportTemplateId !== 0
    ) {
      params.notSupportTemplateId = state.forms.notSupportTemplateId
    }

    if (state.selectedDivideSetting?.id) {
      params.divideSettingId = state.selectedDivideSetting?.id
    } else {
      params.divideSettingDetailIds = state.selectedDivideSetting?.ids
    }

    if (state.forms.support === '対応' && state.forms.questionTemplateId) {
      params.questionTemplateId = state.forms.questionTemplateId
    }

    return params
  }

  const rules = computed(() => {
    if (state.forms.support === '対応') {
      return {
        contactGroupId: 'required|min:1',
      }
    }

    if (state.forms.support === '非対応') {
      return {
        notSupportTemplateId: 'required|min:1',
      }
    }

    return {}
  })

  const messages = {
    'required.contactGroupId': '対応のときは振り分け先が必須です',
    'min.contactGroupId': '対応のときは振り分け先が必須です',
    'required.notSupportTemplateId':
      '非対応のときは非対応テンプレートが必須です',
    'min.notSupportTemplateId': '非対応のときは非対応テンプレートが必須です',
  }

  const validateForms = (): boolean => {
    resetError()
    const validation = new Validator(
      state.forms,
      rules.value as Rules,
      messages
    )
    if (validation.passes()) {
      return true
    } else {
      state.errors = {
        contactGroupId: validation.errors.first('contactGroupId'),
        notSupportTemplateId: validation.errors.first('notSupportTemplateId'),
      }
      return false
    }
  }

  const resetError = () => {
    state.errors = {
      contactGroupId: '',
      notSupportTemplateId: '',
    }
  }

  return {
    state,
    getCreateParams,
    getUpdateParams,
    validateForms,
  }
}
