import { Category } from '@/domain/category/category.model'
import { KnowledgeCategory } from '@/domain/knowledgeCategory/knowledgeCategory.model'

export const moveUp = (
  index: number,
  categories: (Category | KnowledgeCategory)[]
): number[] => {
  const newCategories = [...categories]

  const higherCategory = categories[index]
  higherCategory.sort = higherCategory.sort - 1
  newCategories[index - 1] = higherCategory

  const lowerCategory = categories[index - 1]
  higherCategory.sort = higherCategory.sort + 1
  newCategories[index] = lowerCategory

  return newCategories.map((c) => c.id)
}

export const moveDown = (
  index: number,
  categories: (Category | KnowledgeCategory)[]
): number[] => {
  const newCategories = [...categories]

  const lowerCategory = categories[index]
  lowerCategory.sort = lowerCategory.sort + 1
  newCategories[index + 1] = lowerCategory

  const higherCategory = categories[index + 1]
  higherCategory.sort = higherCategory.sort - 1
  newCategories[index] = higherCategory

  return newCategories.map((c) => c.id)
}
