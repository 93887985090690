import _sanitizeHTML from 'sanitize-html'

// デフォルトに許容する属性を追加
_sanitizeHTML.defaults.allowedAttributes['*'] = ['style', 'class']

export const sanitizeHTML = (html: string) =>
  _sanitizeHTML(html, {
    allowedSchemes: ['http', 'https'],
    allowedTags: _sanitizeHTML.defaults.allowedTags.concat(['img', 'strong']),
    allowedAttributes: _sanitizeHTML.defaults.allowedAttributes,
  })

export const validateHTML = (html: string) => {
  const aTags = html.match(/<a(?: .+?)?>.*?<\/a>/g) || []
  const validHrefs = aTags
    .map((aTag: string) => aTag.match(/href\s*=\s*"([^"]*)"/))
    .filter(
      (matchArray) =>
        matchArray &&
        (matchArray[1].startsWith('http') || matchArray[1].startsWith('https'))
    )
  return aTags.length === validHrefs.length
}
