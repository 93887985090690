import { ApiConfig } from '@/types/apiConfig'

export const UserSettingApi: ApiConfig = {
  get: (id: string) => ({
    method: 'GET',
    url: `/api/user-settings/${id}`,
  }),
  follow: (id: string) => ({
    method: 'POST',
    url: `/api/user-settings/follows`,
    data: { user_id: id },
  }),
  unfollow: (id: string) => ({
    method: 'DELETE',
    url: `/api/user-settings/follows/${id}`,
  }),
}
