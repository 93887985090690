import _isValidFilename from 'valid-filename'
import { reactive } from 'vue'
import { createContainer } from 'vue-unstated'
import { EXTENSIONS, SIZE_LIMIT } from '@/config/file'
import { Attachment } from '@/types/attachment'
import { FileType } from '@/types/file'
import { removeExtension } from '@/utils/file'
import { partition } from '@/utils/partition'
import { isValidFilename } from '@/utils/validator'

export const FILE_STATE_DEFAULT_KEY = 'default'

interface State {
  files: FileType[]
  savedFiles: Attachment[]
  errorMessage: string[]
}

interface States {
  [index: string]: State
}

export const useFileUpload = (): {
  states: States
  inputFilter: (
    newFile: FileType,
    oldFile: undefined,
    prevent: () => void
  ) => void
  validateFiles: (key: string) => void
  isRunningUpload: () => boolean
} => {
  const states = reactive<States>({
    [FILE_STATE_DEFAULT_KEY]: {
      files: [],
      savedFiles: [],
      errorMessage: [],
    },
  })

  const inputFilter = (
    newFile: FileType,
    _oldFile: undefined,
    prevent: () => void
  ) => {
    if (newFile && !EXTENSIONS.test(newFile.name)) {
      prevent()
      alert('指定された拡張子のファイルは添付できません。')
      return false
    }

    return true
  }

  const validateFiles = (key = FILE_STATE_DEFAULT_KEY) => {
    if (!(key in states)) return

    states[`${key}`].errorMessage = []
    const { validFiles: validSizeFiles, errorMessage: sizeErrorMessage } =
      validateFileSize(states[`${key}`].files)
    const { validFiles: allValidFiles, errorMessage: nameErrorMessage } =
      validateFileName(validSizeFiles)

    if (sizeErrorMessage.length > 0 || nameErrorMessage.length > 0) {
      states[`${key}`].errorMessage = [...sizeErrorMessage, ...nameErrorMessage]
    }

    states[`${key}`].files = allValidFiles
  }

  const validateFileSize = (files: FileType[]) => {
    const [validFiles, invalidFiles] = partition(
      files,
      (value) => value.size <= SIZE_LIMIT
    )
    return {
      validFiles,
      errorMessage:
        invalidFiles.length > 0
          ? [
              'アップロードできるサイズは、1ファイル20MBまでです。ファイルサイズを確認してください。',
              ...invalidFiles.map((file: FileType) => `${file.file.name}`),
            ]
          : [],
    }
  }

  const validateFileName = (files: FileType[]) => {
    const [validFiles, invalidFiles] = partition(files, (value) =>
      isValidFilename(_isValidFilename, removeExtension(value.file.name))
    )
    return {
      validFiles,
      errorMessage:
        invalidFiles.length > 0
          ? [
              '不正なファイル名が含まれています',
              ...invalidFiles.map((file: FileType) => file.file.name),
            ]
          : [],
    }
  }

  const isRunningUpload = () => {
    // 現在保持している全ての選択ファイルがsuccessかerrorになっていない場合はアップロード実行中
    let isRunning = false
    Object.keys(states).forEach((key, index) => {
      const state = states[key]
      if (state.files.length > 0) {
        if (state.files.some((file) => !file.success && !file.error)) {
          isRunning = true
          return false
        }
      }
    })
    return isRunning
  }

  return {
    states,
    inputFilter,
    validateFiles,
    isRunningUpload,
  }
}

const fileUploadContainer = createContainer(useFileUpload)

export default fileUploadContainer
