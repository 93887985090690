import { ApiConfig } from '@/types/apiConfig'

interface SearchPamras {
  contactGroupId?: number
  contactGroupName: string
  email?: string
  excludeContactGroupIds?: number[]
  includeDeleted: boolean
  perPage?: number
}

export const ContactGroupApi: ApiConfig = {
  get: (id: number) => ({
    method: 'GET',
    url: `/api/respondent/contact-groups/${id}`,
  }),
  searches: (params: SearchPamras, cursor = '') => ({
    method: 'POST',
    url: `/api/respondent/contact-groups/searches?cursor=${cursor}`,
    data: params,
  }),
  searchForQuestions: (params: SearchPamras, cursor = '') => ({
    method: 'POST',
    url: `/api/respondent/contact-groups/question/searches?cursor=${cursor}`,
    data: params,
  }),
  searchForFaq: (params: SearchPamras, cursor = '') => ({
    method: 'POST',
    url: `/api/respondent/contact-groups/faq/searches?cursor=${cursor}`,
    data: params,
  }),
  searchUsers: (params: { contactGroupIds: number[] }) => ({
    method: 'POST',
    url: `/api/respondent/contact-groups/users/searches`,
    data: params,
  }),
  report: (params: { contactGroupIds: number[]; date: string }) => ({
    method: 'POST',
    url: `/api/respondent/contact-groups/reports`,
    data: params,
  }),
  usersReport: (params: {
    contactGroupIds: number[]
    date: string
    order: string
  }) => ({
    method: 'POST',
    url: `/api/respondent/contact-groups/users/reports`,
    data: params,
  }),
}
